/**
 * @module Actions
 *
 */
import { reject } from "rsvp";
import { Dispatch } from "react";
import { ActionType, action } from "typesafe-actions";
import { fetchGet, fetchPut, request } from "app/utils/request";
import { UserSettingsModel } from "app/models";
import config from "config/environment";

const url = "account/notifications_settings";

const fetchPending = () => action("@user-settings.pending.get");
const fetchError = (err: any) => action("@user-settings.error.get", err);
const fetchSuccess = (settings: UserSettingsModel) =>
  action("@user-settings.success.get", settings);

const fetchCountryCodes = (countryCodes: string[]) =>
  action("@user-settings.countryCodes.get", countryCodes);
const fetchCountryCodesErr = (err: any) =>
  action("@user-settings.fetchCountryCodesErr.get", err);

const fetchVerificationPending = () =>
  action("@user-settings.verificationPending.get");
const fetchVerificationError = (err: any) =>
  action("@user-settings.verificationError.get", err);
const fetchVerificationSuccess = (settings: UserSettingsModel) =>
  action("@user-settings.verificationSuccess.get", settings);
// const fetchNotifications = (notifications: UserSettingsModel) =>
//   action("@user-settings.notifications.get", notifications);

const savePending = () => action("@user-settings.pending.put");
const saveError = (err: any) => action("@user-settings.error.put", err);
const saveSuccess = () => action("@user-settings.success.put");

const thisActions = {
  fetchPending,
  fetchError,
  fetchSuccess,
  fetchCountryCodes,
  fetchCountryCodesErr,
  saveSuccess,
  savePending,
  saveError,
  fetchVerificationPending,
  fetchVerificationError,
  fetchVerificationSuccess,
};

export type UserSettingsAction = ActionType<typeof thisActions>;

export function fetchUserSettings(token?: string) {
  return (dispatch: Dispatch<UserSettingsAction>) => {
    dispatch(fetchPending());

    const tokenUrl = token != null ? `${url}?token=${token}` : url;
    return fetchGet(
      tokenUrl,
      {},
      { version: 1, noAuth: token != null ? true : false },
    )
      .then((settings) => dispatch(fetchSuccess(settings)))
      .catch((err) => dispatch(fetchError(err)));
  };
}

export function fetchPhoneCountryCodes(clientId?: string) {
  return (dispatch: Dispatch<UserSettingsAction>) => {
    dispatch(fetchPending());

    return fetchGet(
      `otp_countries_whitelist?client_id=${
        clientId ? clientId : "44bc56c490ecc81b859789e6b6cc36d5"
      }`,
      {},
      { version: 1 },
    )
      .then((countryCodes) => dispatch(fetchCountryCodes(countryCodes)))
      .catch((err) => dispatch(fetchCountryCodesErr(err)));
  };
}

export const sendVerificationCode =
  ({ phone, phone_country }: { phone: string; phone_country: string }) =>
  async () => {
    const url = config.API_SERVER + `/api/v1/verifications`;

    try {
      const response = await request(
        url,
        "POST",
        { phone, phone_country },
        { version: 1 },
      );
      return response;
    } catch (err) {
      console.error("Error in sendVerificationCode:", err);
      throw err;
    }
  };

export function confirmVerificationCode({
  id,
  code,
}: {
  id: string;
  code: string;
}) {
  return async (): Promise<any> => {
    const url = `${config.API_SERVER}/api/v1/verifications/${id}`;
    try {
      const response = await request(url, "PUT", { code }, { version: 1 });
      return response;
    } catch (err) {
      throw err;
    }
  };
}

export function saveUserSettings(
  notifications_settings: UserSettingsModel,
  token?: string,
) {
  return (dispatch: Dispatch<UserSettingsAction>) => {
    dispatch(savePending());

    const tokenUrl = token != null ? `${url}?token=${token}` : url;
    return fetchPut(
      tokenUrl,
      { notifications_settings },
      { version: 1, noAuth: token != null ? true : false },
    )
      .then(() => dispatch(saveSuccess()))
      .catch((err) => {
        dispatch(saveError(err));
        return reject(err);
      });
  };
}

export function changeNotifications(notifications_settings: {}) {
  return (dispatch: Dispatch<UserSettingsAction>) => {
    dispatch(fetchPending());

    const url = config.API_SERVER + `/api/v1/account/notifications_settings`;
    const promise = request(
      url,
      "PUT",
      { notifications_settings },
      { version: 1 },
    );
    promise.then((notifications) => dispatch(fetchSuccess(notifications)));
    promise.catch((err) => dispatch(fetchError(err)));

    return promise;
  };
}

export function fetchNotifications() {
  return (dispatch: Dispatch<UserSettingsAction>) => {
    dispatch(fetchPending());

    const url = config.API_SERVER + `/api/v1/account/notifications_settings`;
    const promise = request(url, "GET", {}, { version: 1 });
    promise.then((notifications) => dispatch(fetchSuccess(notifications)));
    promise.catch((err) => dispatch(fetchError(err)));

    return promise;
  };
}

export const actions = {
  fetchUserSettings,
  saveUserSettings,
  fetchNotifications,
};
