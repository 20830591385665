import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import CreateSpaceXl from "app/components/Button/CreateSpaceXl";
import SpaceCardXl from "app/components/SpaceCardXl";
import { useActions } from "app/utils/hooks";
import { fetchSpaceMemberships } from "app/actions/space";
import { fetchGroups } from "app/actions/groups";
import { Loading } from "../Wrappers";
import { Link } from "react-router-dom";
import useShuffledArray from "app/utils/hooks/useShuffleArrya";
import GroupCardXl from "../GroupCardXl";

const SpacesAndGroupsList = () => {
  const spaceActions = useActions({
    fetchSpaceMemberships,
    fetchGroups,
  });

  const [combinedData, setCombinedData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchSpacesAndGroups = async () => {
      try {
        setLoading(true);

        const [spacesRes, groupsRes] = await Promise.all([
          spaceActions.fetchSpaceMemberships({ page: 1, perPage: 50 }),
          spaceActions.fetchGroups(),
        ]);

        const spacesData = Array.isArray(spacesRes) ? spacesRes : [];
        const groupsData = Array.isArray(groupsRes) ? groupsRes : [];
        setCombinedData([...spacesData, ...groupsData]);
      } catch (error) {
        console.error("Error fetching spaces or groups:", error);
        setCombinedData([]);
      } finally {
        setLoading(false);
      }
    };

    fetchSpacesAndGroups();
  }, []);

  const shuffledData = useShuffledArray(combinedData);

  return (
    <div className={styles.space_cards_list}>
      <span className={styles.create_space}>
        <CreateSpaceXl />
        <hr />
      </span>
      {loading && (
        <Loading
          isLoading={loading}
          loadType="spinner"
          className={styles.loading}
        />
      )}
      {shuffledData.map((data: any, index: number) => (
        <Link
          key={data.id || index}
          to={data.space ? `/spaces/${data.id}` : `/groups/${data.id}`}
        >
          {data.space ? (
            <SpaceCardXl spaceData={data.space} />
          ) : (
            <GroupCardXl groupData={data} />
          )}
        </Link>
      ))}
    </div>
  );
};

export default SpacesAndGroupsList;
