import SpaceModel from "app/models/SpaceModel";
import React from "react";
import UserAvatar from "../UserAvatar";
import styles from "./styles.module.scss";

interface Props {
  spaceData: SpaceModel;
}

const SpaceCardXl = ({ spaceData }: Props) => {
  return (
    <div className={styles.container}>
      <span className={styles.avatar_wrapper}>
        <UserAvatar
          srcName={spaceData?.avatar}
          userName={spaceData?.athleteName}
          sizeWidth={102}
          sizeHeight={102}
          className={styles.avatar}
        />
      </span>
      <div className={styles.space_info}>
        <p className={styles.athlete_name}>
          <b>{spaceData?.athleteName}</b>
        </p>
        <p className={styles.sport}>{spaceData?.sport}</p>
      </div>
    </div>
  );
};

export default SpaceCardXl;
