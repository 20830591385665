import styles from "./styles.module.scss";
import UserSelector from "../UserSelector";
import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import UserItems from "../UserItems";
import Label from "../Label";
import Modal from "../Modal";
import {
  schedulingFetchGet,
  schedulingFetchPatch,
} from "app/utils/request/scheduling/fetch";
import { RootState } from "typedefs";
import UserModel from "app/models/UserModel";
import { useTranslation } from "react-i18next";
import TextAreaModal from "../TextAreaModal";
import CalendarX from "../../../../public/images/calendar-x-red.svg";
import { RoleType, UserContext } from "app/contexts/UserContext";

const RegisterStudentsPopup = ({
  showModal,
  handleRemove,
  setSelectedItem,
  selectedItem,
  showRemovePopup,
  setShowRemovePopup,
  programId,
  facilityId,
  setShowInformationModal,
  setModalInfo,
  participantStatus,
}) => {
  const currentStudent = useSelector(
    (state: RootState): UserModel => state.user.data
  );
  const [deletedStudentIds, setDeletedStudentIds] = useState([]);
  const [invitedStudents, setInvitedStudents] = useState([]);
  const [waitListedStudents, setWaitListedStudents] = useState([]);
  const [_initialIds, setInitialIds] = useState([]);
  const [canConfirm, setCanConfirm] = useState(participantStatus === '');
  const [studentsData, setStudentsData] = useState([]);
  const user = useContext(UserContext);
  const isFacility = user.role === RoleType.SCHEDULER;
  const { t } = useTranslation();

  const handleRemoveItem = () => {
    setInvitedStudents((old) =>
      old?.filter((option) => option.id !== selectedItem.id)
    );
    setWaitListedStudents((old) =>
      old?.filter((option) => option.id !== selectedItem.id)
    );
    setInitialIds((old) => old?.filter((option) => option !== selectedItem.id));
    setDeletedStudentIds((old) => [...old, selectedItem.id]);
    setSelectedItem(null);
    setShowRemovePopup(false);
    setCanConfirm(true);
  };

  const handleCancelRemove = () => {
    setSelectedItem(null);
    setShowRemovePopup(false);
  };

  useEffect(() => {
    const params = {
      registrant_id: user.id,
      registrant_type: isFacility ? "FacilityScheduler" : "StudentProfile",
    };
    schedulingFetchGet(`/facilities/${facilityId}/student_profiles`).then(
      (response) => {
        setStudentsData(response);
      }

    );
    schedulingFetchGet(`/programs/${programId}/registrations`, params).then(
      (response) => {
        const registrantsData = response?.data?.completed?.map((item) => item);
        const waitlistedData = response?.data?.onWaitlist?.map((item) => item);
        setWaitListedStudents(waitlistedData);
        if (registrantsData?.length > 0) {
          setInvitedStudents(registrantsData);
        } else if (participantStatus === "" && !isFacility) {
          setInvitedStudents([{ ...currentStudent }]);
        }
      }
    );
  }, [programId, participantStatus]);

  const onConfirm = () => {
    setCanConfirm(false);
    const data = {
      students_ids_to_register: invitedStudents.map((item) => item?.id),
      students_ids_to_unregister: deletedStudentIds,
    };
    schedulingFetchPatch(`/programs/${programId}/registrations`, data).then(
      (response) => {
        if (data.students_ids_to_unregister.length > 0) {
          setModalInfo({ message: "Users deleted successfully." });
        } else {
          setModalInfo(response);
        }
        showModal(false);
        setShowInformationModal(true);
      }
    );
  };

  const confirmWithDisableOptionButton = (text, onClick) => (
    <button
      data-testid="save-edit"
      form="form-details"
      className={canConfirm ? styles.confirm_button : styles.disabled_button}
      type="submit"
      disabled={!canConfirm}
      onClick={onClick}
    >
      {text}
    </button>
  );

  const cancelButton = (text, onClick) => (
    <button
      data-testid="cancel-edit"
      className={styles.cancel_button}
      onClick={onClick}
    >
      {text}
    </button>
  );

  return showRemovePopup ? (
    <TextAreaModal
      topImage={CalendarX}
      showCloseIcon
      title={`${t("Are you sure you want to cancel")} ${selectedItem.firstName
        } ${selectedItem.lastName}
     ${t("registration")}?`}
      hideTextArea
      stackedButtons
      subTitle={t("Send a message via email to the registered students.")}
      placeholder={t("Type here")}
      width="400px"
      confirmFirst
      confirmText={t("Yes, cancel it")}
      cancelText={t("No, keep it")}
      setShowModal={handleCancelRemove}
      handleAction={handleRemoveItem}
    />
  ) : (
    <Modal
      showModal={showModal}
      title={t("Register")}
      showCloseIcon={true}
      confirmButton={confirmWithDisableOptionButton(t("Confirm"), onConfirm)}
      cancelButtonProp={cancelButton(t("Cancel"), () => showModal(false))}
      width={"400px"}
      zIndex={3}
    >
      <div className={styles.register_container}>
        <div className={styles.column_container}>
          <>
            <Label
              text={t("Add Additional Athletes")}
              item={(
                <UserSelector
                  showEmail={isFacility}
                  placeholder={t("Select Athletes")}
                  values={studentsData}
                  selectedUsers={invitedStudents}
                  setValues={setInvitedStudents}
                />
              )}
            />
          </>

          <UserItems
            userList={invitedStudents}
            onClose={handleRemove}
            showEmail={isFacility}
            separatorStyle={styles.line_separator}
          />
          {waitListedStudents && waitListedStudents.length > 0 && (
            <Label
              text={t("Waitlisted")}
              item={(
                <UserItems
                  separatorStyle={styles.line_separator}
                  userList={waitListedStudents}
                  showEmail={isFacility}
                  onClose={handleRemove}
                />
              )}
            />
          )}
        </div>
      </div>
    </Modal>
  );
};

export default RegisterStudentsPopup;
