import { cls } from "app/utils";
import React, { useEffect, useRef, useState } from "react";
import styles from "./styles.module.scss";

interface DropdownProps {
  children?: React.ReactNode;
  btnContent: React.ReactNode;
  btnContentBadge?: React.ReactNode;
  btnClassName?: string;
  dropdownClassname?: string;
  dropdownWrapperClass?: string;
  hideButtonStyles?: boolean;
  closeOnClick?: boolean;
  closeModal?: any;
  onOpen?: () => void;
}

const DropdownButton = ({
  children,
  btnContent,
  btnContentBadge,
  btnClassName,
  dropdownClassname,
  dropdownWrapperClass,
  hideButtonStyles,
  closeOnClick = false,
  closeModal,
  onOpen,
}: DropdownProps): React.ReactElement => {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleClickOutside = (e: any): void => {
    if (isOpen && ref.current && !ref.current.contains(e.target)) {
      if (
        !ref.current.contains(e.target) &&
        !e.target.closest(`.${styles.dropdown_container}`)
      ) {
        setIsOpen(false);
      }
      if (closeOnClick) {
        setIsOpen(false);
      }
    }
  };

  const toggleDropDown = (evt: React.MouseEvent<HTMLSpanElement>) => {
    evt.preventDefault();
    setIsOpen(!isOpen);
    if (onOpen) {
      onOpen();
    }
  };

  useEffect((): void => {
    setIsOpen(false);
  }, [closeModal]);

  useEffect((): (() => void) => {
    document.addEventListener("click", handleClickOutside, true);
    return (): void => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [isOpen]);

  return (
    <div className={cls(styles.container, dropdownClassname)}>
      {btnContentBadge && (
        <div className={styles.badge_container}>{btnContentBadge}</div>
      )}
      <div
        ref={ref}
        onClick={toggleDropDown}
        className={cls(styles.dropdown_btn, btnClassName, {
          ["btn btn-outline-secondary"]: !hideButtonStyles,
        })}
      >
        {btnContent}
      </div>
      {/* {isOpen && ( */}
      {children && (
        <div
          className={cls(styles.dropdown_container, dropdownWrapperClass, {
            [styles.open]: isOpen,
            [styles.close]: !isOpen,
          })}
        >
          {children}
        </div>
      )}
      {/* )} */}
    </div>
  );
};

export default DropdownButton;
