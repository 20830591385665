import GroupModel from "app/models/GroupsModel";
import React from "react";
import { useTranslation } from "react-i18next";
import TeamAvatar from "../TeamAvatar";
import styles from "./styles.module.scss";

interface Props {
  groupData: GroupModel;
}

const GroupCardXl = ({ groupData }: Props) => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <span className={styles.avatar_wrapper}>
        <TeamAvatar
          srcName={groupData?.avatar}
          sizeWidth={102}
          sizeHeight={102}
          className={styles.avatar}
        />
      </span>
      <div className={styles.group_info}>
        <p className={styles.group_name}>
          <b>{groupData?.name}</b>
        </p>
        {/* <p className={styles.sport}>{groupData?.sport}</p> */}
        <p className={styles.group}>{t("Group")}</p>
      </div>
    </div>
  );
};

export default GroupCardXl;
