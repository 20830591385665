import React, { useMemo, useState, useEffect } from "react";
import styles from "./styles.module.scss";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import SpacesAndGroupsList from "app/components/SpacesAndGroupsList";
import MyCoachesList from "app/components/MyCoachesList";
import RecentActivities from "app/components/RecentActivities";
import { cls } from "app/utils";
import { useStoreActions } from "app/utils/hooks";
import { fetchConnections } from "app/actions/connection";
import UserModel from "app/models/UserModel";
import SiteSearch from "app/components/SiteSearch";
import NotificationsDropdown from "app/components/NotificationsDropdown";

const AthleteHome = (): React.ReactElement => {
  const { t } = useTranslation();
  const history = useHistory();
  const [showAllCoaches, setShowAllCoaches] = useState<boolean>(false);
  const [initialCoaches, setInitialCoaches] = useState<UserModel[]>([]);

  const { connection } = useStoreActions({ connection: fetchConnections });

  const allCoaches = useMemo((): UserModel[] => {
    return (
      connection?.data?.filter(
        (user: UserModel): string => user?.coachProfile?.bookingServiceLink,
      ) || []
    );
  }, [connection]);

  useEffect((): void => {
    if (!initialCoaches.length && allCoaches.length) {
      const shuffled = [...allCoaches].sort((): number => Math.random() - 0.5);
      setInitialCoaches(shuffled.slice(0, 4));
    }
  }, [allCoaches, initialCoaches]);

  const filteredCoaches = useMemo(() => {
    return showAllCoaches ? allCoaches : initialCoaches;
  }, [allCoaches, showAllCoaches, initialCoaches]);

  const onViewSpaces = (): void => {
    history.push("/spaces");
  };

  const onViewNotifications = (): void => {
    history.push("/notifications");
  };

  const onViewAllCoaches = (): void => {
    setShowAllCoaches(true);
  };

  const renderData = [
    {
      label: t("Spaces & Groups"),
      viewAllHandler: onViewSpaces,
      children: <SpacesAndGroupsList />,
    },

    {
      label: t("My Coaches"),
      viewAllHandler: !showAllCoaches ? onViewAllCoaches : undefined,
      children: <MyCoachesList coaches={filteredCoaches} />,
      hideIf: filteredCoaches?.length === 0,
    },

    {
      label: t("Recent Activity"),
      viewAllHandler: onViewNotifications,
      children: <RecentActivities />,
    },
  ];

  const Homenavbar = (): React.ReactElement => {
    return (
      <div className={styles.home_navbar}>
        <h2 className={styles.home_navbar_title}>{t("Home")}</h2>
        <div className={styles.navbar_content}>
          <div>
            <SiteSearch className={styles.search} />
          </div>
          <div>
            <NotificationsDropdown />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={cls(styles.container, "athlete-home")}>
      <Homenavbar />
      {renderData.map((item): React.ReactElement => {
        if (item?.hideIf) {
          return null;
        }

        return (
          <div key={item.label}>
            <div className={styles.section_title}>
              <p>{item.label}</p>
              {item.viewAllHandler && (
                <div
                  onClick={item?.viewAllHandler}
                  className={styles.view_all_btn}
                >
                  <p>{t("View All")}</p>
                </div>
              )}
            </div>
            {item.children}
          </div>
        );
      })}
    </div>
  );
};

export default AthleteHome;
