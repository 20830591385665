/**
 * @module Components.SignUp
 *
 */
import React, { useState, FormEvent } from "react";
import { MessageDescriptor } from "react-intl";
import { useError } from "app/utils/hooks";
import { AuthService } from "app/services";
import { UserModel } from "app/models";
import { cls } from "app/utils";
import { RoleSelect, SportSelect } from "app/components/inputs/SelectBox";
import { DefaultOption } from "app/components/inputs/SelectBox/Base";
import ImageCheckbox, {
  EventState,
} from "app/components/inputs/CheckBox/Image";
import role_athlete_png from "app/images/role-athlete.png";
import role_coach_png from "app/images/role-coach.png";
import role_fan_png from "app/images/role-fan.png";
import styles from "./styles.module.scss";
import { validateProperty } from "./validate";
import FormItem from "app/components/inputs/FormItem";
import { useTranslation } from "react-i18next";

type State = {
  user: Partial<UserModel> | null;
  kind: KindType | null;
  sport: string | null;
  title: string | null;
  start_trial: boolean;
};

type PropsDef = {
  user: Partial<UserModel>;
  coachSignup: boolean;
  onComplete: (data: Partial<State>) => void;
  startTrial: boolean;
};

type FormErrors = {
  kind?: string | MessageDescriptor;
};

const initErrors: FormErrors = {};

type KindType = "coach" | "athlete" | "other";

const initState: State = {
  user: null,
  kind: null,
  sport: null,
  title: null,
  start_trial: true,
};

export default function AboutYou(props: PropsDef) {
  const { t } = useTranslation();
  const [state, setState] = useState(() => ({
    ...initState,
    user: props.user || null,
    kind: props.coachSignup ? ("coach" as KindType) : null,
  }));
  const [errorsForm, addErrors, clearErrors] = useError(initErrors);

  const kindChange = (ev: EventState) => {
    setState({ ...state, kind: ev.name as KindType });
  };

  const sportChange = (opt: DefaultOption) => {
    if (opt != null) {
      setState({ ...state, sport: opt.value });
    } else {
      setState({ ...state, sport: null });
    }
  };

  const titleChange = (opt: DefaultOption) => {
    if (opt != null) {
      setState({ ...state, title: opt.value });
    } else {
      setState({ ...state, title: null });
    }
  };

  const submitAbout = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    clearErrors();

    const kind = state.kind;
    const validation = validateProperty("kind", kind);

    if (!validation.isValid) {
      addErrors({ kind: validation.error });
      return;
    }

    let sport: string | null = (state.sport || "").trim();
    let title: string | null = (state.title || "").trim();

    if (!sport.length) {
      sport = null;
    }
    if (!title.length) {
      title = null;
    }

    (AuthService as any)
      .savePostSignUpSurvey(kind, sport, title, props.startTrial)
      .then(() => {
        props.onComplete({ kind: kind.trim() as KindType, sport, title });
      });
  };

  const getError = (key: keyof FormErrors, errorsForm: FormErrors) => {
    if (errorsForm[key]) {
      return errorsForm[key];
    }
    return null;
  };

  return (
    <div className={styles.signupAboutYou}>
      <form className={styles.aboutContainer} noValidate onSubmit={submitAbout}>
        <div className={styles.aboutHeader}>
          <h1>{t("About You")}</h1>
          <p className="text-muted">
            {t(
              "Tell us a little bit about yourself so we can customize your experience."
            )}
          </p>
        </div>

        <div className={styles.aboutBody}>
          <div className={cls(styles.qBox, props.coachSignup ? "hidden" : "")}>
            <label>{t("What role best describes you?")}</label>
            <section className={styles.selector}>
              <span>
                <ImageCheckbox
                  src={role_coach_png}
                  name="coach"
                  label={t("Coach")}
                  checked={state.kind === "coach"}
                  onChange={kindChange}
                  controlled={true}
                />
              </span>
              <span>
                <ImageCheckbox
                  src={role_athlete_png}
                  name="athlete"
                  label={t("Athlete")}
                  checked={state.kind === "athlete"}
                  onChange={kindChange}
                  controlled={true}
                />
              </span>
              <span>
                <ImageCheckbox
                  src={role_fan_png}
                  name="other"
                  label={t("Parent / Fan")}
                  checked={state.kind === "other"}
                  onChange={kindChange}
                  controlled={true}
                />
              </span>
            </section>

            <FormItem error={getError("kind", errorsForm)}></FormItem>
          </div>

          <div className={styles.qBox}>
            <label>{t("What are you using CoachNow for?")}</label>
            <section>
              <SportSelect onChange={sportChange} isCreatable={true} />
            </section>
          </div>

          <div className={styles.qBox}>
            <label>{t("I like to be called...")}</label>
            <section>
              <RoleSelect type={state.kind} onChange={titleChange} />
            </section>
          </div>
        </div>

        <div className={styles.aboutFooter}>
          <button className="btn btn-primary full">{t("Get Started")}</button>
        </div>
      </form>
    </div>
  );
}
