/**
 * @module Components.Routes
 *
 */
import React, { useState, useEffect, useContext } from 'react';
import { DateTime } from 'luxon';
import { useForm, FormProvider } from 'react-hook-form';

import ModalLayout from "app/components/Layouts/ModalLayout/ModalLayout";

import { EventModel, EventType } from 'app/models/EventModel';
import { CoachProfileModel } from 'app/models/scheduling/CoachProfileModel';

import EventTypeSelector from "app/routes/Calendar/EventForm/EventTypeSelector";
import SchedulingUserSelector from 'app/components/SchedulingUserSelector';
import SessionForm from "app/routes/Calendar/EventForm/SessionForm";
import ScheduleBlockForm from "app/routes/Calendar/EventForm/ScheduleBlockForm";
import FormItem from "app/components/inputs/FormItem";
import { errorMessageFor } from 'app/routes/Calendar/EventForm/helpers';

import CloseIcon from "../../../../public/images/close.svg";
import { useTranslation } from 'react-i18next';
import { FacilityModel } from 'app/models/scheduling/FacilityModel';
import { RoleType, UserContext } from 'app/contexts/UserContext';

import styles from 'app/routes/Calendar/EventForm/styles.module.scss';

type PropsDef = {
  event: EventModel;
  coachProfileOptions?: CoachProfileModel[];
  onCancel: () => void;
  onSave: (event: EventModel) => void;
  submitting: boolean;
  errors: any;
  coachProfiles: CoachProfileModel[];
  allowCoachSelection?: boolean;
  facility?: FacilityModel;
};

export default function EventForm({
  event,
  onCancel,
  coachProfileOptions,
  onSave,
  errors,
  submitting=false,
  coachProfiles,
  allowCoachSelection = false,
  facility,
}: PropsDef) {
  const { t } = useTranslation();

  const methods = useForm({
    defaultValues: {
      ...event,
      coachProfiles,
    },
    mode: 'onChange',
  });

  const {
    control,
    register,
    unregister,
    handleSubmit,
    setValue,
    watch,
    formState,
    clearErrors,
    setError,
    getValues,
    resetField,
  } = methods;

  const user = useContext(UserContext);

  useEffect(() => {
    clearErrors();

    if (!errors) {
      return;
    }

    if (Object.keys(errors).length > 0) {
      Object.keys(errors).forEach((key) => {
        const error = errors[key];
        if (error) {
          const field = key === 'studentProfileIds' ? 'studentProfiles' : key;
          const message = error.join(', ');
          // @ts-ignore:next-line
          setError(field, { type: 'custom', message });
        }
      });
    }
  }, [errors]);

  const [showConfirmation, setShowConfirmation] = useState(false);

  const fadeForModal = showConfirmation;

  const watchEventType = watch('type');
  const watchCoaches = watch('coachProfiles');

  const isEdit = !!event.id;
  const isNew = !isEdit;

  const isAdmin = user.role === RoleType.ADMIN  || (user.role === RoleType.COACH && user.isAdmin);
  const showCoachSelection = isNew && (allowCoachSelection || (watchEventType === EventType.COACH_SCHEDULE_BLOCK && isAdmin));
  const ownEvent = coachProfiles.map(c => c?.id).includes(user.id);

  const onCoachChange = (changeEvent: any) => {
    const coaches = changeEvent.target.value;
    const selectedDate = getValues().date;
    setValue('coachProfiles', coaches, { shouldDirty: true });

    if (selectedDate < coaches[0].earliestBookingDate.toJSDate() && !isAdmin && !ownEvent) {
      setValue('date', coaches[0].earliestBookingDate.toJSDate());
    }

    resetField('lessonRateId');
    resetField('rate');
    resetField('location');
    resetField('private');
    resetField('start');
    resetField('end');
  };


  const onDateChange = (changeEvent: any) => {
    const date = changeEvent.target.value;

    setValue('date', date, { shouldValidate: true, shouldDirty: true });
    const startTime = DateTime.fromISO(getValues('start'))
                              .setZone(event.timeZone)
                              .set({
                                year: date.getFullYear(),
                                month: date.getMonth() + 1,
                                day: date.getDate()
                              });
    const endTime = DateTime.fromISO(getValues('end'))
                            .setZone(event.timeZone)
                            .set({
                              year: date.getFullYear(),
                              month: date.getMonth() + 1,
                              day: date.getDate()
                            });

    setValue('start', startTime.toISO(), { shouldDirty: true });
    setValue('end', endTime.toISO(), { shouldDirty: true });
  };

  const onSubmit = (data: any) => {
    let eventData: any;
    if (data.type === 'lesson_schedule') {
      eventData = {
        ...data,
        studentProfileIds: data.studentProfiles.map((student: any) => student.id),
        coachProfileIds: [data.coachProfiles[0].id],
        rate: !data.rate ? 0 : data.rate,
      };
      delete eventData.studentProfiles;
      delete eventData.coachProfiles;
    } else {
      eventData = {
        ...data,
        coachProfileIds: data.coachProfiles.map((coach: any) => coach.id),
      };
    }

    eventData = {
      ...eventData,
      start: DateTime.fromISO(eventData.start).setZone(eventData.timeZone).set({
        year: eventData.date.getFullYear(),
        month: eventData.date.getMonth() + 1,
        day: eventData.date.getDate(),
      }).toISO(),
      end: DateTime.fromISO(eventData.end).setZone(eventData.timeZone).set({
        year: eventData.date.getFullYear(),
        month: eventData.date.getMonth() + 1,
        day: eventData.date.getDate(),
      }).toISO(),
    };
    onSave(eventData);
  };

  const title = isEdit ? t('Edit Event') : t('New Event');

  const onEventTypeChange = (changeEvent: any) => {
    const eventType = changeEvent.target.value;
    if (eventType === 'lesson_schedule') {
      resetField('coachProfiles');
      unregister('title');
      unregister('private');
    }

    if (eventType === 'coach_schedule_block') {
      unregister('studentProfiles', { keepDefaultValue: true });
      unregister('lessonRateId', { keepDefaultValue: true });
      unregister('rate', { keepDefaultValue: true });
      unregister('location', { keepDefaultValue: true });
    }

    setValue('type', eventType);
  };

  const onDismiss = () => {
    const hasDirtyFields = Object.keys(formState.dirtyFields).length > 0;
    if (hasDirtyFields) {
      setShowConfirmation(true);
    } else {
      onCancel();
    }
  };

  const loadCoaches = async (_inputValue: string, _currentOptions: [], _additional: { page: number }) => {
    return {
      options: coachProfileOptions || [],
      hasMore: false,
    };
  };

  const validateCoachSelection = (coachSelection: CoachProfileModel[], formValues: any) => {
    if (formValues.type === EventType.LESSON_SCHEDULE) {
      return coachSelection.length === 1;
    }

    return coachSelection.length > 0;
  };

  const allowScheduleBlock = ownEvent || isAdmin;

  return (
    <>
      <ModalLayout isOpen classNames={`${styles.modal} ${fadeForModal ? styles.fade_for_confirmation : ''}  event_form_container`}>
        <div className={styles.header}>
          <h2>{title}</h2>
          <img className={styles.modal_status_icon} src={CloseIcon} alt="info" onClick={onDismiss} />
        </div>

        <div className={`${styles.event_form}`}>
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              {isNew && <EventTypeSelector {...register('type', { onChange: onEventTypeChange })} control={control} allowUnavailable={allowScheduleBlock} />}

              {showCoachSelection && (
                <FormItem error={errorMessageFor(errors, 'coachProfiles')} className={styles.input_field}>
                  <label>{t('Coach')}</label>
                  <SchedulingUserSelector
                    {...register("coachProfiles", { onChange: onCoachChange, validate: validateCoachSelection })}
                    control={control}
                    loadOptions={loadCoaches}
                    placeholder={t('Select Coach')}
                    multiSelect={watchEventType === 'coach_schedule_block'}
                    allowMultiSelectAll
                    allOptionsLabel={t('All Coaches')}
                  />
                </FormItem>
              )}

              {watchEventType === 'coach_schedule_block' ? (
                <ScheduleBlockForm onDateChange={onDateChange} />
              ) : (
                <SessionForm
                  lessonRateOptions={watchCoaches[0]?.lessonRates || []}
                  onDateChange={onDateChange}
                  coachProfile={watchCoaches[0]}
                  facility={facility}
                />
              )}

            </form>
          </FormProvider>
        </div>

        <div className={styles.form_actions}>
          <button className={`btn btn-outline-secondary`} onClick={onDismiss} type="button">{t("Cancel")}</button>
          <button disabled={!formState.isValid || submitting} className={`btn btn-primary`} type="button" onClick={handleSubmit(onSubmit)}>
            {submitting ? t('Saving...') : t('Save')}
          </button>
        </div>
      </ModalLayout>

      <ModalLayout isOpen={showConfirmation} classNames={`${styles.confirm_cancel_modal} confirm_cancel`}>
        <div className={styles.confirmation_modal_content}>
          <div>
            {t("Are you sure you want to discard this event?")}
          </div>
          <div className={styles.confirmation_actions}>
            <button className="btn btn-secondary" type="button" onClick={() => setShowConfirmation(false)}>{t("Cancel")}</button>
            <button
              className="btn btn-primary"
              type="button"
              onClick={() => {
                setShowConfirmation(false);
                onCancel();
              }}>
                {t("Discard")}
            </button>
          </div>
        </div>
      </ModalLayout>
    </>
  );
}
