import ModalLayout from "app/components/Layouts/ModalLayout/ModalLayout";
import { cls } from "app/utils";
import React, { memo, useEffect, useState } from "react";
import styles from "./styles.module.scss";
import closeIcon from "../../../../../../public/images/close-icon.svg";
import Button from "app/components/Button/ButtonVariant";
import { useActions } from "app/utils/hooks";
import { getMe, putGroupNotifications } from "app/actions/groups";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

interface IProps {
  isModalOpen: boolean;
  modalHandler: () => void;
}

const GroupsNotificationsModal = ({ isModalOpen, modalHandler }: IProps) => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const notificationActions = useActions({
    getMe,
    putGroupNotifications,
  });
  const [me, setMe] = useState(null);

  console.log("me: ", me);

  const onSelectHandler = (key: string, value: boolean) => {
    setMe({
      ...me,
      [key]: value,
    });
  };

  const ListData = [
    {
      label: t("Email"),
      description: t("Get an email for every Group notification."),
      value: "emailNotificationsEnabled",
      isActive: me?.emailNotificationsEnabled || false,
    },
    {
      label: t("Push Notifications"),
      description: t("Get a push for every Group notification."),
      value: "pushNotificationsEnabled",
      isActive: me?.pushNotificationsEnabled || false,
    },
  ];

  useEffect(() => {
    let isMounted = true;

    if (id && isMounted) {
      const fetchMe = async () => {
        try {
          const resp = await notificationActions.getMe(id);
          if (resp?.id) {
            setMe(resp);
          }
        } catch (err) {
          console.error(err);
        }
      };
      fetchMe();
    }

    return () => {
      isMounted = false;
    };
  }, [id]);

  const saveHandler = () => {
    notificationActions.putGroupNotifications(me).then(() => {
      modalHandler();
    });
  };

  return (
    <ModalLayout isOpen={isModalOpen} onClose={modalHandler}>
      <div className={styles.content_wrapper}>
        <div className={styles.modal_header}>
          <h1>{t("Your Notifications")}</h1>
          <img
            src={closeIcon}
            alt="close"
            className={styles.close_icon}
            onClick={modalHandler}
          />
        </div>
        <ul className={cls("stack-list-items")}>
          {ListData?.map((setting) => (
            <li
              key={setting.label}
              className={cls("stack-list-item selectable-item", {
                ["active"]: me?.[`${setting.value}`] || false,
                ["disabled"]: !me,
              })}
            >
              <label
                className="selectable-item-trigger"
                htmlFor={setting.label}
              ></label>
              <div className="selectable-item-control">
                <div className="checkbox">
                  <input
                    type="checkbox"
                    name="groupType"
                    id={setting.label}
                    checked={setting.isActive}
                    onChange={(event) =>
                      onSelectHandler(setting.value, event.target.checked)
                    }
                  />
                  <label className="opaque" htmlFor={setting.label}></label>
                </div>
              </div>

              <div className="selectable-item-details">
                <div className="selectable-item-label ng-binding">
                  {setting.label}
                </div>
                <div className="selectable-item-summary ng-binding">
                  {setting.description}
                </div>
              </div>
            </li>
          ))}
        </ul>
        <div className={styles.button_wrapper}>
          <Button
            buttonType="secondary-outline"
            title={t("Cancel")}
            onClickFunc={modalHandler}
          />
          <Button
            buttonType="primary"
            title={t("Save")}
            onClickFunc={saveHandler}
            disabled={!me}
          />
        </div>
      </div>
    </ModalLayout>
  );
};

export default memo(GroupsNotificationsModal);
