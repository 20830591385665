/**
 * @module Components.StripeForm
 *
 */
import React, { useState, FormEvent, useEffect } from "react";
import { injectStripe } from "react-stripe-elements";
import { MessageDescriptor } from "react-intl";
import { cls } from "app/utils";
import { useActions, useError, usePending } from "app/utils/hooks";
import { Token, ElementChangeResponse } from "app/utils/stripe";
import { Spinner } from "app/components/Loading";
import CardName from "./_components/CardName";
import CardNumber from "./_components/CardNumber";
import CardExpiry from "./_components/CardExpiry";
import CardCVC from "./_components/CardCVC";
import PostalCode from "./_components/PostalCode";
import styles from "./styles.module.scss";
import AddressLineOne from "./_components/AddressLineOne";
import AddressLinetwo from "./_components/AddressLineTwo";
import City from "./_components/City";
import Country from "./_components/Country";
import UsState from "../usState";
import { useTranslation } from "react-i18next";
import FormItem from "app/components/inputs/FormItem";
import ButtonVariant from "app/components/Button/ButtonVariant";
import { fetchPaymentInfo } from "app/actions/payment-info";
import { applyCoupon, clearCoupon } from "app/actions/coupon";
import { purchaseSubscription } from "app/actions/stripe";
import { useHistory } from "react-router-dom";
import { startTrial } from "app/actions/subscription";

export type PropsDef = {
  disabled?: boolean;
  cardName?: string;
  cardNumber?: string;
  cardExpiry?: string;
  cardSecurityCode?: string;
  country?: string;
  usState?: string;
  city?: string;
  addressLineOne?: string;
  addressLineTwo?: string;
  postalCode?: string;
  submitTitle?: string;
  onToken?: (token: Token) => void;
  onCancel?: () => void;
  stripe?: { [key: string]: any };
};

type ErrorModel = {
  cardNameError?: MessageDescriptor | null;
  cardNumberError?: string | null;
  cardExpiryError?: string | null;
  cardSecurityCodeError?: string | null;
  countryError?: string;
  usStateError?: string;
  cityError?: string;
  addressLineOneError?: string;
  addressLineTwoError?: string;
  postalCodeError?: string | null;
  formError?: string | null;
};

type State = ErrorModel & {
  cardName: ElementChangeResponse | null;
  cardNumber: ElementChangeResponse | null;
  cardExpiry: ElementChangeResponse | null;
  cardSecurityCode: ElementChangeResponse | null;
  country?: ElementChangeResponse | null;
  usState?: ElementChangeResponse | null;
  city?: ElementChangeResponse | null;
  addressLineOne?: ElementChangeResponse | null;
  addressLineTwo?: ElementChangeResponse | null;
  postalCode: ElementChangeResponse | null;
};

const initError: ErrorModel = {
  cardNameError: null,
  cardNumberError: null,
  cardExpiryError: null,
  cardSecurityCodeError: null,
  countryError: null,
  usStateError: null,
  cityError: null,
  addressLineOneError: null,
  addressLineTwoError: null,
  postalCodeError: null,
  formError: null,
};

const initState: State = {
  cardName: null,
  cardNumber: null,
  cardExpiry: null,
  country: null,
  // @ts-ignore
  usState: null,
  city: null,
  cardSecurityCode: null,
  addressLineOne: null,
  addressLineTwo: null,
  postalCode: null,
};

const COUPON_CODE = "pga6";
const PRODUCT_KIND = "CoachPlus";
const PLAN_CODE = "coachpro+yearly";

function StartFreeTrialForm(props: PropsDef) {
  const history = useHistory();
  const { t } = useTranslation();
  const [isReady, setIsReady] = useState(false);
  const [pending, startPending, stopPending] = usePending();
  const [errors, addErrors, clearErrors] = useError(initError);
  const [state, setState] = useState(() =>
    mergePropsWithState(props, initState)
  );

  const actions = useActions({
    fetchPaymentInfo,
    applyCoupon,
    clearCoupon,
    purchaseSubscription,
    startTrial,
  });

  const formClass = cls(styles.stripeCardForm, isReady ? "" : "hidden");
  const disabled = props.disabled || pending.status;

  const route = (url: string) => {
    return history.push(url);
  };

  useEffect(() => {
    const applyPromo = () => {
      actions.applyCoupon({
        couponCode: COUPON_CODE,
        productKind: PRODUCT_KIND,
        planCode: PLAN_CODE,
      });
    };

    applyPromo();
  }, []);

  const onStateChange = (name: string, el: ElementChangeResponse) => {
    clearErrors();
    setState({ ...state, [name]: el });
  };

  const handlePurchaseFreeTrial = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!props.stripe) {
      console.error("Stripe is not initialized!");
      return;
    }
    const { cardNumber, cardExpiry, cardSecurityCode } = state;

    // trim cardName postalCode to remove extra spaces
    const cardName =
      state.cardName && state.cardName.value
        ? (state.cardName.value as string).trim()
        : null;
    const country =
      state.country && state.country.value
        ? (state.country.value as string)
        : null;
    const usState =
      state.usState && state.usState.value
        ? (state.usState.value as string)
        : null;
    const city =
      state.city && state.city.value
        ? (state.city.value as string).trimLeft()
        : null;
    const addressLineOne =
      state.addressLineOne && state.addressLineOne.value
        ? (state.addressLineOne.value as string).trimLeft()
        : null;
    const addressLineTwo =
      state.addressLineTwo && state.addressLineTwo.value
        ? (state.addressLineTwo.value as string).trimLeft()
        : null;
    const postalCode =
      state.postalCode && state.postalCode.value
        ? (state.postalCode.value as string).trim()
        : null;

    // validate input for errors
    const errors = validate(
      cardName,
      cardNumber,
      cardExpiry,
      cardSecurityCode,
      country,
      usState,
      city,
      addressLineOne,
      // addressLineTwo,
      postalCode,
      t
    );

    if (Object.keys(errors).length) {
      addErrors(errors);
      return;
    }

    startPending();

    try {
      const token = await props.stripe.createToken({
        name: cardName,
        address_country: country,
        address_city: city,
        address_state: usState || "",
        address_line1: addressLineOne,
        address_line2: addressLineTwo || "",
        address_zip: postalCode,
      });

      if (token.error) {
        let error: ErrorModel = { formError: token.error.message };
        switch (token.error.code) {
          case "incomplete_zip":
            error = { postalCodeError: token.error.message };
            break;
          case "card_declined":
            error = { cardNumberError: token.error.message };
            break;
          case "expired_card":
            error = { cardExpiryError: token.error.message };
            break;
          default:
            error = { formError: token.error.message };
        }
        addErrors(error);
        return;
      }

      const respToken = token?.token?.id;
      if (!respToken) {
        console.error("Token ID is missing!");
        return;
      }

      await purchasePlan(respToken);

      if (props.onToken) {
        props.onToken(token.token);
      }

      route("/getting-started");
    } catch (err) {
      console.error(
        "Error creating token:",
        err instanceof Error ? err.message : err
      );
    } finally {
      stopPending();
    }
  };

  const purchasePlan = async (token: string) => {
    try {
      if (!token) {
        throw new Error("Token is required to purchase a plan.");
      }

      const resp: any = await actions.purchaseSubscription({
        token,
        couponCode: COUPON_CODE,
        productKind: PRODUCT_KIND,
        planCode: PLAN_CODE,
      });

      console.log("Response from purchaseSubscription: ", resp);

      switch (resp.type) {
        case "@stripe.post.success":
          console.log("Purchase successful:", resp);
          // Uncomment and handle success
          // props.onSuccess(activePlan.id);
          break;

        case "@stripe.post.error":
          console.error("Error during purchasing plan:", resp);
          break;

        default:
          console.error("Unknown response type:", resp);
          break;
      }
    } catch (err) {
      console.error(
        "Error purchasing plan:",
        err instanceof Error ? err.message : err
      );
    }
  };

  // const purchase = (token: string) => {
  //   // get coupon code if existst
  //   const coupon = actionState.coupon.data;
  //   const couponCode = coupon && coupon.couponCode ? coupon.couponCode : undefined;
  //
  //   // send purchase to api
  //   actions.purchaseSubscription({
  //     token, couponCode,
  //     productKind: activePlan.productKind,
  //     planCode: activePlan.id
  //   }).then((result: any) => {
  //     if (result.type === '@stripe.post.success') {
  //       props.onSuccess(activePlan.id);
  //     }
  //   });
  // };

  const handleKeyboard = (e: React.KeyboardEvent<HTMLFormElement>) => {
    if (e.which === 13) {
      e.preventDefault();
      handlePurchaseFreeTrial(e);
    }
  };

  const handleTrialClicked = async () => {
    try {
      await actions.startTrial();
      (window as any).location = "/feed?trialStarted=true";
    } catch (err) {
      console.error(
        "Error when starting trial",
        err instanceof Error ? err?.message : err
      );
    }
  };

  return (
    <div className={cls(styles.rcStripeForm, disabled ? "disable" : "")}>
      {!isReady && <Spinner />}

      <form
        className={formClass}
        onKeyDown={handleKeyboard}
        onSubmit={handlePurchaseFreeTrial}
        target="_self"
      >
        <CardName
          onChange={(el: ElementChangeResponse) =>
            onStateChange("cardName", el)
          }
          value={props.cardName}
          error={errors.cardNameError}
          disabled={disabled}
        />
        <CardNumber
          onReady={() => setIsReady(true)}
          onChange={(el: ElementChangeResponse) =>
            onStateChange("cardNumber", el)
          }
          value={props.cardNumber}
          error={errors.cardNumberError}
          disabled={disabled}
        />
        <div className={styles.card_details_wrapper}>
          <CardExpiry
            onChange={(el: ElementChangeResponse) =>
              onStateChange("cardExpiry", el)
            }
            value={props.cardExpiry}
            error={errors.cardExpiryError}
            disabled={disabled}
          />
          <CardCVC
            onChange={(el: ElementChangeResponse) =>
              onStateChange("cardSecurityCode", el)
            }
            value={props.cardSecurityCode}
            error={errors.cardSecurityCodeError}
            disabled={disabled}
          />
        </div>
        <Country
          onChange={(el: ElementChangeResponse) => onStateChange("country", el)}
          value={props.country}
          error={errors.countryError}
          disabled={disabled}
        />
        {state.country &&
          state.country.value &&
          state.country.value === "US" && (
            <UsState
              onChange={(el: ElementChangeResponse) =>
                onStateChange("usState", el)
              }
              value={props.usState}
              error={errors.usStateError}
              disabled={disabled}
            />
          )}
        <City
          onChange={(el: ElementChangeResponse) => onStateChange("city", el)}
          value={props.city}
          error={errors.cityError}
          disabled={disabled}
        />
        <AddressLineOne
          onChange={(el: ElementChangeResponse) =>
            onStateChange("addressLineOne", el)
          }
          value={props.addressLineOne}
          error={errors.addressLineOneError}
          disabled={disabled}
        />
        <AddressLinetwo
          onChange={(el: ElementChangeResponse) =>
            onStateChange("addressLineTwo", el)
          }
          value={props.addressLineTwo}
          // error={errors.addressLineTwoError}
          disabled={disabled}
        />
        <PostalCode
          onChange={(el: ElementChangeResponse) =>
            onStateChange("postalCode", el)
          }
          value={props.postalCode}
          error={errors.postalCodeError}
          disabled={disabled}
        />
        <FormItem error={errors.formError}>
          <div className={styles.buttons_wrapper}>
            <p className={styles.small_description}>
              {t("6 months free, then $499.00 per year")}
            </p>
            <ButtonVariant
              title={t("Start Free Trial")}
              buttonType="primary"
              onClickFunc={handlePurchaseFreeTrial}
              disabled={pending?.status}
            />
            <ButtonVariant
              title={t("Continue with Free Plan")}
              // onClick={cancelUpgrade}
              buttonType="secondary-outline"
              onClickFunc={handleTrialClicked}
              disabled={pending?.status}
            />
          </div>
        </FormItem>
      </form>
    </div>
  );
}

export default injectStripe(StartFreeTrialForm);

const mergePropsWithState = (props: PropsDef, state: State): State => {
  return Object.assign({}, state, {
    cardName: props.cardName,
    cardNumber: props.cardNumber,
    cardExpiry: props.cardExpiry,
    cardSecurityCode: props.cardSecurityCode,
    country: props.country,
    usState: props.usState,
    city: props.city,
    addressLineOne: props.addressLineOne,
    addresslineTwo: props.addressLineTwo,
    postalCode: props.postalCode,
  });
};

const validate = (
  cardName: string | null,
  cardNumber: ElementChangeResponse | null,
  cardExpiry: ElementChangeResponse | null,
  cardSecurityCode: ElementChangeResponse | null,
  country: string | null,
  usState: string | null,
  city: string | null,
  addressLineOne: string | null,
  // addressLineTwo: string | null,
  postalCode: string | null,
  t: any
) => {
  const errors: ErrorModel = {};

  if (!(cardName && cardName.length)) {
    errors.cardNameError = {
      id: "error.required.card-name",
      defaultMessage: t(
        "Please enter the name that appears on your credit card."
      ),
    };
  }

  if (!cardNumber) {
    errors.cardNumberError = t("Please enter your credit card number.");
  }

  if (!cardExpiry) {
    errors.cardExpiryError = t("Please enter your cards expiration date.");
  }

  if (!cardSecurityCode) {
    errors.cardSecurityCodeError = t(
      "Please enter the security code that appears on your card."
    );
  }

  if (!country) {
    errors.countryError = t("Please select a country.");
  }

  if (country && country === "US" && !usState) {
    errors.usStateError = t("Please select state.");
  }

  if (!city) {
    errors.cityError = t("Please enter a valid city.");
  }

  if (!addressLineOne) {
    errors.addressLineOneError = t("Please enter a valid city.");
  }

  // if (!addressLineTwo) {
  //   errors.addressLineTwoError = formatMessage(
  //     LOCALES.error_required_address_line_two
  //   );
  // }

  if (!postalCode) {
    errors.postalCodeError = t("A postal code is required.");
  }
  return errors;
};
