import React, { useEffect, useRef } from "react";
import styles from "./styles.module.scss";
import PostViewsCountedView from "../PostViewsCountedView";
import PostLikes from "./InnerPost/PostLikes";
import PostTags from "./InnerPost/PostTags";
import PostModel from "app/models/PostModel";
import Replies from "./InnerPost/Replies";
import PostMedia from "./InnerPost/PostMedia";
import { canReply, canSeePostData } from "./Post";

import EditPost from "./InnerPost/EditPost";
import { cls } from "app/utils";
import { usePostModel } from "./indexModel";
import PostHeader from "./InnerPost/PostHeader";
import { usePostViewsTracking } from "app/utils/hooks/usePostViewsTracking";
import PostDetails from "./InnerPost/PostDetails";
import TogglePinModal from "../TogglePinModal";
import UserModel from "app/models/UserModel";
import PinedPostBadge from "./InnerPost/PinedPostBadge";
import { useTranslation } from "react-i18next";
import { PostMediaContainer } from "./InnerPost/PostMediaContainer";
import { DateTime } from "luxon";
interface Props {
  post: PostModel;
  user?: UserModel;
  isDemo?: boolean;
  onEdit?: (post: PostModel) => void;
  onEditTags?: (post: PostModel) => void;
  onMediaLockedClick?: () => void;
  onRemove?: (post: PostModel) => void;
  onReport?: (post: PostModel) => void;
  onPin?: () => void;
  canPinPost?: boolean;
  onUpgradeToPostClick?: () => void;
  hidePinBadge?: boolean;
  displayLocation?: boolean;
  dropdownButtonClassName?: string;
  hideReply?: boolean;
  hideLikes?: boolean;
  updateError?: boolean;
  canSeePostViews?: boolean;
  canSeeWhoLiked?: boolean;
  canCreateReply?: boolean;
}

const Post: React.FC<Props> = ({
  post,
  user,
  isDemo,
  onEdit,
  // todo: add this onMediaLockedClick for teams and team_groups
  onRemove,
  onPin,
  canPinPost,
  onReport,
  hidePinBadge,
  displayLocation,
  dropdownButtonClassName,
  hideReply,
  hideLikes,
  updateError,
  canSeePostViews = true,
  canSeeWhoLiked = true,
}: Props) => {
  // const [selectedItemId, setSelectedItemId] = React.useState<number | null>(
  //   null,
  // );
  const {
    onCopyClick,
    onMoveClick,
    onPinClick,
    onPinHandler,
    pinModalOpen,
    isEditingPost,
    isEditingTags,
    onEditClick,
    onEditClose,
    onEditTagsClick,
    onEditTagsClose,
    onPostUpdate,
    canLikePost,
  } = usePostModel({ post, onEdit, onPin });
  const { t } = useTranslation();

  const ref = useRef<HTMLDivElement>();

  const { createObserver } = usePostViewsTracking();

  useEffect(() => {
    createObserver(ref, post);
  }, [post, createObserver]);

  const parseDateToString = () => {
    const dateTime = DateTime.fromISO(post.event.startsAt);

    return dateTime.toLocaleString(DateTime.DATE_FULL);
  };

  return (
    <div
      className={cls(styles.container, {
        // [styles.padding_bottom]: hideLike && hideViews,
      })}
      ref={ref}
      data-id={post?.id}
    >
      {!hidePinBadge && post.pinnedAt && (
        <PinedPostBadge
          pinnedByName={post.pinnedByName}
          pinnedTill={post?.pinnedTill}
        />
      )}
      <PostHeader
        post={post}
        onEditClick={onEditClick}
        onEditTagsClick={onEditTagsClick}
        onCopyClick={onCopyClick}
        displayLocation={displayLocation}
        dropdownButtonClassName={dropdownButtonClassName}
        onPinClick={onPinClick}
        onPostDelete={onRemove}
        onPostReport={onReport}
        isDemo={isDemo}
        canPinPost={canPinPost}
        onMoveClick={onMoveClick}
      />
      <div className={styles.post_inner}>
        {!isEditingPost ? (
          <div
            className={cls(
              // { [styles.post_details_tags]: post?.resources?.length > 0 },
              styles.post_details_container
            )}
          >
            {/* post text */}
            {post?.details && (
              <PostDetails isDemo={isDemo} details={post?.details} />
            )}
            {/* post tags */}
            {post.tags && <PostTags tags={post.tags} />}
          </div>
        ) : (
          <EditPost
            classNames={cls(styles.edit_post, {
              [styles.edit_post_container]: post.resources,
            })}
            post={post}
            onCancel={onEditClose}
            onUpdate={onPostUpdate}
          />
        )}

        {isEditingTags && (
          <div className={styles.tags_edit_container}>
            <EditPost
              classNames={cls(styles.edit_post, {
                [styles.edit_post_container]: post.resources,
              })}
              post={post}
              onCancel={onEditTagsClose}
              onUpdate={onPostUpdate}
              editTags
            />
          </div>
        )}
        {/* Multiple Resource Post, aka: gallery */}
        {post.resources?.length && post.object === "gallery" && (
          <PostMediaContainer
            mediaData={post?.resources}
            // onClick={(id: number) => {
            // modalHandler();
            // setSelectedItemId(id);
            // }}
          />
        )}
        {/* Single Resource Post */}
        {post.resources?.length && post.object !== "gallery" && (
          <div
            className={cls(styles.post_attachment, {
              [styles.shrink]:
                post.resources[0].type !== "image" &&
                post.resources[0].type !== "video",
            })}
          >
            <PostMedia mediaData={post.resources[0]} />
          </div>
        )}
        {post.event && (
          <div className={styles.date_container}>
            <span className={styles.calendar_icon}>
              <i className="ico ico-calendar" />
            </span>
            <div>
              {"Session on "}
              <strong>{parseDateToString()}</strong>
            </div>
          </div>
        )}
        {canLikePost() && post.published && (
          <div className={styles.like_view_container}>
            <PostViewsCountedView
              viewers={post.postViews}
              viewsCount={post.postViewsCount}
              postId={post.id}
              canSeePostData={canSeePostViews && canSeePostData(post)}
              planPermissions={post.planPermissions}
              CoachKind={user.kind}
              isDemoSpace={isDemo}
            />
            {!hideLikes && canLikePost() && (
              <PostLikes
                postId={post.id}
                likesCount={post.likesCount}
                liked={post.liked}
                likable={post.likable}
                isDemo={isDemo}
                canSeeWhoLiked={canSeeWhoLiked}
              />
            )}
          </div>
        )}
      </div>
      {updateError && (
        <div className={styles.error}>{t("Something went wrong")}</div>
      )}
      {post.published && !hideReply && (
        <Replies post={post} isDemo={isDemo} canCreateReply={canReply(post)} />
      )}
      {/* {isModalOpen && ( */}
      {/*   <PostMediaModal */}
      {/*     onClose={modalHandler} */}
      {/*     isOpen={isModalOpen} */}
      {/*     mediaData={post.resources[0]} */}
      {/*   /> */}
      {/* )} */}
      {pinModalOpen && (
        <TogglePinModal
          postId={post.id}
          isOpen={pinModalOpen}
          onClose={onPinClick}
          isPinned={post.pinnedByName}
          onPinHandler={onPinHandler}
        />
      )}
    </div>
  );
};

export default Post;
