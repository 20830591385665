import React, { useState, useEffect } from "react";
import Select, { StylesConfig } from "react-select";
import ButtonWithTooltip from "app/components/Button/ButtonWithToolTip";
import styles from "./styles.module.scss";
import { UseFormRegister, UseFormSetValue, UseFormWatch } from "react-hook-form";
import { useTranslation } from 'react-i18next';

interface CutOffTimeProps {
  title: string;
  customText: string;
  register: UseFormRegister<any>;
  setValue: UseFormSetValue<any>;
  watch: UseFormWatch<any>;
  prefix: string;
}

const CutOffTime: React.FC<CutOffTimeProps> = ({
  title,
  customText,
  setValue,
  watch,
  prefix,
}) => {
  const { t } = useTranslation();

  const initialCutOffType = watch(`${prefix}_type`) || "start_time";
  const initialCutOffTime = watch(`${prefix}_time`);
  const initialCutOffTimeOfDay = watch(`${prefix}_time_of_day`) || "12:00 AM";
  const initialStaticDay = watch(`${prefix}_static_day`) || 0;
  const initialStaticHour = watch(`${prefix}_static_hour`) || 0;

  const [cutOffType, setCutOffType] = useState(initialCutOffType);
  const [cutOffTime, setCutOffTime] = useState(initialCutOffTime || 24);
  const [cutOffTimeOfDay, setCutOffTimeOfDay] = useState(initialCutOffTimeOfDay);
  const [staticDay, setStaticDay] = useState(initialStaticDay);
  const [staticHour, setStaticHour] = useState(initialStaticHour);
  const [startTimeActive, setStartTimeActive] = useState(initialCutOffType === "start_time");
  const [staticActive, setStaticActive] = useState(initialCutOffType === "static");

  const cutOffTimeOptions = [
    { label: t("Unlimited"), value: -1 },
    { label: t("count_hour", { count: 1 }), value: 1 },
    { label: t("count_hour", { count: 3 }), value: 3 },
    { label: t("count_hour", { count: 6 }), value: 6 },
    { label: t("count_hour", { count: 12 }), value: 12 },
    { label: t("count_day", { count: 1 }), value: 24 },
    { label: t("count_day", { count: 2 }), value: 48 },
    { label: t("count_day", { count: 3 }), value: 72 },
    { label: t("count_day", { count: 4 }), value: 96 },
    { label: t("count_day", { count: 5 }), value: 120 },
    { label: t("count_day", { count: 6 }), value: 144 },
    { label: t("count_day", { count: 7 }), value: 168 },
  ];

  const staticDayOptions = [
    { label: t("Day of"), value: 0 },
    { label: t("count_day_before", { count: 1 }), value: 1 },
    { label: t("count_day_before", { count: 2 }), value: 2 },
    { label: t("count_day_before", { count: 3 }), value: 3 },
  ];

  const staticHourOptions = [
    { label: t("12:00 AM"), value: 0 },
    { label: t("1:00 AM"), value: 1 },
    { label: t("2:00 AM"), value: 2 },
    { label: t("3:00 AM"), value: 3 },
    { label: t("4:00 AM"), value: 4 },
    { label: t("5:00 AM"), value: 5 },
    { label: t("6:00 AM"), value: 6 },
    { label: t("7:00 AM"), value: 7 },
    { label: t("8:00 AM"), value: 8 },
    { label: t("9:00 AM"), value: 9 },
    { label: t("10:00 AM"), value: 10 },
    { label: t("11:00 AM"), value: 11 },
    { label: t("12:00 PM"), value: 12 },
    { label: t("1:00 PM"), value: 13 },
    { label: t("2:00 PM"), value: 14 },
    { label: t("3:00 PM"), value: 15 },
    { label: t("4:00 PM"), value: 16 },
    { label: t("5:00 PM"), value: 17 },
    { label: t("6:00 PM"), value: 18 },
    { label: t("7:00 PM"), value: 19 },
    { label: t("8:00 PM"), value: 20 },
    { label: t("9:00 PM"), value: 21 },
    { label: t("10:00 PM"), value: 22 },
    { label: t("11:00 PM"), value: 23 },
  ];

  useEffect(() => {
    setCutOffType(initialCutOffType);
    setCutOffTime(initialCutOffTime || 24);
    setCutOffTimeOfDay(initialCutOffTimeOfDay);

    if (initialCutOffType === "static" && initialCutOffTime !== undefined) {
      const calculatedStaticDay = Math.floor(initialCutOffTime / 24);
      const calculatedStaticHour = initialCutOffTime % 24;
      setStaticDay(calculatedStaticDay);
      setStaticHour(calculatedStaticHour);
    } else {
      setStaticDay(initialStaticDay);
      setStaticHour(initialStaticHour);
    }
  }, [initialCutOffType, initialCutOffTime, initialCutOffTimeOfDay, initialStaticDay, initialStaticHour]);

  useEffect(() => {
    setStartTimeActive(cutOffType === "start_time");
    setStaticActive(cutOffType === "static");
  }, [cutOffType]);

  useEffect(() => {
    setValue(`${prefix}_type`, cutOffType);
    setValue(`${prefix}_time`, cutOffTime);

    if (cutOffType === "static") {
      const calculatedStaticDay = Math.floor(cutOffTime / 24);
      const calculatedStaticHour = cutOffTime % 24;
      setValue(`${prefix}_static_day`, calculatedStaticDay);
      setValue(`${prefix}_static_hour`, calculatedStaticHour);
    }
  }, [cutOffType, cutOffTime, cutOffTimeOfDay, setValue, prefix]);

  useEffect(() => {
    if (cutOffType === "static") {
      const newCutOffTime = staticDay * 24 + staticHour;
      setCutOffTime(newCutOffTime);
    }
  }, [staticDay, staticHour, cutOffType]);

  const handleStartTimeClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setStartTimeActive(true);
    setStaticActive(false);
    setCutOffType("start_time");
    setCutOffTime(24);
  };

  const handleStaticClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setStaticActive(true);
    setStartTimeActive(false);
    setCutOffType("static");
    setCutOffTime(staticDay * 24 + staticHour);
  };

  const customStyles: StylesConfig = {
    control: (base: any) => ({
      ...base,
      background: 'white',
      border: '1px solid #ccc',
    }),
    valueContainer: (base: any) => ({
      ...base,
      height: '48px',
      display: 'flex',
      alignItems: 'center',
    }),
    multiValue: (styles: any) => ({
      ...styles,
      backgroundColor: '#27a4ff',
      padding: '4px',
      borderRadius: '4px',
    }),
    multiValueLabel: (styles: any) => ({
      ...styles,
      color: 'white',
      fontSize: '16px',
    }),
    multiValueRemove: (styles: any) => ({
      ...styles,
      color: 'white',
      ':hover': {
        color: 'white',
      },
    }),
    singleValue: (provided: any) => ({
      ...provided,
      color: 'black',
    }),
    container: (provided: any) => ({
      ...provided,
      color: '#8C8C8C',
    }),
    placeholder: (defaultStyles: any) => ({
      ...defaultStyles,
      color: '#8c8c8c',
    }),
  };

  const IndicatorSeparator = (): JSX.Element | null => null;

  return (
    <div className={styles.cutOffTimeContainer}>
      <label htmlFor="cut-off" className={styles.labelStyle}>
        {title}
      </label>
      <div className={styles.custom_text}>{customText}</div>
      <div className={styles.buttonDropdownRow}>
        <div className={styles.buttonContainer}>
          <ButtonWithTooltip
            buttonText={t("Start Time")}
            tooltipText={t("Select a cut-off time based on the start time of each lesson")}
            onClick={handleStartTimeClick}
            isActive={startTimeActive}
            orientation={'right'}
          />
        </div>
        <div className={styles.buttonContainer}>
          <ButtonWithTooltip
            buttonText={t("Static")}
            tooltipText={t("Select a cut-off time that applies to all lessons on a given day")}
            onClick={handleStaticClick}
            isActive={staticActive}
            orientation={'left'}
          />
        </div>
        {startTimeActive && (
          <div className={styles.dropdownContainer}>
            <Select
              value={cutOffTimeOptions.find(option => option.value === cutOffTime)}
              onChange={(selectedOption: { label: string; value: number } | null) => setCutOffTime(selectedOption?.value || 24)}
              options={cutOffTimeOptions}
              className={styles.dropdown}
              styles={customStyles}
              components={{ IndicatorSeparator }}
              isSearchable={false}
            />
          </div>
        )}
        {staticActive && (
          <div className={styles.dropdownContainer}>
            <Select
              value={staticDayOptions.find(option => option.value === staticDay)}
              onChange={(selectedOption: { label: string; value: number } | null) => setStaticDay(selectedOption?.value || 0)}
              options={staticDayOptions}
              className={styles.dropdown}
              styles={customStyles}
              components={{ IndicatorSeparator }}
              isSearchable={false}
            />
            <Select
              value={staticHourOptions.find(option => option.value === staticHour)}
              onChange={(selectedOption: { label: string; value: number } | null) => setStaticHour(selectedOption?.value || 0)}
              options={staticHourOptions}
              className={styles.dropdown}
              styles={customStyles}
              components={{ IndicatorSeparator }}
              isSearchable={false}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default CutOffTime;
