/**
 * @module Components.StripeForm
 *
 */
import React, { useEffect, useState } from "react";
import { useLoc } from "app/utils/hooks";
import { StripeProvider, Elements } from "react-stripe-elements";
import { StripeService } from "app/services";
import StartFreeTrialFormContainer, {
  PropsDef as StripePropsDef,
} from "./StartFreeTrialFormContainer";

// @ts-ignore
const Stripe = StripeService as any;

const STRIPE_FONTS = [
  {
    cssSrc:
      "https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap",
  },
];

export type PropsDef = StripePropsDef;

export default function StartFreeTrialFormWrapper(props: PropsDef) {
  const intl = useLoc();
  const [stripe, setStripe] = useState<stripe.Stripe>(null);

  useEffect(() => (Stripe.setLocale(intl.locale), undefined), [intl.locale]);
  useEffect(() => {
    Stripe.stripePromise().then((st: stripe.Stripe) => setStripe(st));
  }, [Stripe.stripe]);

  return (
    <StripeProvider stripe={stripe}>
      <Elements fonts={STRIPE_FONTS}>
        <StartFreeTrialFormContainer {...props} />
      </Elements>
    </StripeProvider>
  );
}
