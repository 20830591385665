import { useEffect, useState, useCallback } from "react";
import { shallowEqual } from "react-redux";
import {
  confirmVerificationCode,
  fetchPhoneCountryCodes,
  sendVerificationCode,
} from "app/actions/user-settings";
import { editPhone } from "app/actions/user";
import { useActions, useSelector } from "app/utils/hooks";
import { RootState } from "typedefs";
import { IUserPhone } from "app/interfaces/UserTypes";
import UserModel from "app/models/UserModel";
import UserSettingsModel from "app/models/UserSettingsModel";

interface Props {
  handlePhoneModal: () => void;
}

type ErrorType = {
  sendingCode: boolean;
  codeIsNotCorrect: boolean;
  somethingWentWrong: boolean;
  alreadyTaken: boolean;
};

type PhoneInfoType = {
  phone: string;
  phoneCountry: string;
  phoneNormalized?: string;
};

type VerificationResponseType = {
  id: string;
  object: string;
  status: string;
  token: string | null;
};

type ReturnType = {
  countryCodeErr: ErrorType;
  lowercaseCountryCodes: string[];
  setCode: (value: string) => void;
  handleSendVerificationCode: () => void;
  sendVerificationHandler: (value: string, data: IUserPhone) => void;
  handleTimerEnd: () => void;
  confirmCodeHandler: () => void;
  showResend: boolean;
  step: number;
  closeModal: () => void;
  error: ErrorType;
  self: UserModel;
  phoneInfo: PhoneInfoType;
};

export const useAccountPhoneVerificationModel = ({
  handlePhoneModal,
}: Props): ReturnType => {
  const actions = useActions({
    fetchPhoneCountryCodes,
    sendVerificationCode,
    confirmVerificationCode,
    editPhone,
  });

  const { countryCodes, countryCodeErr } = useSelector(
    (state: RootState): UserSettingsModel => state.userSettings.fetch,
    shallowEqual,
  );

  const { self } = useSelector(
    (state: RootState): UserModel => state.user,
    shallowEqual,
  );

  const [step, setStep] = useState<number>(1);
  const [lowercaseCountryCodes, setLowercaseCountryCodes] = useState<string[]>(
    [],
  );
  const [phoneInfo, setPhoneInfo] = useState<PhoneInfoType>({
    phone: "",
    phoneCountry: "",
    phoneNormalized: "",
  });
  const [error, setError] = useState<ErrorType>({
    sendingCode: false,
    codeIsNotCorrect: false,
    somethingWentWrong: false,
    alreadyTaken: false,
  });
  const [code, setCode] = useState<string>("");
  const [verificationResponse, setVerificationResponse] =
    useState<VerificationResponseType | null>(null);
  const [showResend, setShowResend] = useState(false);

  const closeModal = useCallback(() => {
    setStep(1);
    handlePhoneModal();
  }, [handlePhoneModal]);

  useEffect(() => {
    actions.fetchPhoneCountryCodes();
  }, [actions]);

  useEffect(() => {
    if (countryCodes) {
      const lowercaseCodes = countryCodes.map((code) => code.toLowerCase());

      if (self?.phoneVerified) {
        lowercaseCodes.push(self.phoneCountry.toLowerCase());
      }

      setLowercaseCountryCodes(lowercaseCodes);
    }
  }, [countryCodes, self]);

  const sendVerificationHandler = useCallback(
    (value: string, data: IUserPhone) => {
      const phoneNormalized = value;
      const phone = value.substring(data.dialCode.length);
      const phoneCountry = data.countryCode.toUpperCase();

      setPhoneInfo({ phoneNormalized, phone, phoneCountry });
    },
    [],
  );

  const handleSendVerificationCode = useCallback(async () => {
    if (!phoneInfo.phone) {
      setError((prev) => ({
        ...prev,
        somethingWentWrong: true,
      }));
      return;
    }

    try {
      const response = await actions.sendVerificationCode({
        phone: phoneInfo.phone,
        phone_country: phoneInfo.phoneCountry,
      });

      setVerificationResponse(response);
      if (step === 1) {
        setStep((prevStep) => prevStep + 1);
      }

      setError({
        sendingCode: false,
        codeIsNotCorrect: false,
        somethingWentWrong: false,
        alreadyTaken: false,
      });

      setShowResend(false);
    } catch (err) {
      console.error(
        "Something went wrong at handleSendVerificationCode: ",
        err,
      );
    }
  }, [actions, phoneInfo]);

  const handleTimerEnd = useCallback(() => {
    setShowResend(true);
  }, []);

  const fetchConfirmVerifCode = async () => {
    try {
      await actions.confirmVerificationCode({
        id: verificationResponse.id,
        code,
      });
    } catch (err) {
      if (err?.errors?.[0] === "sms_gateway_invalid_verification_code") {
        setError({
          codeIsNotCorrect: true,
          sendingCode: false,
          somethingWentWrong: false,
          alreadyTaken: false,
        });
      } else {
        setError({
          somethingWentWrong: true,
          sendingCode: false,
          codeIsNotCorrect: false,
          alreadyTaken: false,
        });
      }
      return false;
    }

    return true;
  };

  const fetchEditPhone = async () => {
    try {
      await actions.editPhone({
        verification_token: verificationResponse.token,
      });
    } catch (err) {
      if (err?.errors?.[0] === "phone_already_taken") {
        setError({
          alreadyTaken: true,
          sendingCode: false,
          codeIsNotCorrect: false,
          somethingWentWrong: false,
        });
      } else {
        setError({
          somethingWentWrong: true,
          sendingCode: false,
          codeIsNotCorrect: false,
          alreadyTaken: false,
        });
      }
      return false;
    }

    return true;
  };
  const confirmCodeHandler = async () => {
    if (!code || !verificationResponse?.id) {
      return;
    }

    const isCodeConfirmed = await fetchConfirmVerifCode();
    if (!isCodeConfirmed) {
      return;
    }

    const isPhoneEdited = await fetchEditPhone();
    if (!isPhoneEdited) {
      return;
    }

    if (step === 2) {
      closeModal();
    }

    setError({
      sendingCode: false,
      codeIsNotCorrect: false,
      somethingWentWrong: false,
      alreadyTaken: false,
    });
    setShowResend(false);
  };
  return {
    countryCodeErr,
    lowercaseCountryCodes,
    setCode,
    sendVerificationHandler,
    handleSendVerificationCode,
    handleTimerEnd,
    confirmCodeHandler,
    showResend,
    step,
    closeModal,
    error,
    self,
    phoneInfo,
  };
};
