import { RootState } from "typedefs";
import { useSelector } from "app/utils/hooks";
import { CoachProfileModel } from "app/models/scheduling/CoachProfileModel";

const useCurrentCoachProfile = (): { data: CoachProfileModel, pending: boolean } => {
    const currentUser = useSelector((state: RootState) => state.user);
    const facilityCoaches = useSelector((state: RootState) => state.user?.self?.scheduling?.facility.coachProfiles) || [];

    const currentCoach = facilityCoaches.find(c => c.id === currentUser.self.id);

    return { data: currentCoach, pending: !currentCoach };
};

export default useCurrentCoachProfile;
