import React from "react";
import styles from "./styles.module.scss";
import SearchBar from "app/components/inputs/SearchBar";
import ContentPanel from "app/components/Layouts/ContentPanel";
import TabButton from "app/components/Button/TabButton";
import { cls } from "app/utils";
import GroupMember from "app/components/GroupMember";
import { useGroupMembersModel } from "./indexModel";
import UserModel from "app/models/UserModel";
import MembersRoleFilter from "app/components/MembersRoleFilter";
import DropdownButton from "app/components/DropdownButton";
import Button from "app/components/Button/ButtonVariant";
import GroupInviteModal from "../_components/GroupInviteModal";
import GroupPageLayout from "app/components/Layouts/GroupPageLayout";
import GroupTabsContainer from "../_components/GroupTabsContainer";
import { useTranslation } from "react-i18next";
import { Loading } from "app/components/Wrappers";

export type tabType = {
  label: "Active" | "Invited" | "Incomplete";
  value: "accepted" | "pending" | "incomplete";
};

const GroupMembers = () => {
  const { t } = useTranslation();

  const {
    selectedGroup,
    canManageMembers,
    tabs,
    selectedTab,
    onTabSelect,
    members,
    membersFilterHandler,
    sentryRef,
    onDeleteMember,
    onMemberUpdate,
    memberFilterParams,
    memberSearchHandler,
    inviteMembersModalOpen,
    modalInviteIsOpen,
    pending,
  } = useGroupMembersModel();

  const allRolesBtnTitle = () => {
    return (
      <>
        {t("All Roles")} <i className="ico ico-chevron" />
      </>
    );
  };

  const allRolesBtn = (
    <Button
      title={allRolesBtnTitle()}
      buttonStyles={styles.dropdown_button}
      buttonType="secondary-outline"
    />
  );

  return (
    <GroupPageLayout>
      <div className={styles.group_tabs_container}>
        <GroupTabsContainer groupData={selectedGroup} />
      </div>
      <ContentPanel
        className={cls({
          [styles.panel]: canManageMembers,
        })}
      >
        <div className={styles.title_header_wrapper}>
          <h2 className={cls("text-muted", styles.header_title)}>
            {t("Members")}
          </h2>
          <Button
            title={t("Invite Members")}
            buttonType="primary"
            onClickFunc={inviteMembersModalOpen}
            buttonStyles={styles.invite_member_btn}
          />
        </div>
        {canManageMembers && (
          <div className={styles.members_tabs}>
            {tabs.map((tab: tabType) => {
              return (
                <TabButton
                  key={tab.label}
                  label={tab.label}
                  className={styles.tab_button}
                  isActive={tab.label === selectedTab.label}
                  onClick={() => onTabSelect(tab as tabType)}
                />
              );
            })}
          </div>
        )}
      </ContentPanel>

      <ContentPanel>
        <div className={styles.search_container}>
          <SearchBar
            className={styles.search_input}
            placeholder={t("Search Members")}
            onChange={memberSearchHandler}
          />
          <DropdownButton
            dropdownClassname={styles.dropdownClassname}
            btnContent={allRolesBtn}
            btnClassName={styles.dropdown_btn}
          >
            <MembersRoleFilter
              memberFilterParams={memberFilterParams}
              onChange={membersFilterHandler}
            />
          </DropdownButton>
        </div>
        <div className={styles.members_list}>
          {members?.map((member: UserModel, index: number) => {
            return (
              <GroupMember
                key={member.userId ?? index}
                member={member}
                role={
                  member?.userId === selectedGroup?.ownerId ? t("Owner") : null
                }
                onRemoveMember={onDeleteMember}
                onMemberUpdate={onMemberUpdate}
              />
            );
          })}
          {!pending && members?.length === 0 && (
            <div className={styles.no_membbers}>{t("No members found.")}</div>
          )}
        </div>
        {pending && <Loading isLoading={pending} loadType="spinner" />}
        {!pending && members?.length > 0 && <div ref={sentryRef} />}
      </ContentPanel>
      {modalInviteIsOpen && (
        <GroupInviteModal defaultModalContent="invite-members" />
      )}
    </GroupPageLayout>
  );
};

export default GroupMembers;
