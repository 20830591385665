import React, { useLayoutEffect, useState } from "react";
import styles from "./style.module.scss";
import { useStartYourFreeTrialModel } from "./indexModel";
import coachnow_logo from "app/images/Logo_dark.svg";
import PriceCard from "./_components/PriceCard";
import BillingInformation from "./_components/BillingInformation";
import price_tag from "app/images/green_price_tag.svg";
import { Toast } from "app/components/helpers";
import useQuery from "app/utils/hooks/useQuery";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import { cls } from "app/utils";
import { useSelector } from "react-redux";
import { RootState } from "typedefs";
import { Authenticated } from "app/components/Wrappers";

const PRICE_PER_MONTH = "$499";

const StartYourFreeTrial = () => {
  const { t } = useStartYourFreeTrialModel();
  const history = useHistory();
  const { self } = useSelector((state: RootState) => state.user);
  const [toastAlert, setToastAlert] = useState(null);
  const query = useQuery();
  const location = useLocation();

  useLayoutEffect(() => {
    if (query.get("promo") !== "pga6") {
      history.push("/404");
    }
  }, [query, history, self]);

  const onDone = () => {
    setToastAlert(t("Your account was successfully updated."));
  };

  const hasActiveTrial = self?.subscription?.trialEndsAt >= new Date();

  if (
    hasActiveTrial ||
    self?.subscription?.status === "active" ||
    self?.subscription?.status === "trial" ||
    self?.hasActiveSubscription ||
    self?.hasCoachPlus ||
    self?.hasTrialSubscription
  ) {
    return <Redirect to="/feed" />;
  }

  return (
    <Authenticated redirect="/signup">
      <div
        className={cls(styles.container, "start-pga-trial", {
          [styles.page_background]: location.pathname.includes("signup"),
        })}
      >
        <img
          className={styles.site_logo}
          src={coachnow_logo}
          alt="CoachNow Icon"
        />
        <div className={styles.content_container}>
          <div className={styles.header}>
            <h3 className={styles.header_title}>
              {t("Start your Free Trial")}
            </h3>
            <p className={styles.header_description}>
              {t("To get started enter your card details")}
            </p>
          </div>
          <div className={styles.promocode_applied}>
            <img
              src={price_tag}
              alt="coupon tag"
              className={styles.price_tag}
            />
            <div className={styles.promo_applied_wrapper}>
              <p className={styles.pga_promo_applied}>
                {t("PGA Show Offer Applied")}:
              </p>
              <p className={styles.coupon_description}>
                {t("You’ll get a 6 Months of Free Trial")}
              </p>
            </div>
          </div>
          <PriceCard price={PRICE_PER_MONTH} />
          <BillingInformation onDone={onDone} />
        </div>

        <Toast success={toastAlert} onDone={() => setToastAlert(null)} />
      </div>
    </Authenticated>
  );
};

export default StartYourFreeTrial;
