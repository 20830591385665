import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { useActions } from "app/utils/hooks";
import { fetchNotifications } from "app/actions/Notification";
import { NotificationModel } from "app/models/NotificationModel";
import SingleNotification from "../NotificationsList/SingleNotification";

const RecentActivities = () => {
  const notificationActions = useActions({ fetchNotifications });
  const [data, setData] = useState<NotificationModel[]>([]);

  useEffect(() => {
    notificationActions
      .fetchNotifications({ page: 1, per_page: 4 })
      .then((res: { notifications: NotificationModel[] }) => {
        if (res?.notifications?.length > 0) {
          console.log(res);
          setData(res?.notifications);
        }
      });
  }, []);

  return (
    <div className={styles.container}>
      {data.map((activity: NotificationModel) => {
        return (
          <SingleNotification
            key={activity.id}
            notification={activity}
            className={styles.notification}
          />
        );
      })}
    </div>
  );
};

export default RecentActivities;
