import React from "react";
import styles from './styles.module.scss';
import OptionButton from "app/components/OptionButtonGroup/OptionButton";
import LabelTimeOption from "app/components/OptionButtonGroup/LabelTimeOption";

interface OptionProps {
  day: number;
  value: number;
  label: string;
  short: string;
  active: boolean;
  startTime: string;
  endTime: string;
}

interface OptionButtonProps {
  options: OptionProps[];
  selected: OptionProps[];
  setSelected: any;
  onChange: any;
  shortButtons: boolean;
}

const OptionButtonGroup = ({
  options,
  selected,
  setSelected,
  onChange,
  shortButtons = false,
}: OptionButtonProps) => {

  return (
    <div>
      <div className={styles.option_button_group}>
        {options.map((option) => (
          <OptionButton
            key={option.value}
            option={option}
            selected={selected}
            setSelected={setSelected}
            shortButtons={shortButtons}
          />
        ))}
      </div>
      <>
        {selected.map((el) => (
          <LabelTimeOption
            key={el.value}
            index={el.value}
            label={el.label}
            timeFrom={el.startTime}
            timeTo={el.endTime}
            onChange={onChange}
          />
        ))}
      </>
      <div className={styles.horizontal_line} />
    </div>
  );
};

export default OptionButtonGroup;
