import { useCallback, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "typedefs";

export const useScheduleBtnModel = () => {
  const [isOpen, setIsOpen] = useState(false);
  const scheduledIn = useSelector(
    (state: RootState) =>
      // @ts-ignore
      state?.schedulePost?.scheduleIn?.pickedIn.slice(0, -1),
    shallowEqual,
  );

  const handleScheduleModal = useCallback(() => {
    setIsOpen((prev) => !prev);
  }, []);

  return { handleScheduleModal, isOpen, scheduledIn };
};
