import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { fetchConnections } from "app/actions/connection";
import { useStoreActions } from "app/utils/hooks";
import { Loading } from "app/components/Wrappers";
import styles from "./styles.module.scss";
import DropdownButton from "app/components/DropdownButton";
import { cls } from "app/utils";
import { useTranslation } from "react-i18next";
import UserModel from "app/models/UserModel";
import UserAvatar from "app/components/UserAvatar";
import Fuse from "../../../../vendor/fuse";
import Button from "app/components/Button/ButtonVariant";
import UsersList from "./UsersList";
import Dropdown from "app/components/Dropdown";
import AddNewUser from "app/components/AddNewUser";
import { uuid } from "app/utils/uuid";

const UserSelect = ({
  onChange,
  isCreatable,
  noOptionsMessage,
  addNewUserModalTitle,
  displayAddUser,
  value = [],
  hideEmail,
}: {
  onChange: (selectedUsers: UserModel[]) => void;
  isCreatable?: boolean;
  noOptionsMessage?: string | null;
  addNewUserModalTitle?: string;
  displayAddUser?: boolean;
  value?: UserModel[];
  hideEmail?: boolean;
}) => {
  const { connection } = useStoreActions({ connection: fetchConnections });
  const [userOptions, setUserOptions] = useState<UserModel[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [selectedUsers, setSelectedUsers] = useState<UserModel[]>([]);
  const [addNewUserModal, setAddNewUserModal] = useState(false);
  const { t } = useTranslation();

  const selectInputReference = useRef<HTMLInputElement>(null);

  // Sync selected users with the `value` prop
  useEffect(() => {
    setSelectedUsers(value);
    setUserOptions(() =>
      connection?.data?.filter(
        (option: UserModel) => !value?.some((user) => user?.id === option?.id),
      ),
    );
  }, [value, connection.data]);

  // Fuse setup for search filtering
  const fuseOptions = {
    keys: ["displayName", "email"],
    includeScore: true,
    shouldSort: true,
    threshold: 0.1,
  };
  const fuse = useMemo(
    () => new (Fuse as any)(userOptions, fuseOptions),
    [userOptions, fuseOptions],
  );

  const results = useMemo(() => {
    if (searchTerm) {
      return fuse
        .search(searchTerm)
        ?.map((result: any) => result.item)
        ?.filter(
          (user: UserModel) => !selectedUsers.some((u) => u.id === user.id),
        );
    }
    return userOptions?.filter(
      (user) => !selectedUsers.some((u) => u.id === user.id),
    );
  }, [searchTerm, userOptions, fuse, selectedUsers]);

  const handleSearchChange = useCallback((value: string) => {
    setSearchTerm(value || "");
  }, []);

  const toggleUserSelection = useCallback(
    (user: UserModel) => {
      setSelectedUsers((prevSelected) => {
        const isSelected = prevSelected.some((u) => u.id === user.id);
        const updatedSelection = isSelected
          ? prevSelected?.filter((u) => u.id !== user.id)
          : [...prevSelected, user];
        onChange(updatedSelection);
        return updatedSelection;
      });

      setUserOptions((prevOptions) =>
        prevOptions.some((u) => u.id === user.id)
          ? prevOptions?.filter((u) => u.id !== user.id)
          : [...prevOptions, user],
      );

      if (selectInputReference.current) {
        selectInputReference.current.value = "";
        setSearchTerm("");
      }
    },
    [onChange],
  );

  const removeUser = useCallback(
    (user: UserModel) => {
      setSelectedUsers((prevSelected) => {
        const updatedSelection = prevSelected?.filter((u) =>
          u.id ? u.id !== user.id : u.email !== user.email,
        );
        onChange(updatedSelection);
        return updatedSelection;
      });
      setUserOptions((prevOptions) =>
        prevOptions.some((u) => u.id === user.id)
          ? prevOptions
          : [...prevOptions, user],
      );
    },
    [onChange],
  );

  const dropdownContent = () => (
    <span className={styles.input_container}>
      <input
        type="text"
        className={styles.input}
        onChange={(e) => handleSearchChange(e.target.value)}
        placeholder={t("Search by name or email")}
        ref={selectInputReference}
      />
      <span className={styles.svg_container}>
        <DropdownIndicator />
      </span>
    </span>
  );

  const addNewUserModalHandler = () => setAddNewUserModal((prev) => !prev);

  const addNewUser = (newUser: UserModel) => {
    const newUserObj = { id: uuid(), ...newUser, displayName: newUser.name };
    setSelectedUsers((prev) => {
      const updatedSelection = [...prev, newUserObj];
      onChange(updatedSelection);
      return updatedSelection;
    });
    setUserOptions((prevOptions) =>
      prevOptions?.filter((option) => option.id !== newUserObj.id),
    );

    if (selectInputReference.current) {
      selectInputReference.current.value = "";
      setSearchTerm("");
    }
  };

  if (connection.pending) {
    return <Loading loadType="spinner" isLoading />;
  }

  return (
    <>
      <DropdownButton
        closeOnClick
        hideButtonStyles
        dropdownWrapperClass={cls(styles.dropdown)}
        btnClassName={styles.btnn}
        btnContent={dropdownContent()}
      >
        <UsersList
          isCreatable={isCreatable}
          results={results}
          toggleUserSelection={toggleUserSelection}
          noOptionsMessage={noOptionsMessage}
          onNoOptionBtnClick={addNewUserModalHandler}
          displayAddUser={displayAddUser}
          hideEmail={hideEmail}
        />
      </DropdownButton>
      {isCreatable && addNewUserModal && (
        <Dropdown
          dropdownWrapperClass={styles.add_new_user_dropdown}
          isOpen={addNewUserModal}
          onClose={addNewUserModalHandler}
        >
          <AddNewUser
            title={addNewUserModalTitle}
            onAddUser={addNewUser}
            onCancel={addNewUserModalHandler}
            searchInput={searchTerm}
          />
        </Dropdown>
      )}
      {selectedUsers.length > 0 && (
        <SelectedUsers
          onRemoveUserClick={removeUser}
          selectedAuthors={selectedUsers}
        />
      )}
    </>
  );
};

const SelectedUsers = memo(
  ({
    selectedAuthors,
    onRemoveUserClick,
  }: {
    selectedAuthors: UserModel[];
    onRemoveUserClick: (author: UserModel) => void;
  }) => (
    <div className={styles.users_list}>
      {selectedAuthors.map((author, index) => (
        <div className={styles.user_container} key={author.id ?? index}>
          <div className={styles.user_info_container}>
            <UserAvatar
              className={styles.avatar}
              sizeWidth={44}
              sizeHeight={44}
              altName="author"
              userName={author.displayName}
              srcName={author.avatar as string}
            />
            <div className={styles.user_info}>
              <h4>{author.displayName}</h4>
              <p>{author.email}</p>
            </div>
          </div>
          <Button
            title={<i className={cls("ico ico-x text-danger")} />}
            onClickFunc={() => onRemoveUserClick(author)}
            buttonType="secondary-outline"
            buttonStyles={styles.delete_button}
          />
        </div>
      ))}
    </div>
  ),
);

const DropdownIndicator = () => (
  <svg
    height="20"
    width="20"
    viewBox="0 0 20 20"
    aria-hidden="true"
    focusable="false"
    className={cls("css-6q0nyr-Svg", styles.dropdown_indicator)}
  >
    <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z" />
  </svg>
);

export default memo(UserSelect);
