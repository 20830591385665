import React from "react";
import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";
import { Token } from "app/utils/stripe";
import StartFreeTrialForm from "app/components/StripeForm/StartFreeTrialForm";

interface IProps {
  onDone?: (token: Token) => void;
  onCancel?: () => void;
}

const BillingInformation = ({ onDone }: IProps) => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <h3 className={styles.billint_title}>{t("Billing Information")}</h3>
      <StartFreeTrialForm onToken={onDone} />
    </div>
  );
};

export default BillingInformation;
