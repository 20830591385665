/**
 * @module Components.Routes
 *
 */
import React, { useState, useEffect, useRef } from "react";
import { MessageDescriptor } from "react-intl";
import { Promise, resolve, reject } from "rsvp";
import { Link, Redirect, useParams } from "react-router-dom";
import { useUrlSearch, useError, usePending } from "app/utils/hooks";
import { AuthService, PhoneVerificationService } from "app/services";
import {
  UserModel,
  TeamModel,
  InvitationModel,
  VerificationModel,
} from "app/models";
import { fetchPost, fetchGet } from "app/utils/request";
import { routeNative } from "app/utils/router";
import SignUpForm from "./SignUpForm";
import BillingInfo from "./BillingInfo";
import AboutYou from "./AboutYou";
import TeamAvatar from "app/components/TeamAvatar";
import Styles from "./styles.module.scss";
import config from "config/environment";
import FormItem from "app/components/inputs/FormItem";
import ResendButton from "app/components/PhoneVerification/ResendButton";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { IUserPhone } from "app/interfaces/UserTypes";
import { Trans, useTranslation } from "react-i18next";
import Wrapper from "app/components/Onboarding/Wrapper";
import { cls } from "app/utils";

const Auth = AuthService as any;

const userState: Partial<UserModel> = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  kind: "",
  ageConsent: true,
  tosConsent: false,
  consentToken: undefined,
  avatar: undefined,
};

type State = {
  user: Partial<UserModel>;
  lockEmail: boolean;
  showSendConfirmationCode: boolean;
  showCodeVerification: boolean;
  showSignUp: boolean;
  showBilling: boolean;
  showAbout: boolean;
  showStartTrial: boolean;
  invalidInvite: boolean;
  verification: Partial<VerificationModel> | null;
  phone: string;
  phoneCountry: string;
  phoneNormalized: string;
};

const initState: State = {
  user: userState,
  lockEmail: false,
  showSendConfirmationCode: false,
  showCodeVerification: false,
  showSignUp: false,
  showBilling: false,
  showAbout: false,
  showStartTrial: false,
  invalidInvite: false,
  verification: null,
  phone: null,
  phoneCountry: null,
  phoneNormalized: null,
};

type OptionalState = {
  showSendConfirmationCode?: boolean;
  showCodeVerification?: boolean;
  showSignUp?: boolean;
  showBilling?: boolean;
  showAbout?: boolean;
  showStartTrial?: boolean;
};

const initTeam: TeamModel | null = null;
const initInvit: InvitationModel | null = null;

export type RouteParamsDef = {
  invitation_token?: string;
  team_token?: string;
  team_role?: string;
  user_role?: string;
};

type ErrorState = {
  loginError?: MessageDescriptor | string | null;
};

const initError: ErrorState = {
  loginError: null,
};

const WELCOME_COACH_URL = "https://app.coachnow.io/getting-started";

/**
 * @class Signup
 *
 */
export default function SignUp() {
  const { t } = useTranslation();

  const LOCALES = {
    phone_verification_unprocessable_entity: {
      id: "error.signin-phone-verification.unprocessable_entity",
      defaultMessage: t("Signin failed. Please provide a valid phone number"),
    },
    phone_verification_unknown: {
      id: "error.signin-phone-verification.unknown",
      defaultMessage: t("Signin failed. Error unknown"),
    },
  };

  const [state, setState] = useState(initState);
  const [teamState, setTeam] = useState(initTeam);
  const [invitationState, setInvitation] = useState(initInvit);
  // @ts-ignore
  const { invitation_token, team_token, team_role, user_role } = useParams();
  const [searchParams] = useUrlSearch();
  const [pending, startPending, stopPending] = usePending();
  const [errorState, addErrors, clearErrors] = useError(initError);
  const codeRef = useRef<HTMLInputElement>(null);
  const isCoachSignup = user_role && user_role === "coach";

  useEffect(() => {
    if (team_token != null) {
      loadTeam(team_token)
        .then((model) => joinTeam(model))
        .catch(() => setState({ ...state, invalidInvite: true }));
    } else if (invitation_token != null) {
      loadInvitation(invitation_token)
        .then((model: InvitationModel) => acceptInvitation(model))
        .catch(() => setState({ ...state, invalidInvite: true }));
    }
  }, []);

  useEffect(() => {
    if (invitationState != null) {
      const email = invitationState.email;
      const phone = invitationState.phone;
      const phoneCountry = invitationState.phoneCountry;
      const phoneNormalized = invitationState.phoneNormalized;
      const [firstName, lastName] = (invitationState.name || " ").split(" ");

      setState({
        ...state,
        user: { ...state.user, email, firstName, lastName },
        phone,
        phoneCountry,
        phoneNormalized,
        showSendConfirmationCode: phone != null && phoneCountry != null,
        lockEmail: phone === null,
      });
    }
  }, [invitationState]);

  const handlePhoneVerification = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    startPending();

    (PhoneVerificationService as any)
      .verify(state.phone, state.phoneCountry)
      .catch(
        (
          err:
            | "phone_verification_unprocessable_entity"
            | "phone_verification_unknown"
        ) => {
          addErrors({ loginError: LOCALES[err] });
          stopPending();
        }
      )
      .then((verification: VerificationModel) => {
        stopPending();
        setState({
          ...state,
          verification,
          showSendConfirmationCode: false,
          showCodeVerification: true,
        });
      });
  };

  const handleCodeConfirmation = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    clearErrors();

    const codeEl = codeRef.current;
    const verification = state.verification;

    if (codeEl == null || verification == null) {
      addErrors({
        loginError: t("Signin failed. Verification code is invalid."),
      });
      return reject();
    }

    const code = codeEl.value.trim();

    startPending();
    return (PhoneVerificationService as any)
      .confirm(verification.id, code)
      .catch(() => {
        addErrors({
          loginError: t("Signin failed. Verification code is invalid."),
        });
        stopPending();

        return reject();
      })
      .then(() => {
        setState({ ...state, showCodeVerification: false, showSignUp: true });
      });
  };

  const handlePhoneInputChange = (
    value: string,
    data: IUserPhone,
    _event: React.FormEvent<HTMLInputElement>,
    formattedValue: string
  ) => {
    const phoneNormalized = formattedValue;
    const phone = value.substring(data.dialCode.length);
    const phoneCountry = data.countryCode.toUpperCase();

    setState({ ...state, phone, phoneCountry, phoneNormalized });
  };

  const joinTeam = (team: TeamModel, search?: string): void => {
    search = search || "";
    if (Auth.isAuthenticated) {
      fetchGet(`/teams/${team.id}`)
        .catch(() => resolve())
        .then((data) => {
          if (data == null) {
            const query =
              team_role != null ? { encrypted_role: team_role } : {};
            fetchPost(`/teams/${team_token}/join`, query, { version: 2 })
              .then(() =>
                routeNative(`/groups/${team.id}${search}`, { reload: true })
              )
              .catch(() => routeNative(`/groups${search}`, { reload: true }));
          } else {
            // Auth.invalidate(true);
            setTeam(team);
          }
        });
    } else {
      setTeam(team);
    }
  };

  const acceptInvitation = (
    invitation: InvitationModel,
    search?: string
  ): void => {
    search = search || "";
    if (Auth.isAuthenticated) {
      Auth.getAuthUser().then((user: UserModel) => {
        if (
          invitation.email === user.email ||
          (user.phoneVerified && invitation.phone === user.phone)
        ) {
          const afterUrl =
            invitation.object === "team_invitation"
              ? `/groups/${invitation.teamId}`
              : `/spaces/${invitation.spaceId}`;
          const afterErrorUrl =
            invitation.object === "team_invitation" ? `/groups` : `/spaces`;
          const query = { id: invitation.id };
          fetchPost(`/invitations/${invitation.id}/accept`, query, {
            version: 1,
          })
            .then(() => routeNative(afterUrl + search, { reload: true }))
            .catch(() => routeNative(afterErrorUrl + search, { reload: true }));
        } else {
          // Auth.invalidate(true);
          setInvitation(invitation);
        }
      });
    } else {
      setInvitation(invitation);
    }
  };

  const updateUser = (userData: Partial<UserModel>, other?: OptionalState) => {
    // const user = { ...state.user, ...userData };
    // eslint-disable-next-line no-console
    // setState({ ...state, ...other, user });
    const updatedUser = { ...state.user, ...userData };
    setState({ ...state, ...other, user: updatedUser });
    // console.log("state & updatedUser", { ...state, updatedUser });
  };

  const accountCreated = (data: { user: UserModel }) => {
    // eslint-disable-next-line no-console
    // console.log("{user} data at accountCreated", data);

    updateUser(data.user, { showSignUp: false, showAbout: true });
  };

  const pgaPromoUrl = searchParams?.return_url?.includes(
    "/start-trial?promo=pga6"
  );

  const aboutYouCompleted = (data: UserModel) => {
    const coachRoleSelected = data.kind === "coach";

    if (teamState != null) {
      return joinTeam(teamState, "/?firstUse=true");
    }
    if (invitationState != null) {
      return acceptInvitation(invitationState, "/?firstUse=true");
    } else {
      if (pgaPromoUrl) {
        return routeNative("/start-trial?promo=pga6", {
          reload: true,
        });
      }

      updateUser(data, { showAbout: false });

      if (coachRoleSelected || isCoachSignup) {
        (window as any).location = WELCOME_COACH_URL;
        console.log("what do you do here");
      }
    }
  };

  if (
    !state.invalidInvite &&
    !invitationState &&
    !teamState &&
    (team_token || invitation_token)
  ) {
    return <Wrapper loading={true}></Wrapper>;
  }

  // Join and redirect to groups/spaces if user has already an account
  if (
    (team_token || invitation_token) &&
    Auth.isAuthenticated &&
    !state.showAbout
  ) {
    if (teamState != null) {
      const targetUrl = "/groups/" + teamState.id;
      joinTeam(teamState);
      routeNative(targetUrl, { reload: true });
    } else if (invitationState != null) {
      const targetUrl =
        invitationState.object === "team_invitation"
          ? `/groups/${invitationState.teamId}`
          : `/spaces/${invitationState.spaceId}`;
      acceptInvitation(invitationState);
      routeNative(targetUrl, { reload: true });
    }
  }

  if (
    !team_token &&
    !invitation_token &&
    Auth.isAuthenticated &&
    !state.showAbout
  ) {
    if (searchParams.return_url) {
      return <Redirect to={searchParams.return_url} />;
    } else if (searchParams.returnUrl) {
      return <Redirect to={searchParams.returnUrl} />;
    }
    const defaultReturnUrl = "/?firstUse=true&kind=" + state.user.kind;
    return <Redirect to={defaultReturnUrl} />;
  }

  const scComp = (
    <div>
      <div>{t("Please enter your phone number to continue")}</div>

      <form
        noValidate
        onSubmit={(e) => handlePhoneVerification(e)}
        target="_self"
      >
        <FormItem>
          <PhoneInput
            country={
              invitationState && invitationState.phoneCountry
                ? invitationState.phoneCountry.toLowerCase()
                : ""
            }
            value={invitationState ? invitationState.phoneNormalized : ""}
            countryCodeEditable={false}
            onChange={handlePhoneInputChange}
            inputStyle={{ width: "100%", height: "42px" }}
            inputProps={{ autoFocus: true }}
          />
        </FormItem>

        <FormItem error={errorState.loginError}>
          <button
            className="btn btn-primary full signin mt-0"
            disabled={pending.status}
          >
            {t("Continue")}
          </button>
        </FormItem>
      </form>
    </div>
  );

  const cvComp = (
    <div>
      <div className={Styles.confirmationSentText}>
        (
        <Trans
          i18nKey="confirmation_code_sent"
          components={{ strong: <strong />, br: <br /> }}
          values={{ phoneNumber: state.phoneNormalized }}
        />
        )
      </div>

      <form
        noValidate
        onSubmit={(e) => handleCodeConfirmation(e)}
        target="_self"
      >
        <FormItem>
          <input
            className="code"
            name="code"
            ref={codeRef}
            placeholder={t("Verification Code")}
            type="text"
          />
        </FormItem>

        <ResendButton phone={state.phone} phoneCountry={state.phoneCountry} />

        <FormItem error={errorState.loginError}>
          <button
            className="btn btn-primary full signin mt-0"
            disabled={pending.status || !state.verification}
          >
            {t("Next")}
          </button>
        </FormItem>
      </form>
    </div>
  );

  const sfComp = (
    <SignUpForm
      user={state.user}
      verificationToken={state.verification ? state.verification.token : null}
      phoneCountry={state.phoneCountry}
      phoneNormalized={state.phoneNormalized}
      lockEmail={state.lockEmail}
      onUserChange={updateUser}
      onDone={accountCreated}
      onSignin={(user: UserModel) =>
        updateUser(user, { showBilling: false, showAbout: false })
      }
      onConsent={(user: UserModel) => updateUser(user, { showBilling: true })}
      hideOutsideSources={teamState != null || invitationState != null}
      coachSignup={isCoachSignup}
    />
  );

  const biComp = (
    <BillingInfo
      onDone={(token: string) =>
        updateUser({ consentToken: token }, { showBilling: false })
      }
      onCancel={() => setState({ ...state, showBilling: false })}
    />
  );

  const aboutComp = (
    <AboutYou
      user={state.user}
      coachSignup={isCoachSignup}
      onComplete={aboutYouCompleted}
      startTrial={!pgaPromoUrl}
    />
  );

  let showing = state.showSendConfirmationCode ? cvComp : sfComp;

  if (state.showSignUp) {
    showing = sfComp;
  } else if (state.showSendConfirmationCode) {
    showing = scComp;
  } else if (state.showCodeVerification) {
    showing = cvComp;
  } else if (state.showBilling) {
    showing = biComp;
  } else if (state.showAbout) {
    showing = aboutComp;
  } else if (state.invalidInvite) {
    showing = <InvalidInvite />;
  }

  let header = <div className={Styles.teamHeader}></div>;
  if (teamState != null) {
    header = (
      <InviteHeader
        type="team"
        name={teamState.name || ""}
        logo={teamState.logo}
        sport={teamState.sport}
      />
    );
  } else if (invitationState != null) {
    if (invitationState.object === "team_invitation") {
      header = (
        <InviteHeader
          type="invitation_team"
          name={invitationState.teamName}
          sport={invitationState.sport}
          user={invitationState.name}
          createdBy={invitationState.inviterName}
        />
      );
    } else {
      header = (
        <InviteHeader
          type="invitation_space"
          name={invitationState.spaceName || ""}
          user={invitationState.name}
          createdBy={invitationState.inviterName}
        />
      );
    }
  }

  return (
    <Wrapper>
      {header}
      {showing}
    </Wrapper>
  );
}

type InviteProps = {
  type: string;
  name: string;
  user?: string;
  logo?: string;
  sport?: string;
  createdBy?: string;
};

const InviteHeader = (props: InviteProps) => {
  const { t } = useTranslation();

  let body = <div />;
  if (props.type === "team") {
    body = (
      <div>
        <Trans
          i18nKey="signup_join_group"
          components={{ strong: <strong /> }}
          values={{ name: `${props.name} / ${props.sport}` }}
        />
      </div>
    );
  } else if (props.type === "invitation_team") {
    const name =
      props.sport != null ? `${props.name} / ${props.sport}` : props.name;
    body = (
      <div>
        <p>
          <Trans
            i18nKey="signup_join_group"
            components={{ strong: <strong /> }}
            values={{ name }}
          />
        </p>
        <p>{t("created by {{creator}}", { creator: props.createdBy })}</p>
      </div>
    );
  } else if (props.type === "invitation_space") {
    body = (
      <div>
        <p>
          <Trans
            i18nKey="signup_join_trainingspace"
            components={{ strong: <strong /> }}
            values={{ name: props.name }}
          />
        </p>
        <p>{t("created by {{creator}}", { creator: props.createdBy })}</p>
      </div>
    );
  }

  let header = <div />;
  if (props.logo) {
    header = (
      <TeamAvatar
        className="avatar"
        srcName={props.logo}
        altName={props.name}
      />
    );
  } else if (props.user) {
    header = (
      <h4>
        {t("Welcome")}
        &nbsp;{props.user}
      </h4>
    );
  }

  return (
    <div className={Styles.teamHeader}>
      <div className={cls(Styles.avatar, Styles.form_header_avatar)}>
        {header}
      </div>
      <div className={Styles.message}>{body}</div>
    </div>
  );
};

const InvalidInvite = () => {
  const { t } = useTranslation();

  return (
    <div className={Styles.invalidInvite}>
      <h1 className="text-danger">{t("Invitation is Invalid")}</h1>

      <div className={Styles.message}>
        {t("This invitation has expired or it has already been accepted.")}
        &nbsp;
        <Trans
          i18nKey="help_email"
          components={{ a: <a href={`mailto:${config.SUPPORT_EMAIL}`} /> }}
          values={{ email: config.SUPPORT_EMAIL, text: config.SUPPORT_EMAIL }}
        />
      </div>

      <div className={Styles.signin}>
        <Link className="btn btn-link full" to="/signin">
          {t("Sign In")}
        </Link>
      </div>
    </div>
  );
};

const loadTeam = (team_token: string) => {
  return fetchGet<TeamModel>(
    `/teams/by_token/${team_token}`,
    {},
    { version: 1 }
  ).then((model: TeamModel | TeamModel[]) => model as TeamModel);
};

export const loadInvitation = (
  invitation_token: string
): Promise<InvitationModel> => {
  return fetchGet(
    `/invitations/by_token/${invitation_token}`,
    {},
    { version: 1 }
  ).then((model: InvitationModel): InvitationModel => {
    if (model != null && model.id != null) {
      return model;
    } else {
      return reject(null);
    }
  });
};
