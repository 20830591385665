import React, { useState, useEffect } from "react";
import Select, { StylesConfig } from "react-select";
import styles from "./styles.module.scss";
import {
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";
import ButtonWithTooltip from "app/components/Button/ButtonWithToolTip";
import { useTranslation } from "react-i18next";
import CalendarPicker from "../../components/OptionButtonGroup/CalendarPicker";
import { useConstants } from "./constants";

interface OpenCalendarOptionsProps {
  register: UseFormRegister<any>;
  setValue: UseFormSetValue<any>;
  watch: UseFormWatch<any>;
}

const OpenCalendarOptions: React.FC<OpenCalendarOptionsProps> = ({
  setValue,
  watch,
}) => {
  const { t } = useTranslation();
  const { list_of_months, list_of_times } = useConstants();

  const watchCalendarType = watch("open_calendar_type", "Rolling");
  const watchCalendarValue = watch("open_calendar_value", { label: t("count_week", { count: 6 }), value: t("count_week", { count: 6 }) });
  const watchFromDate = watch("open_calendar_from_date", null);
  const watchToDate = watch("open_calendar_to_date", null);

  const [activeButton, setActiveButton] = useState<string>(watchCalendarType);
  const [calendarOptions, setCalendarOptions] = useState<
    Array<{ label: string; value: string }>
  >(list_of_times.map((time) => ({ label: time, value: time })));
  const [customCalendar, setCustomCalendar] = useState<boolean>(
    watchCalendarType === "Custom",
  );
  const [startDate, setStartDate] = useState<Date | null>(
    watchFromDate ? new Date(watchFromDate) : null,
  );
  const [endDate, setEndDate] = useState<Date | null>(
    watchToDate ? new Date(watchToDate) : null,
  );


  useEffect(() => {
    setActiveButton(watchCalendarType);
    setCalendarOptions(getCalendarOptions(watchCalendarType));
    setCustomCalendar(watchCalendarType === "Custom");
  }, [watchCalendarType]);

  useEffect(() => {
    if (watchFromDate && watchToDate) {
      setStartDate(new Date(watchFromDate));
      setEndDate(new Date(watchToDate));
    }
  }, [watchFromDate, watchToDate]);

  useEffect(() => {
    if (customCalendar) {
      const today = new Date();
      const twoWeeksFromToday = new Date(today);
      twoWeeksFromToday.setMonth(today.getMonth() + 2);
      setStartDate(today);
      setEndDate(twoWeeksFromToday);
      setValue("open_calendar_from_date", today);
      setValue("open_calendar_to_date", twoWeeksFromToday);
    }
  }, [customCalendar, setValue]);

  const handleButtonClick = (event, buttonText: string) => {
    event.preventDefault();
    setActiveButton(buttonText);
    setValue("open_calendar_type", buttonText);

    let defaultValue;
    const options = getCalendarOptions(buttonText);

    if (buttonText === "Rolling" && watchCalendarType !== 'Rolling') {
      defaultValue = { label: t("count_week", { count: 6 }), value: t("count_week", { count: 6 }) };
      setCustomCalendar(false);
    } else if (buttonText === "Static" && watchCalendarType !== 'Static') {
      defaultValue = { label: t("count_month", { count: 2 }), value: t("count_month", { count: 2 }) };
      setCustomCalendar(false);
    } else if (buttonText === "Custom") {
      setCustomCalendar(true);
    }

    setCalendarOptions(options);

    if (defaultValue) {
      setValue("open_calendar_value", defaultValue.value);
    }
  };

  const getCalendarOptions = (buttonText: string) => {
    switch (buttonText) {
      case "Rolling":
        return list_of_times.map((time) => ({ label: time, value: time }));
      case "Static":
        return list_of_months.map((month) => ({ label: month, value: month }));
      case "Custom":
        return [];
      default:
        return [];
    }
  };

  const handleSelectChange = (selectedOption: any) => {
    setValue("open_calendar_value", selectedOption.value);
  };

  const customStyles: StylesConfig = {
    control: (base: any) => ({
      ...base,
      background: "white",
      border: "1px solid #ccc",
    }),
    valueContainer: (base: any) => ({
      ...base,
      height: "48px",
      display: "flex",
      alignItems: "center",
    }),
    multiValue: (styles: any) => ({
      ...styles,
      backgroundColor: "#27a4ff",
      padding: "4px",
      borderRadius: "4px",
    }),
    multiValueLabel: (styles: any) => ({
      ...styles,
      color: "white",
      fontSize: "16px",
    }),
    multiValueRemove: (styles: any) => ({
      ...styles,
      color: "white",
      ":hover": {
        color: "white",
      },
    }),
    singleValue: (provided: any) => ({
      ...provided,
      color: "black",
    }),
    container: (provided: any) => ({
      ...provided,
      color: "#8C8C8C",
    }),
    placeholder: (defaultStyles: any) => ({
      ...defaultStyles,
      color: "#8c8c8c",
    }),
  };

  const IndicatorSeparator = (): JSX.Element | null => null;

  return (
    <div style={{ marginTop: "20px" }}>
      <div style={{ marginBottom: "10px" }}>
        <label htmlFor="open_calendar_type" className={styles.labelStyle}>
          {t("Open Calendar")}
        </label>
      </div>
      <div className={styles.custom_text}>
        {t("How far in advance do you allow athletes to book sessions?")}
      </div>
      <div className={styles.rowContainer}>
        <div className={styles.buttonContainer}>
          <ButtonWithTooltip
            buttonText={t("Rolling")}
            tooltipText={t(
              "Calendar is always open for the selected period. Every day a new day becomes available for Athletes to book a session.",
            )}
            onClick={(e) => handleButtonClick(e, "Rolling")}
            isActive={activeButton === "Rolling"}
            orientation={"right"}
          />
          <ButtonWithTooltip
            buttonText={t("Static")}
            tooltipText={t(
              "A new month becomes available on the 15th of the month. Make sure all unavailable time is scheduled before the new month opens.",
            )}
            onClick={(e) => handleButtonClick(e, "Static")}
            isActive={activeButton === "Static"}
            orientation={"center"}
          />
          <ButtonWithTooltip
            buttonText={t("Custom")}
            tooltipText={t(
              "Select specific days that are available for Athletes to book a session.",
            )}
            onClick={(e) => handleButtonClick(e, "Custom")}
            isActive={activeButton === "Custom"}
            orientation={"left"}
          />
        </div>
        {customCalendar && (
          <div className={styles.calendarContainer}>
            <div className={styles.calendarItem}>
              <label className={styles.calendarLabelBiggerText}>
                {t("From")}
              </label>
              <CalendarPicker
                date={startDate || new Date()}
                setDate={(date) => {
                  setStartDate(date);
                  setValue("open_calendar_from_date", date);
                }}
                minDate={new Date()}
              />
            </div>
            <div className={styles.calendarItem}>
              <label className={styles.calendarLabel}>{t("To")}</label>
              <CalendarPicker
                date={endDate || new Date()}
                setDate={(date) => {
                  setEndDate(date);
                  setValue("open_calendar_to_date", date);
                }}
                minDate={startDate || new Date()}
              />
            </div>
          </div>
        )}
        {!customCalendar && (
          <div className={styles.dropdown2}>
            <Select
              id="open_calendar_value"
              value={
                typeof watchCalendarValue === "string"
                  ? { label: watchCalendarValue, value: watchCalendarValue }
                  : watchCalendarValue
              }
              onChange={handleSelectChange}
              options={calendarOptions}
              className={styles.calendarDropdown}
              styles={customStyles}
              components={{ IndicatorSeparator }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default OpenCalendarOptions;
