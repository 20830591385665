import { useTranslation } from 'react-i18next';

export const useConstants = () => {
  const { t } = useTranslation();

  const days_of_the_week = [
    {
      value: 0,
      label: t(`daysList.long.0`),
      short: t(`daysList.short.0`),
    },
    {
      value: 1,
      label: t(`daysList.long.1`),
      short: t(`daysList.short.1`),
    },
    {
      value: 2,
      label: t(`daysList.long.2`),
      short: t(`daysList.short.2`),
    },
    {
      value: 3,
      label: t(`daysList.long.3`),
      short: t(`daysList.short.3`),
    },
    {
      value: 4,
      label: t(`daysList.long.4`),
      short: t(`daysList.short.4`),
    },
    {
      value: 5,
      label: t(`daysList.long.5`),
      short: t(`daysList.short.5`),
    },
    {
      value: 6,
      label: t(`daysList.long.6`),
      short: t(`daysList.short.6`),
    }
  ];

  const currency_list = [
    { value: 'USD', label: t('currency.usd'), currency: '$' },
    { value: 'AUD', label: t('currency.aud'), currency: '$' },
    { value: 'GBP', label: t('currency.gbp'), currency: '£' },
    { value: 'CAD', label: t('currency.cad'), currency: '$' },
    { value: 'EUR', label: t('currency.eur'), currency: '€' },
    { value: 'JPY', label: t('currency.jpy'), currency: '¥' },
    { value: 'AED', label: t('currency.aed'), currency: 'AED' },
    { value: 'MXN', label: t('currency.mxn'), currency: 'MXN' },
    { value: 'NZD', label: t('currency.nzd'), currency: '$' },
  ];

  const time_zone_list = [
    { value: "Etc/GMT+12", code: t('timezone.gmt12'), label: t('timezone.gmt12_label') },
    { value: "Pacific/Pago_Pago", code: t('timezone.pago_pago'), label: t('timezone.pago_pago_label') },
    { value: "Pacific/Midway", code: t('timezone.midway'), label: t('timezone.midway_label') },
    { value: "Pacific/Honolulu", code: t('timezone.honolulu'), label: t('timezone.honolulu_label') },
    { value: "America/Juneau", code: t('timezone.juneau'), label: t('timezone.juneau_label') },
    { value: "America/Los_Angeles", code: t('timezone.los_angeles'), label: t('timezone.los_angeles_label') },
    { value: "America/Tijuana", code: t('timezone.tijuana'), label: t('timezone.tijuana_label') },
  ];

  const pricings = [
    {
      title: t('pricing.title1'),
      duration: t('pricing.duration_30min'),
      price: 50,
      sessionType1: [t('pricing.basics'), t('pricing.full_swing')],
      sessionType2: t('pricing.full_swing'),
      location: t('pricing.location_golf_club'),
      setDefault: true,
    },
    {
      title: t('pricing.title2'),
      duration: t('pricing.duration_15min'),
      price: 100,
      sessionType1: [t('pricing.full_swing')],
      sessionType2: t('pricing.putting'),
      location: t('pricing.location_golf_academy'),
      setDefault: false,
    },
    {
      title: t('pricing.title3'),
      duration: t('pricing.duration_60min'),
      price: 150,
      sessionType1: [t('pricing.wedges')],
      sessionType2: t('pricing.bunkers'),
      location: t('pricing.location_driving_range'),
      setDefault: false,
    },
    {
      title: t('pricing.title4'),
      duration: t('pricing.duration_75min'),
      price: 75,
      sessionType1: [t('pricing.putting')],
      sessionType2: t('pricing.recover'),
      location: t('pricing.location_mini_golf_course'),
      setDefault: false,
    },
    {
      title: t('pricing.title5'),
      duration: t('pricing.duration_90min'),
      price: 200,
      sessionType1: [t('pricing.playing_lesson')],
      sessionType2: t('pricing.club_fitting'),
      location: t('pricing.location_golf_course'),
      setDefault: false,
    },
  ];

  const list_of_months = [
    t("count_month", {
      count: 1,
    }),
    t("count_month", {
      count: 2,
    }),
    t("count_month", {
      count: 3,
    }),
    t("count_month", {
      count: 4,
    }),
    t("count_month", {
      count: 5,
    }),
    t("count_month", {
      count: 6,
    }),
  ];

  const list_of_times = [
    t("count_week", {
      count: 1,
    }),
    t("count_week", {
      count: 2,
    }),
    t("count_week", {
      count: 3,
    }),
    t("count_week", {
      count: 4,
    }),
    t("count_week", {
      count: 5,
    }),
    t("count_week", {
      count: 6,
    }),
    t("count_week", {
      count: 7,
    }),
    t("count_week", {
      count: 8,
    }),
    t("count_month", {
      count: 3,
    }),
    t("count_month", {
      count: 4,
    }),
    t("count_month", {
      count: 5,
    }),
    t("count_month", {
      count: 6,
    }),
    t('unlimited'),
  ];

  return { currency_list, time_zone_list, pricings, list_of_months, list_of_times, days_of_the_week };
};
