import React from "react";
import styles from "./styles.module.scss";
import { GroupPageLayoutModel } from "./indexModel";
import GroupHeader from "app/routes/Groups/_components/GroupHeader";
import ChannelsList from "app/routes/Groups/_components/ChannelstList";
import GettingStartedProgress from "app/components/GettingStarted/GettingStartedProgress";

interface Props {
  children: React.ReactNode;
}

const GroupPageLayout = ({ children }: Props) => {
  const { selectedGroup, channels, joinableList } = GroupPageLayoutModel();

  return (
    <div className="group-container">
      <GettingStartedProgress />
      <div className={styles.container}>
        <GroupHeader
          title={selectedGroup?.name}
          sport={selectedGroup?.sport}
          avatar={selectedGroup?.logo}
        />
        <div className={styles.timeline_wrapper}>
          <div className={styles.channels_list}>
            {channels && joinableList && (
              <ChannelsList
                channels={channels}
                joinableList={joinableList}
                selectedGroup={selectedGroup}
              />
            )}
          </div>
          <div className={styles.content_container}>{children}</div>
        </div>
      </div>
    </div>
  );
};

export default GroupPageLayout;
