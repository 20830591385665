import React, { memo } from "react";
import styles from "./styles.module.scss";
import { cls } from "app/utils";
import CreateNavBtn from "./CreateNavBtn";
import edufii_logo_dark from "app/images/Logo_dark.svg";
import NotificationsDropdown from "../NotificationsDropdown";

import { useHistory } from "react-router-dom";
import SiteSearch from "../SiteSearch";

interface Props {
  sidebarExpanded: boolean;
  toggleSidebar: () => void;
}

const SiteNavbar = ({ sidebarExpanded, toggleSidebar }: Props) => {
  const history = useHistory();

  const handleLogoClick = () => {
    history.push("/");
  };

  return (
    <div className={styles.container}>
      <nav className={styles.navbar}>
        <div className={styles.mobile_only}>
          <div className={cls(styles.site_sidebar_toggle, "mobile-only")}>
            <button
              onClick={toggleSidebar}
              className={cls("burger", { ["expanded"]: !sidebarExpanded })}
            >
              <div className="burger-holder">
                <div className="bars-black burger-bars"></div>
              </div>
            </button>
          </div>
        </div>
        <div className={cls(styles.logo, "mobile-only")}>
          <img
            src={edufii_logo_dark}
            alt="CoachNow"
            onClick={handleLogoClick}
          />
        </div>
        <div className={styles.pull_right}>
          <SiteSearch className={styles.search} />
          <div className={styles.create_btn_container}>
            <CreateNavBtn />
          </div>
          <div className={styles.notifications_container}>
            <NotificationsDropdown />
          </div>
        </div>
      </nav>
    </div>
  );
};

export default memo(SiteNavbar);
