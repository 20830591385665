import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import { Settings } from 'luxon';


i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    debug: false,
    supportedLngs: ["en", "ja"],
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: "/locales/{{lng}}/translation.json",
    },
    compatibilityJSON: 'v3'
  })
  .then(() => {
    // Set Luxon locale
    Settings.defaultLocale = i18n.language;

    i18n.on('languageChanged', lng => {
      // Set Luxon locale on lang change
      Settings.defaultLocale = lng;
    });

  })
  .catch((err) => console.error("i18n initialization failed", err));


export default i18n;
