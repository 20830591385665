import Label from "../Label";

import styles from "./styles.module.scss";
import React, { useEffect, useState } from "react";
import UserSelector from "../UserSelector";
import {
  program_type_options,
  registrations_status_options,
} from "app/routes/BuildProgram/Pricing/utils";
import SelectBox from "app/components/OptionButtonGroup/SelectBox";
import TagsPicker from "../TagsPicker";
import ButtonsContainer from "../Buttons/ButtonsContainer";
import { schedulingFetchGet } from "app/utils/request/scheduling/fetch";
import { useTranslation } from "react-i18next";

const ProgramFilters = ({
  facilityId = null,
  filters,
  setFilters,
  onApply,
  onReset,
  programCategories = null,
  isStudent = false,
}) => {
  const { t } = useTranslation();
  const [tags, setTags] = useState();
  const [coaches, setCoaches] = useState();

  useEffect(() => {
    isStudent
      ? schedulingFetchGet(`/coach_profiles`).then((response) => {
          setCoaches(response);
        })
      : schedulingFetchGet(`/facilities/${facilityId}/coach_profiles`).then(
          (response) => {
            setCoaches(response);
          }
        );
  }, []);

  const handleFiltersChange = (key, value) => {
    setFilters((old) => ({ ...old, [key]: value }));
  };
  const registrationStatus = (
    <Label
      text={t("Registration Status")}
      item={(
        <SelectBox
          options={registrations_status_options}
          value={filters.registration_status}
          setValue={(value) =>
            handleFiltersChange("registration_status", value)
          }
          containerStyle={styles.no_padding}
          customControl={{
            height: "40px",
            borderRadius: "8px",
          }}
        />
  )}
    />
  );

  return (
    <div
      className={
        isStudent
          ? styles.student_filter_popup_container
          : styles.filter_popup_container
      }
    >
      <h3>{t("Filters")}</h3>
      <div className={styles.filters_container}>
        {isStudent ? (
          registrationStatus
        ) : (
          <Label
            text={t("Program Category")}
            item={(
              <SelectBox
                options={programCategories}
                setValue={(value) => handleFiltersChange("category", value)}
                value={filters.category}
                containerStyle={styles.no_padding}
                customControl={{
                  height: "40px",
                  borderRadius: "8px",
                }}
              />
            )}
          />
        )}
        <Label
          text={t("Attendance")}
          item={(
            <ButtonsContainer
              customStyle={styles.pricing_item}
              items={program_type_options}
              openCalendarSelected={filters?.attendance}
              setOpenCalendarSelected={(value) =>
                handleFiltersChange("attendance", value)
              }
            />
          )}
          containerStyle={styles.label_container}
        />
        <Label
          text={t("Coach")}
          item={(
            <UserSelector
              singleValue
              value={
                filters.coach
                  ? {
                      label: `${filters.coach.firstName}  ${filters.coach.lastName}`,
                      value: filters.coach.id,
                    }
                  : null
              }
              values={coaches}
              selectedUsers={filters.coach}
              setValues={(value) => handleFiltersChange("coach", value)}
              hasArrow
              placeholder={t("Select")}
            />
          )}
        />
        {!isStudent && registrationStatus}
        <Label
          text={t("Tags")}
          item={(
            <TagsPicker
              selectedTags={filters.tags}
              placeholder={t("Select")}
              tags={tags}
              handleChange={(value) => handleFiltersChange("tags", value)}
              setTags={setTags}
              isSearchable={false}
            />
          )}
        />
      </div>
      <div className={styles.button_container}>
        <button className={styles.cancel_button} onClick={onReset}>
          {t("Reset")}
        </button>
        <button
          onClick={onApply}
          type="submit"
          className={`${styles.accept_button}`}
          data-cy="confirmation_accept_bttn"
        >
          {t("Apply")}
        </button>
      </div>
    </div>
  );
};

export default ProgramFilters;
