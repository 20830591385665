import styles from "./styles.module.scss";
import React, { useState } from "react";
import getCroppedImg from "./functions";
import Modal from "../Modal";
import ImageComponent from "../ImageComponent";
import Cropper from "react-easy-crop";
import { useSelector } from "react-redux";
import { RootState } from "typedefs";
import { useTranslation } from "react-i18next";
import { buildSchedulingUrl } from "app/utils/request/url";

const DefaultImage = ({
  onConfirm,
  needsCrop = false,
  showModal,
  uploadedImage = null,
  uploadedImageURL = null,
}) => {
  const { t } = useTranslation();
  const images = useSelector(
    (state: RootState): string[] => state.program.defaultImages
  );
  const user = useSelector((state: RootState) => state.user);

  const [selectedIndex, setSelectedIndex] = useState(null);
  const [confirmed, setConfirmed] = useState(uploadedImageURL ? true : false);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [img, setImg] = useState(uploadedImage);
  const [zoom, setZoom] = useState(1);

  const onCropComplete = (_croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const showCroppedImage = async () => {
    try {
      const croppedImage = await getCroppedImg(img, croppedAreaPixels, 0);
      onConfirm(croppedImage);
    } catch (e) {
      console.error(e);
    }
  };

  const onConfirmClick = async () => {
    if (uploadedImageURL || (needsCrop && confirmed)) {
      showCroppedImage();
      return null;
    }
    const jwtToken = user?.data?.jwt;
    const head = { Authorization: jwtToken };
    const url = buildSchedulingUrl(
      "/get_image",
      {
        image_url: images[selectedIndex],
      },
      2
    );

    const response = await fetch(url, {
      method: "GET",
      headers: {
        ...head,
      },
    });
    const blob = await response.blob();
    const file = new File([blob], "downloaded-image", { type: blob.type });
    setImg(file);

    if (needsCrop && !confirmed) {
      setConfirmed(true);
      return null;
    }

    onConfirm(images[selectedIndex]);
    return null;
  };

  const modalButtons = (className, text, disabled) => {
    return (
      <button
        data-testid="save-edit"
        form="form-details"
        className={disabled ? styles.disabled_button : className}
        type="submit"
        disabled={disabled}
        onClick={() => {
          text === t("Cancel") ? showModal(false) : onConfirmClick();
        }}
      >
        {text}
      </button>
    );
  };

  const generateText = () => {
    if (needsCrop) {
      return confirmed ? t("Confirm") : t("Next");
    }
    return t("Confirm");
  };

  return (
    <Modal
      showModal={showModal}
      title={confirmed ? t("Crop Image") : t("Select Image")}
      showCloseIcon={true}
      confirmButton={modalButtons(
        styles.confirm_button,
        generateText(),
        selectedIndex == null && !uploadedImageURL
      )}
      cancelButtonProp={modalButtons(styles.cancel_button, t("Cancel"), false)}
      width={"500px"}
      zIndex={3}
    >
      <div
        className={
          confirmed ? styles.cropper_container : styles.images_container
        }
      >
        <div className={styles.default_images_container}>
          {!confirmed ? (
            images.map((item, index) => {
              return (
                <div key={index} className={styles.image}>
                  <div
                    onClick={() => setSelectedIndex(index)}
                    className={`${styles.imageItem} ${
                      selectedIndex === index && styles.selectedImageItem
                    }`}
                  >
                    <ImageComponent url={item} height={150} />
                  </div>
                </div>
              );
            })
          ) : (
            <div className={styles.cropper}>
              <Cropper
                image={uploadedImageURL || images[selectedIndex]}
                crop={crop}
                zoom={zoom}
                aspect={1}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
              />
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default DefaultImage;
