import React, { useRef } from 'react';
 // @ts-ignore
import AsyncPaginate, { AsyncResult, wrapMenuList } from "react-select-async-paginate";
import { components } from 'react-select';
import { uniqBy } from 'lodash';
import { useController } from 'react-hook-form';

import Option from "./Option";
import SelectAllOption from "./SelectAllOption";
import SingleValue from "./SingleValue";
import MultiValue from "./MultiValue";
import SelectionList from "./SelectionList";

import { SelectStyles } from 'app/routes/Calendar/EventForm/constants';

type PropsDef = {
  name: string;
  control: any;
  placeholder: string;
  loadOptions: (inputValue: string, _currentOptions: [], additional: { page: number }) => Promise<AsyncResult<any, { page: number }>>;
  getOptionLabel?: (option: any) => string;
  multiSelect?: boolean;
  allowMultiSelectAll?: boolean;
  allOptionsLabel?: string;
};

const onMultiSelect = (selectedUser: any, field: any) => {
  const filteredUsers = field.value.filter((s: { id: number; }) => s.id !== selectedUser.id);

  if (filteredUsers.length === field.value.length) { // adding user
    field.onChange(uniqBy(field.value.concat([selectedUser]), 'id'));
  } else {
    field.onChange(filteredUsers);
  }
};

const defaultOptionLabel = (opt: any) => {
  return `${opt.firstName} ${opt.lastName}`;
};

const SchedulingUserSelector = React.forwardRef((props: PropsDef, _ref) => {
  const {
    name, control, placeholder, loadOptions,
    getOptionLabel = defaultOptionLabel, allOptionsLabel,
    multiSelect = false,
    allowMultiSelectAll = false,
  } = props;

  const selectRef = useRef<any>(null);

  const { field } = useController({ name, control });

  const onRemove = (userId: number) => {
    const newUsers = field.value.filter(user => {
      return user.id !== userId;
    });

    field.onChange(newUsers);
  };

  const hasUsers = field.value.length > 0;
  const showSelectionList = hasUsers && multiSelect;

  const MultiValueWithAllOptions = (props: any) => {
    const selection = props.getValue();

    if (props.options.length === selection.length && allowMultiSelectAll) {
      return <div>{allOptionsLabel}</div>;
    }

    return <MultiValue {...props} />;
  };

  const ValueComponent = multiSelect ? MultiValueWithAllOptions : SingleValue;

  const showSelectAllOption = multiSelect && allowMultiSelectAll;

  const MenuListWithAllOption = (props) => {
    return (
      <>
        <SelectAllOption
          label={allOptionsLabel}
          selectOption={() => {
            field.onChange(props.options);
            selectRef.current.onMenuClose();
          }}
        />
        {props.children}
      </>
    );
  };

  const MenuList = wrapMenuList(showSelectAllOption ? MenuListWithAllOption : components.MenuList);

  return (
    <div>
      <AsyncPaginate
        ref={selectRef}
        styles={SelectStyles}
        defaultOptions
        getOptionLabel={getOptionLabel}
        getOptionValue={opt => opt.id}
        loadingMessage={() => null}
        components={{
          MenuList,
          Option,
          SingleValue: ValueComponent,
          LoadingIndicator: () => null
        }}
        loadOptions={loadOptions}
        onChange={value =>  {
          if (multiSelect) {
            onMultiSelect(value, field);
          } else {
            field.onChange([value]);
          }
        }}
        placeholder={placeholder}
        value={field.value}
        isSearchable={multiSelect}
        debounceTimeout={300}
      />

      {showSelectionList && <SelectionList selection={field.value} onRemove={onRemove} />}
    </div>
  );
});

export default SchedulingUserSelector;
