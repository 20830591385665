import styles from "./styles.module.scss";
import React, { useCallback, useEffect, useState } from "react";
import { debounce } from "lodash";
import ProgramItem from "app/components/Programs/ProgramItem";
import ProgramFilters from "app/components/Programs/ProgramFilters";
import ProgramIcon from "../../../../public/images/group-program.svg";
import ItemListView from "app/components/ItemListView";
import { schedulingFetchGet } from "app/utils/request/scheduling/fetch";
import FilterImage from "../../../../public/images/filter.svg";
import SearchImage from "../../../../public/images/search.svg";
import PagePanel from "app/components/PagePanel";
import { Loading } from "app/components/Wrappers";
import { useTranslation } from "react-i18next";

const StudentPrograms = () => {
  const { t } = useTranslation();
  const [programs, setPrograms] = useState(null);
  const [loading, setLoading] = useState(true);
  const userType = "athlete";
  const [name, setName] = useState("");
  const initialFilters = {
    category: null,
    attendance: null,
    coach: null,
    registration_status: null,
    tags: [],
  };
  const [refetch, setRefetch] = useState(true);
  const [showFilters, setShowFilters] = useState(false);
  const [filters, setFilters] = useState(initialFilters);

  useEffect(() => {
    if (refetch) {
      setLoading(true);
      const selectedFilters = {
        name,
        attendance: filters.attendance?.label.toLowerCase(),
        coach_profile_id: filters.coach ? [filters.coach?.id] : null,
        registration_status: filters.registration_status?.value,
        tag_ids:
          filters.tags?.length > 0
            ? filters.tags.map((item) => item?.value)
            : null,
      };
      schedulingFetchGet("/programs", selectedFilters).then((response) => {
        setPrograms(response.data);
        setRefetch(false);
        setLoading(false);
      });
    }
  }, [refetch, filters, name, userType]);

  const onApplyFilters = useCallback(() => {
    setRefetch(true);
    setShowFilters(false);
  }, []);

  const debouncedResults = useCallback(
    () =>
      debounce((value) => {
        setName(value);
        onApplyFilters();
      }, 300),
    [onApplyFilters]
  );

  const debouncedFunction = debouncedResults();

  const handleInputChange = (event) => {
    debouncedFunction(event.target.value);
  };

  const [selectedItem, setSelectedItem] = useState(null);

  const renderProgramList = () => {
    if (loading) {
      return <Loading isLoading loadType="spinner" />;
    }
    return programs?.active?.length > 0 ? (
      <div className={styles.padding_16}>
        <ItemListView
          Item={ProgramItem}
          isStudent
          isActive
          objectList={programs?.active}
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
        />
      </div>
    ) : (
      renderEmpty("active")
    );
  };

  const renderEmpty = (programType) => {
    return (
      <div className={styles.empty}>
        <div>
          <img
            style={{
              width: "30px",
              height: "30px",
            }}
            src={ProgramIcon}
          />
        </div>
        <div className={styles.text_container}>
          <p className={styles.bold}>{`${programType.charAt(0).toUpperCase() + programType.slice(1)} ${t('Programs')}`}</p>
          <p>{t("no_group_programs_yet", { programType })}</p>
        </div>
      </div>
    );
  };
  return (
    <div className={styles.position}>
      <PagePanel title={t("Upcoming Programs")} />
      <div className={`${styles.empty_container} calendar-page-container`}>
        <div className={styles.container_16_px}>
          <div className={styles.space_between}>
            <div className={styles.bars_container}>
              <div className={styles.line_container}>
                <div className={styles.search_container}>
                  <input
                    className={styles.search}
                    placeholder={`${t("Search Program")}...`}
                    onChange={handleInputChange}
                  />
                  <img src={SearchImage} />
                </div>
                <div
                  className={styles.filter_button}
                  onClick={() => setShowFilters((old) => !old)}
                >
                  <img src={FilterImage} />
                </div>
              </div>
            </div>
          </div>
        </div>
        {showFilters && (
          <ProgramFilters
            isStudent
            filters={filters}
            setFilters={setFilters}
            onApply={onApplyFilters}
            onReset={() => setFilters(initialFilters)}
          />
        )}
        <div className={styles.mlr_16}>
          <div>
            <div className={styles.mt_16}>{renderProgramList()}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentPrograms;
