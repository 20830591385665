import React from "react";
import styles from "./styles.module.scss";
import { cls } from "app/utils";
import { PostingFormProps } from "./index.types";
import usePostingForm from "./indexModel";
import UserAvatar from "../UserAvatar";
import { useTranslation } from "react-i18next";
import PostingformExpanded from "../PostingFormExpanded";
import { UpgradeToPro } from "../paywalls";

const PostingForm = ({
  post,
  attachmentProps,
  isReply,
  className,
  expanded,
  profilePicture,
  userName,
  textAreaPlaceholder,
  postTextIsRequired,
  locationsIsRequired,
  scheduleIsRequired,
  canSelectMedia,
  canUploadMedia,
  hasAddMedia = true,
  canSchedule = true,
  hasSchedule = true,
  hasLocationSelect = true,
  disablePostButton,
  hasTagSelect = true,
  postButtonLabel = "Create",
  onPost,
  onCancel,
  errorMessage,
  scheduledInActive,
  isMove,
}: PostingFormProps) => {
  const { t } = useTranslation();
  const {
    postTextError,
    textAreaHandler,
    postingFormExpandHandler,
    postingFormIsExpanded,
    newPostData,
    postLocationsError,
    scheduleError,
    onTagsChange,
    onSelectMedia,
    onClearMedia,
    onScheduleChange,
    pickedSchedule,
    onSubmitFunction,
    onCancelHandler,
    newTags,
    attachment,
    paywallModalHandler,
    paywallIsOpened,
    scheduleVisible,
  } = usePostingForm({
    post,
    attachmentProps,
    onPost,
    onCancel,
    expanded,
    postTextIsRequired,
    locationsIsRequired,
    scheduleIsRequired,
    scheduledInActive,
    hasSchedule,
  });

  return (
    <>
      <div className={cls(styles.container)}>
        <div
          className={cls(styles.content_wrapper, className, {
            [styles.expanded_container]: postingFormIsExpanded,
            [styles.is_reply]: isReply,
          })}
        >
          {profilePicture && (
            <div className={styles.posting_form_avatar}>
              <div className={styles.profile_picture_side}>
                <UserAvatar
                  srcName={profilePicture}
                  userName={userName}
                  altName="avatar"
                  className={styles.avatar}
                  sizeHeight={44}
                  sizeWidth={44}
                />
              </div>
            </div>
          )}
          <div className={styles.posting_form}>
            <textarea
              placeholder={textAreaPlaceholder ?? t("Create post...")}
              className={cls(styles.textArea, {
                [styles.input_error]: postTextError,
                [styles.expanded_text_area]: postingFormIsExpanded,
              })}
              onFocus={() =>
                !postingFormIsExpanded && postingFormExpandHandler()
              }
              onChange={(e) => textAreaHandler(e.target.value)}
              value={newPostData?.details ?? ""}
            />
            {postTextIsRequired && postTextError && (
              <p className={styles.error_msg}>
                {t("Wouldn't you like to say something?")}
              </p>
            )}
            <div
              className={cls(styles.inner_content_wrapper, {
                [styles.expanded]: postingFormIsExpanded,
              })}
            >
              {postingFormIsExpanded && (
                <PostingformExpanded
                  paywallModalPro={paywallModalHandler}
                  canSelectMedia={canSelectMedia}
                  canUploadMedia={canUploadMedia}
                  hasAddMedia={hasAddMedia}
                  canSchedule={canSchedule}
                  hasSchedule={scheduleVisible}
                  hasLocationSelect={hasLocationSelect}
                  disablePostButton={disablePostButton}
                  hasTagSelect={hasTagSelect}
                  postButtonLabel={postButtonLabel}
                  errorMessage={errorMessage}
                  onTagsChange={onTagsChange}
                  onScheduleChange={onScheduleChange}
                  attachment={attachment}
                  onClearMedia={onClearMedia}
                  onSelectMedia={onSelectMedia}
                  scheduledInActive={scheduledInActive}
                  pickedSchedule={pickedSchedule}
                  onCancelHandler={onCancelHandler}
                  onSubmitFunction={onSubmitFunction}
                  locationsIsRequired={locationsIsRequired}
                  postLocationsError={postLocationsError}
                  scheduleIsRequired={scheduleIsRequired}
                  scheduleError={scheduleError}
                  newTags={newTags as any}
                  isMove={isMove}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <UpgradeToPro show={paywallIsOpened} onClose={paywallModalHandler} />
    </>
  );
};

export default PostingForm;
