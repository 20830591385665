import Pencil from "app/images/edit-icon.svg";
import Bin from "app/images/red-trash.svg";
import Clone from "app/images/clone.svg";
import Eye from "app/images/eye.svg";
import styles from "./styles.module.scss";
import React from "react";
import { useTranslation } from "react-i18next";

const ProgramActionMenu = ({
  id,
  handleClone,
  dropdownRef,
  position,
  handleView,
  handleDelete,
  isActive,
}) => {
  const { t } = useTranslation();

  return (
    <div
      className={styles.dropdown}
      style={{ ...position }}
      ref={dropdownRef}
      data-testid="actions"
    >
      <ul>
        <li onClick={handleView} data-testid="edit">
          <div className={styles.icon_container}>
            <img src={Eye} alt="pencil" />
          </div>
          {t("View")}
        </li>
        {isActive && (
          <li onClick={() => handleClone(true)} data-testid="edit">
            <div className={styles.icon_container}>
              <img src={Pencil} alt="pencil" />
            </div>
            {t("Edit")}
          </li>
        )}

        <li onClick={() => handleClone(false)} data-testid="lessons">
          <div className={styles.icon_container}>
            <img src={Clone} alt="flag" />
          </div>
          {t("Clone")}
        </li>
        {isActive && (
          <li onClick={() => handleDelete(id)} data-testid="delete">
            <div className={styles.icon_container}>
              <img src={Bin} alt="bin" />
            </div>
            <span className={styles.red_text}>{t("Delete")}</span>
          </li>
        )}
      </ul>
    </div>
  );
};

export default ProgramActionMenu;
