import React, { useEffect, useState } from "react";
import ModalLayout from "../Layouts/ModalLayout/ModalLayout";
import PhoneInput from "react-phone-input-2";
import styles from "./styles.module.scss";
import Button from "../Button/ButtonVariant";
import checkmark from "../../../public/images/checkmark-green.png";
import { useTranslation } from "react-i18next";
import { useAccountPhoneVerificationModel } from "./indexModel";

interface Props {
  isPhoneModalOpen: boolean;
  handlePhoneModal: () => void;
}

const AccountPhoneVerificationModal = ({
  isPhoneModalOpen,
  handlePhoneModal,
}: Props) => {
  const { t } = useTranslation();

  const {
    countryCodeErr,
    lowercaseCountryCodes,
    setCode,
    sendVerificationHandler,
    handleSendVerificationCode,
    handleTimerEnd,
    confirmCodeHandler,
    showResend,
    step,
    closeModal,
    error,
    self,
    phoneInfo,
  } = useAccountPhoneVerificationModel({ handlePhoneModal });

  return (
    <ModalLayout isOpen={isPhoneModalOpen} onClose={closeModal}>
      <div className={styles.modal_container}>
        <div className={styles.step_wrapper}>
          <span className={styles.header_wrapper}>
            <h4>{t("Verify Phone Number")}</h4>
            <i className="dismiss ico ico-x" onClick={closeModal} />
          </span>
          {step === 1 && (
            <>
              {!countryCodeErr && lowercaseCountryCodes && (
                <>
                  <div className={styles.phone_number_input}>
                    <label htmlFor="phone_number">{t("Phone Number")}</label>
                    <PhoneInput
                      countryCodeEditable={false}
                      onChange={sendVerificationHandler}
                      inputStyle={{ width: "100%", height: "42px" }}
                      // inputProps={{ autoFocus: true }}
                      country={
                        self?.phoneCountry
                          ? self?.phoneCountry.toLowerCase()
                          : "us"
                      }
                      onlyCountries={lowercaseCountryCodes}
                    />
                    {error.sendingCode && (
                      <div className="helper-text error">
                        {t("Please provide a valid phone number")}
                      </div>
                    )}
                  </div>
                  <Button
                    title={t("Send Confirmation Code")}
                    buttonType="primary"
                    buttonStyles={styles.confirmation_btn}
                    onClickFunc={handleSendVerificationCode}
                  />
                </>
              )}
            </>
          )}
          {step === 2 && (
            <>
              <div className={styles.title_wrapper}>
                <p>{t("We sent confirmation code to")}</p>
                <strong>{phoneInfo?.phoneNormalized || null}</strong>
              </div>
              <div className={styles.phone_number_input}>
                <label htmlFor="confirmation_code">
                  {t("Confirmation Code")}
                </label>
                <input
                  type="text"
                  name="confirmation_code"
                  id="confirmation_code"
                  onChange={(e) => setCode(e.target.value)}
                />
                {(error.sendingCode || error.codeIsNotCorrect) && (
                  <div className="helper-text error">
                    {t("Verification Code is invalid or expired.")}
                  </div>
                )}
                {error.somethingWentWrong && (
                  <div className="helper-text error">
                    {t("Something went wrong.")}
                  </div>
                )}

                {error.alreadyTaken && (
                  <div className="helper-text error">
                    {t("This phone number is already taken")}
                  </div>
                )}
              </div>
              <p className={styles.resend_text}>
                {t("Didn't get the code? Retry in")}{" "}
                {!showResend && <TimerComponent onTimerEnd={handleTimerEnd} />}
                {showResend && (
                  <span
                    className={styles.resend_btn}
                    onClick={handleSendVerificationCode}
                  >
                    {t("Resend")}
                  </span>
                )}
              </p>
              <Button
                title={t("Confirm Code")}
                buttonType="primary"
                buttonStyles={styles.confirmation_btn}
                onClickFunc={confirmCodeHandler}
              />
            </>
          )}
          {step === 3 && (
            <>
              <img
                src={checkmark}
                alt="success"
                className={styles.success_image}
              />
              <p className={styles.title_wrapper}>
                {t("Phone Number was successfully verified!")}
              </p>
              <Button
                title={t("Done")}
                buttonType="secondary-outline"
                buttonStyles={styles.confirmation_btn}
                onClickFunc={closeModal}
              />
            </>
          )}
        </div>
      </div>
    </ModalLayout>
  );
};

export default AccountPhoneVerificationModal;

const TimerComponent = ({ onTimerEnd }: { onTimerEnd: () => void }) => {
  const { t } = useTranslation();
  const [seconds, setSeconds] = useState(60);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setSeconds((prevSeconds) => {
        if (prevSeconds === 1) {
          clearInterval(intervalId);
          onTimerEnd();
          return 0;
        }
        return prevSeconds - 1;
      });
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  return <span>{t("count_second", { count: seconds })}</span>;
};
