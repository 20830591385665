import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import ModalLayout from "../Layouts/ModalLayout/ModalLayout";
import styles from "./styles.module.scss";
import closeIcon from "../../../public/images/close-icon.svg";
import { cls } from "app/utils";
import i18n from "app/i18n";
import moment from "moment";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  location?: string;
  redirectUrl: string;
  scheduledAt: Date;
}

const CreatedScheduledPostModal = ({
  isOpen,
  onClose,
  location,
  redirectUrl,
  scheduledAt,
}: IProps): React.ReactElement => {
  const history = useHistory();
  const { t } = useTranslation();
  console.log("scheduledAt: ", scheduledAt);

  const value = moment(scheduledAt);

  value.locale(i18n.language);

  const date = value.format("MMMM D, YYYY");
  const time = value.format("hh:mm a");

  const onUrlClick = (): void => {
    history.push(redirectUrl);
  };

  return (
    <ModalLayout isOpen={isOpen} onClose={onClose}>
      <div className={styles.container}>
        <div className={styles.title_container}>
          <h1>{t("Post Created")}</h1>
          <img
            src={closeIcon}
            alt="close"
            className={styles.close_icon}
            onClick={onClose}
          />
        </div>
        <div className={styles.form_wrapper}>
          <div className={styles.modal_header}>
            <div className={styles.body_icon_wrapper}>
              <i className="ico ico-time" />
            </div>
            <h3 className={styles.post_created_text}>{t("Post Created")}</h3>
          </div>
          <Trans
            className={styles.form_body}
            i18nKey="scheduled_post_modal_body"
            components={{ br: <br /> }}
            values={{ location }}
          />
          <div className={styles.scheduled_time_container}>
            <Trans
              className={styles.form_body}
              i18nKey="scheduled_at_date"
              components={{ strong: <strong /> }}
              values={{ date, time }}
            />
          </div>
        </div>
        <div className={styles.buttons}>
          <button
            className={cls("btn btn-outline-secondary", styles.button)}
            onClick={onUrlClick}
          >
            {t("Go to schedule")}
          </button>
          <button
            className={cls("btn btn-primary", styles.button)}
            onClick={onClose}
          >
            {t("Ok")}
          </button>
        </div>
      </div>
    </ModalLayout>
  );
};

export default CreatedScheduledPostModal;
