/**
 * @module Components.StripeForm
 *
 */
import React from 'react';
import StartFreeTrialFormElementContainer, { PropsDef } from './StartFreeTrialFormElementContainer';
import { CardNumberElement } from 'react-stripe-elements';
import { useTranslation } from 'react-i18next';

const CardNumber = (props: PropsDef) => {
  const { t } = useTranslation();
  return (
    <StartFreeTrialFormElementContainer
      elementType={CardNumberElement}
      placeholder={{
        id: "placeholder.form.card-number",
        defaultMessage: t("Card Number"),
      }}
      {...props}
    />
  );
};

export default CardNumber;
