import React from "react";
import styles from "./styles.module.scss";
import GettingStartedProgress from "app/components/GettingStarted/GettingStartedProgress";
import { FeedTimelineModel } from "./indexModel";
import TimelinePostsContainer from "app/components/TimelinePostsContainer";
import FeedPostingForm from "../FeedPostingForm";
import PostReportModal from "app/components/Post/InnerPost/PostReportModal";
import Filter from "app/components/Filter";
import CreatedScheduledPostModal from "app/components/CreatedScheduledPostModal";

const FeedTimeline = () => {
  const {
    data,
    onPost,
    pending,
    reportModal,
    reportModalClose,
    onReportHandler,
    onPostReportClick,
    onPostDelete,
    onPostUpdate,
    sentryRef,
    filterHandler,
    onClearFilters,
    newScehduleModalOpen,
    handleNewSchedulePost,
    scheduledAt,
  } = FeedTimelineModel();

  return (
    <>
      <GettingStartedProgress />
      <div className={styles.content_container}>
        <FeedPostingForm onPost={onPost} />
        <div className={styles.dropdown_container}>
          <hr />
          <Filter
            filterClickHandler={filterHandler}
            onFilterClear={onClearFilters}
          />
        </div>
        <TimelinePostsContainer
          displayPostLocations
          hidePinBadge
          onPostDelete={onPostDelete}
          onPostUpdate={onPostUpdate}
          onReport={onPostReportClick}
          containerRef={sentryRef}
          posts={data}
          pending={pending}
        />
        {reportModal.isOpen && (
          <PostReportModal
            isModalOpen={reportModal.isOpen}
            modalHandler={reportModalClose}
            reportHandler={onReportHandler}
          />
        )}
        {newScehduleModalOpen && (
          <CreatedScheduledPostModal
            isOpen={newScehduleModalOpen}
            onClose={handleNewSchedulePost}
            redirectUrl={"/schedule"}
            scheduledAt={scheduledAt}
          />
        )}
      </div>
    </>
  );
};

export default FeedTimeline;
