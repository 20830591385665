import React, { useState, useEffect, useRef, MouseEvent } from "react";
import styles from "./styles.module.scss";
import EditPricing from "./Modals/AddPricing/EditPricing";
import RemovePricing from "./Modals/RemovePricing";
import DeleteMenu from "../../../../public/images/delete-icon.svg";
import EditMenu from "../../../../public/images/edit-menu-icon.svg";
import ActivateMenu from "../../../../public/images/toggle.svg";
import ToolTip from "../ToolTip";
import { cls } from "app/utils";
import { useTranslation } from 'react-i18next';
interface PricingItem {
  id: string | number;
  rate: number | string;
  defaultRate: boolean;
  location: string;
  duration: string;
  title: string;
  _destroy?: boolean;
  active: boolean;
}

interface PricingViewProps {
  list: PricingItem[];
  onEdit: (item: PricingItem) => void;
  onRemove: (item: PricingItem) => void;
  titles: string[];
  coachCurrencySymbol: string;
  onChangeStatus: (item: PricingItem) => void;
  saveNewStatus: (item: PricingItem) => void;
}

const PricingView: React.FC<PricingViewProps> = ({
  list,
  onEdit,
  onRemove,
  titles,
  coachCurrencySymbol,
  onChangeStatus,
  saveNewStatus
}) => {
  const { t } = useTranslation();
  const [menuVisible, setMenuVisible] = useState<number | null>(null);
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [removeModalOpen, setRemoveModalOpen] = useState<boolean>(false);
  const [currentItem, setCurrentItem] = useState<PricingItem | null>(null);
  const actionMenuRef = useRef<HTMLDivElement>(null);
  const [shouldShowTooltip, setShouldShowTooltip] = useState({});

  const handleArrowClick = (index: number) => {
    setMenuVisible(menuVisible === index ? null : index);
  };

  const handleEdit = (item: PricingItem) => {
    setCurrentItem(item);
    setEditModalOpen(true);
    setMenuVisible(null);
  };

  const handleRemove = (item: PricingItem) => {
    setCurrentItem(item);
    setRemoveModalOpen(true);
    setMenuVisible(null);
  };

  const handleChangeStatus = (item: PricingItem) => {
    if (item.defaultRate === true && item.active === true) {
      const count = list.filter(rate => rate.active === true).length;

      if (count === 1) {
        onChangeStatus(item);
      } else {
        onChangeStatus(null);
      }
    } else {
      item.active = !item.active;
      saveNewStatus(item);
    }

    setMenuVisible(null);
  };

  const handleConfirmRemove = () => {
    if (currentItem) {
      onRemove(currentItem);
      setRemoveModalOpen(false);
    }
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      actionMenuRef.current &&
      !actionMenuRef.current.contains(event.target as Node)
    ) {
      setMenuVisible(null);
    }
  };

  useEffect(() => {
    document.addEventListener(
      "mousedown",
      handleClickOutside as unknown as EventListener
    );
    return () => {
      document.removeEventListener(
        "mousedown",
        handleClickOutside as unknown as EventListener
      );
    };
  }, []);

  const checkTextWidth = () => {
    const visibility = {};
    list.forEach((_, index) => {
      const element = document.getElementById(`title-${index}`);
      if (element) {
        visibility[index] = element.scrollWidth > 200;
      }
    });
    setShouldShowTooltip(visibility);
  };

  useEffect(() => {
    checkTextWidth();
  }, [list]);

  return (
    <>
      {list &&
        list.map(
          (item, index) =>
            !item._destroy && (
              <div key={index} className={styles.rowPricingView}>
                <div className={styles.cell}>
                  <div
                    className={`${styles.textWithTooltip} ${
                      item.defaultRate ? styles.defaultRateContainer : ""
                    }`}
                  >
                    <div className={styles.titleRow}>
                      <div
                        id={`title-${index}`}
                        className={styles.truncatedText}
                      >
                        {item.title}
                      </div>
                      {shouldShowTooltip[index] && (
                        <div className={styles.toolTipPosition}>
                        <ToolTip selected={true} text={item.title} orientation={'right'} />
                        </div>
                      )}
                    </div>
                    {item.defaultRate && item.active && (
                      <div className={styles.defaultText}>({t("default")})</div>
                    )}
                  </div>
                </div>
                <div className={styles.cell}>{item.duration}</div>
                <div className={styles.cell}>
                  {item.rate !== 0 ? `${coachCurrencySymbol}${item.rate}` : ""}
                </div>
                <div className={styles.cell}>{item.location}</div>
                <div className={styles.cell}>
                  <span className={item.active ? styles.activeButtonDesign : styles.inactiveButtonDesign}>{item.active ? 'Active' : 'Inactive'}</span>
                </div>
                <div className={`${styles.cell} ${styles.iconContainer}`}>
                  <div className={cls("bullets", styles.cursorPointer)} onClick={() => handleArrowClick(index)}/>
                  {menuVisible === index && (
                    <div ref={actionMenuRef} className={styles.actionMenu}>
                      <button
                        onClick={() => handleChangeStatus(item)}
                        className={styles.editButton}
                      >
                        <img src={ActivateMenu} alt="activateMenu" />
                        <div style={{ marginLeft: "10px" }}>{item.active ? t("Deactivate") : t("Activate")}</div>
                      </button>
                      <button
                        onClick={() => handleEdit(item)}
                        className={styles.editButton}
                      >
                        <img src={EditMenu} alt="editMenu" />
                        <div style={{ marginLeft: "10px" }}>{t("Edit")}</div>
                      </button>
                      <button
                        onClick={() => handleRemove(item)}
                        className={styles.removeButton}
                      >
                        <img src={DeleteMenu} alt="deleteMenu" className={styles.deleteImage} />
                        <div style={{ marginLeft: "10px" }}>{t("Delete")}</div>
                      </button>
                    </div>
                  )}
                </div>
              </div>
            )
        )}
      {editModalOpen && currentItem && (
        <EditPricing
          isOpen={editModalOpen}
          onClose={() => setEditModalOpen(false)}
          item={currentItem}
          onEdit={onEdit}
          titles={titles}
          coachCurrencySymbol={coachCurrencySymbol}
        />
      )}
      {removeModalOpen && currentItem && (
        <RemovePricing
          isOpen={removeModalOpen}
          onClose={() => setRemoveModalOpen(false)}
          onDelete={handleConfirmRemove}
          title={currentItem.title}
        />
      )}
    </>
  );
};

export default PricingView;
