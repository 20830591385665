import React from "react";
import { DateTime } from "luxon";

import styles from "./DateTime.module.scss";

type PropsDef = {
  date: DateTime;
};

const ShowDate = ({ date }: PropsDef) => {
  const dateStr = date
    .toLocaleString({ month: "short", day: "numeric" })
    .toUpperCase();
  const dayNameStr = date.toLocaleString({ weekday: "long" }).toUpperCase();

  return (
    <div className={styles.show_date_container}>
      <div className={styles.month_day}>{dateStr}</div>
      <div className={styles.day_of_the_week}>{dayNameStr}</div>
    </div>
  );
};

export default ShowDate;
