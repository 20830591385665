import DropdownButton from "app/components/DropdownButton";
import { SpacesLimit } from "app/components/paywalls";
import { cls } from "app/utils";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { RootState } from "typedefs";
import styles from "./styles.module.scss";

type dropdownList = {
  label: string;
  icon: string | null;
  onClick: () => void;
};

const CreateSpaceXl = (): React.ReactElement => {
  const { t } = useTranslation();
  const history = useHistory();
  const { self } = useSelector((state: RootState) => state.user);
  const [showUpgrade, setUpgradeState] = useState(false);

  const canCreateSpace = self?.planPermissions?.includes("import_spaces");
  const canCreateGroup = self?.planPermissions?.includes("create_teams");

  const handleUpgrade = (): void => {
    setUpgradeState((prev: boolean): boolean => !prev);
  };

  const CreateNewButton = useCallback((): React.ReactElement => {
    return (
      <div className={styles.new_button_container}>
        <div className={styles.add_icon_container}>
          <i className="ico ico-add" />
        </div>
        <p>{t("New")}</p>
      </div>
    );
  }, [t]);

  const onNewSpaceClick = (): void => {
    if (!canCreateSpace) {
      return handleUpgrade();
    }
    history.push("/spaces/new");
  };

  const onNewGroupClick = (): void => {
    if (!canCreateGroup) {
      return handleUpgrade();
    }
    history.push("/groups?create-group=true");
  };

  const dropdownList: dropdownList[] = [
    {
      label: t("New Space"),
      icon: "ico ico-tspace",
      onClick: onNewSpaceClick,
    },
    {
      label: t("New Group"),
      icon: "ico ico-team",
      onClick: onNewGroupClick,
    },
  ];

  const DropdownContent = useCallback((): React.ReactElement => {
    return (
      <ul className={styles.dropdown_content}>
        {dropdownList.map((item: dropdownList): React.ReactElement => {
          return (
            <button
              key={item.label}
              className={styles.dropdown_item}
              onClick={item.onClick}
            >
              <i className={cls(item.icon, styles.dropdown_item_icon)} />
              {item.label}
            </button>
          );
        })}
      </ul>
    );
  }, [dropdownList]);

  return (
    <div className={styles.container}>
      <DropdownButton
        dropdownWrapperClass={styles.dropdown_content_container}
        btnContent={<CreateNewButton />}
        btnClassName={styles.btnClassName}
      >
        <DropdownContent />
      </DropdownButton>
      <SpacesLimit show={showUpgrade} onClose={handleUpgrade} />
    </div>
  );
};
export default CreateSpaceXl;
