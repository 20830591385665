import I18n from "app/i18n";
const t = I18n.t;

export const hoursWithLables = [
  { value: "00:00", label: "12:00 AM" },
  { value: "00:15", label: "12:15 AM" },
  { value: "00:30", label: "12:30 AM" },
  { value: "00:45", label: "12:45 AM" },
  { value: "01:00", label: "1:00 AM" },
  { value: "01:15", label: "1:15 AM" },
  { value: "01:30", label: "1:30 AM" },
  { value: "01:45", label: "1:45 AM" },
  { value: "02:00", label: "2:00 AM" },
  { value: "02:15", label: "2:15 AM" },
  { value: "02:30", label: "2:30 AM" },
  { value: "02:45", label: "2:45 AM" },
  { value: "03:00", label: "3:00 AM" },
  { value: "03:15", label: "3:15 AM" },
  { value: "03:30", label: "3:30 AM" },
  { value: "03:45", label: "3:45 AM" },
  { value: "04:00", label: "4:00 AM" },
  { value: "04:15", label: "4:15 AM" },
  { value: "04:30", label: "4:30 AM" },
  { value: "04:45", label: "4:45 AM" },
  { value: "05:00", label: "5:00 AM" },
  { value: "05:15", label: "5:15 AM" },
  { value: "05:30", label: "5:30 AM" },
  { value: "05:45", label: "5:45 AM" },
  { value: "06:00", label: "6:00 AM" },
  { value: "06:15", label: "6:15 AM" },
  { value: "06:30", label: "6:30 AM" },
  { value: "06:45", label: "6:45 AM" },
  { value: "07:00", label: "7:00 AM" },
  { value: "07:15", label: "7:15 AM" },
  { value: "07:30", label: "7:30 AM" },
  { value: "07:45", label: "7:45 AM" },
  { value: "08:00", label: "8:00 AM" },
  { value: "08:15", label: "8:15 AM" },
  { value: "08:30", label: "8:30 AM" },
  { value: "08:45", label: "8:45 AM" },
  { value: "09:00", label: "9:00 AM" },
  { value: "09:15", label: "9:15 AM" },
  { value: "09:30", label: "9:30 AM" },
  { value: "09:45", label: "9:45 AM" },
  { value: "10:00", label: "10:00 AM" },
  { value: "10:15", label: "10:15 AM" },
  { value: "10:30", label: "10:30 AM" },
  { value: "10:45", label: "10:45 AM" },
  { value: "11:00", label: "11:00 AM" },
  { value: "11:15", label: "11:15 AM" },
  { value: "11:30", label: "11:30 AM" },
  { value: "11:45", label: "11:45 AM" },
  { value: "12:00", label: "12:00 PM" },
  { value: "12:15", label: "12:15 PM" },
  { value: "12:30", label: "12:30 PM" },
  { value: "12:45", label: "12:45 PM" },
  { value: "13:00", label: "1:00 PM" },
  { value: "13:15", label: "1:15 PM" },
  { value: "13:30", label: "1:30 PM" },
  { value: "13:45", label: "1:45 PM" },
  { value: "14:00", label: "2:00 PM" },
  { value: "14:15", label: "2:15 PM" },
  { value: "14:30", label: "2:30 PM" },
  { value: "14:45", label: "2:45 PM" },
  { value: "15:00", label: "3:00 PM" },
  { value: "15:15", label: "3:15 PM" },
  { value: "15:30", label: "3:30 PM" },
  { value: "15:45", label: "3:45 PM" },
  { value: "16:00", label: "4:00 PM" },
  { value: "16:15", label: "4:15 PM" },
  { value: "16:30", label: "4:30 PM" },
  { value: "16:45", label: "4:45 PM" },
  { value: "17:00", label: "5:00 PM" },
  { value: "17:15", label: "5:15 PM" },
  { value: "17:30", label: "5:30 PM" },
  { value: "17:45", label: "5:45 PM" },
  { value: "18:00", label: "6:00 PM" },
  { value: "18:15", label: "6:15 PM" },
  { value: "18:30", label: "6:30 PM" },
  { value: "18:45", label: "6:45 PM" },
  { value: "19:00", label: "7:00 PM" },
  { value: "19:15", label: "7:15 PM" },
  { value: "19:30", label: "7:30 PM" },
  { value: "19:45", label: "7:45 PM" },
  { value: "20:00", label: "8:00 PM" },
  { value: "20:15", label: "8:15 PM" },
  { value: "20:30", label: "8:30 PM" },
  { value: "20:45", label: "8:45 PM" },
  { value: "21:00", label: "9:00 PM" },
  { value: "21:15", label: "9:15 PM" },
  { value: "21:30", label: "9:30 PM" },
  { value: "21:45", label: "9:45 PM" },
  { value: "22:00", label: "10:00 PM" },
  { value: "22:15", label: "10:15 PM" },
  { value: "22:30", label: "10:30 PM" },
  { value: "22:45", label: "10:45 PM" },
  { value: "23:00", label: "11:00 PM" },
  { value: "23:15", label: "11:15 PM" },
  { value: "23:30", label: "11:30 PM" },
  { value: "23:45", label: "11:45 PM" },
];

export const hoursWithLablesFromSpecificValue = (selectedValue, type) => {
  if (!selectedValue) {
    return hoursWithLables;
  }
  const selectedIndex = hoursWithLables.findIndex(
    (option) => option.value === selectedValue.value,
  );
  if (selectedIndex !== -1) {
    return type === "start"
      ? hoursWithLables.slice(selectedIndex + 1)
      : hoursWithLables.slice(0, selectedIndex);
  } else {
    return hoursWithLables;
  }
};

export const useSettingsValues = () => {
  const days_of_the_week = [
    { value: 0, label: t("Sunday"), short: t("SUN") },
    { value: 1, label: t("Monday"), short: t("MON") },
    { value: 2, label: t("Tuesday"), short: t("TUE") },
    { value: 3, label: t("Wednesday"), short: t("WED") },
    { value: 4, label: t("Thursday"), short: t("THU") },
    { value: 5, label: t("Friday"), short: t("FRI") },
    { value: 6, label: t("Saturday"), short: t("SAT") },
  ];

  const rolling_option_list = [
    { value: 1, label: t("count_week", { count: 1 }) },
    { value: 2, label: t("count_week", { count: 2 }) },
    { value: 3, label: t("count_week", { count: 3 }) },
    { value: 4, label: t("count_week", { count: 4 }) },
    { value: 5, label: t("count_week", { count: 5 }) },
    { value: 6, label: t("count_week", { count: 6 }) },
    { value: 7, label: t("count_week", { count: 7 }) },
    { value: 8, label: t("count_week", { count: 8 }) },
    { value: 13, label: t("count_month", { count: 3 }) },
    { value: 17, label: t("count_month", { count: 4 }) },
    { value: 21, label: t("count_month", { count: 5 }) },
    { value: 26, label: t("count_month", { count: 6 }) },
    { value: 52, label: t("Unlimited") },
  ];

  const static_option_list = [
    { value: 1, label: t("count_month", { count: 1 }) },
    { value: 2, label: t("count_month", { count: 2 }) },
    { value: 3, label: t("count_month", { count: 3 }) },
    { value: 4, label: t("count_month", { count: 4 }) },
    { value: 5, label: t("count_month", { count: 5 }) },
    { value: 6, label: t("count_month", { count: 6 }) },
  ];

  const reminders_list = () => {
    const reminders = [
      { value: 1, label: t("count_hour", { count: 1 }) },
      { value: 3, label: t("count_hour", { count: 3 }) },
      { value: 6, label: t("count_hour", { count: 6 }) },
      { value: 12, label: t("count_hour", { count: 12 }) },
      { value: 24, label: t("count_day", { count: 1 }) },
    ];

    for (let i = 2; i < 30; i++) {
      reminders.push({
        value: i * 24,
        label: t("count_day", { count: i }),
      });
    }

    return reminders;
  };

  const cut_off_list = [
    { value: -1, label: t("Unlimited") },
    ...reminders_list(),
  ];

  const open_calendar_list = [
    {
      value: 0,
      label: t("Rolling"),
      english_label: "Rolling",
      tooltip: t(
        "Calendar is always open for the selected period.\n Every day a new day becomes available for Students to book a lesson.",
      ),
    },
    {
      value: 1,
      label: t("Static"),
      english_label: "Static",
      tooltip: t(
        "A new month becomes available on the 15th of the month. Make sure all unavailable time is scheduled before the new month opens.",
      ),
    },
    {
      value: 2,
      label: t("Custom"),
      english_label: "Custom",
      tooltip: t(
        "Select specific days that are available for Students to book a lesson.",
      ),
    },
  ];

  const currency_list = [
    { value: "USD", label: t("US Dollar (USD, $)"), currency: "$" },
    {
      value: "AUD",
      label: t("Australian Dollar (AUD, $)"),
      currency: "$",
    },
    { value: "GBP", label: t("British Pound (GBP, £)"), currency: "£" },
    { value: "CAD", label: t("Canadian Dollar (CAD, $)"), currency: "$" },
    { value: "EUR", label: t("Euro (EUR, €)"), currency: "€" },
    { value: "JPY", label: t("Japanese Yen (JPY, ¥)"), currency: "¥" },
    {
      value: "AED",
      label: t("United Arab Emirates Dirham (AED)"),
      currency: "AED",
    },
    {
      value: "MXN",
      label: t("Mexican Peso (MXN)"),
      currency: "MXN",
    },
    {
      value: "NZD",
      label: t("New Zealand Dollar (NZD, $)"),
      currency: "$",
    },
    {
      value: "TRY",
      label: t("Turkish Lira (TRY, ₺)"),
      currency: "₺",
    },
  ];

  const durations_list = [
    {
      value: 15,
      label: t("count_minutes_short_lovercase", { count: 15 }),
      rate: 0,
      default_value: "15 mins",
    },
    {
      value: 30,
      label: t("count_minutes_short_lovercase", { count: 30 }),
      rate: 0,
      default_value: "30 mins",
    },
    {
      value: 45,
      label: t("count_minutes_short_lovercase", { count: 45 }),
      rate: 0,
      default_value: "45 mins",
    },
    {
      value: 60,
      label: t("count_minutes_short_lovercase", { count: 60 }),
      rate: 0,
      default_value: "60 mins",
    },
    {
      value: 75,
      label: t("count_minutes_short_lovercase", { count: 75 }),
      rate: 0,
      default_value: "75 mins",
    },

    {
      value: 90,
      label: t("count_minutes_short_lovercase", { count: 90 }),
      rate: 0,
      default_value: "90 mins",
    },

    {
      value: 120,
      label: t("count_hour_lovercase_plural", { count: 2 }),
      rate: 0,
      default_value: "2 hours",
    },
  ];

  const convertDurations = (durations) => {
    return durations_list
      .map((item) => {
        const found = durations.find(
          (duration) => duration.value === item.value,
        );
        return {
          ...item,
          duration: found ? found.default_value : null,
          value: found ? found.rate : item.value,
          enabled: !!found,
        };
      })
      .map(({ default_value, ...rest }) => ({
        ...rest,
        duration: rest.duration || default_value,
      }));
  };

  const cut_off_time_buttons = [
    {
      value: "start_time",
      label: t("Start Time"),
      tooltip: t(
        "Select a cut-off time based on the start time of each lesson.",
      ),
    },
    {
      value: "static",
      label: t("Static"),
      tooltip: t(
        "Select a cut-off time that applies to all lessons on a given day.",
      ),
    },
  ];

  const cut_off_days = [
    { value: 0, label: t("Day Of") },
    { value: 1, label: t("Day Before") },
    {
      value: 2,
      label: t("count_day_before", { count: 2 }),
    },
    { value: 3, label: t("count_day_before", { count: 3 }) },
  ];

  return {
    days_of_the_week,
    rolling_option_list,
    static_option_list,
    reminders_list,
    cut_off_list,
    open_calendar_list,
    currency_list,
    durations_list,
    convertDurations,
    cut_off_time_buttons,
    cut_off_days,
  };
};

export const currencies = [
  "USD",
  "AUD",
  "GBP",
  "CAD",
  "EUR",
  "JPY",
  "AED",
  "MXN",
];

interface Option {
  value: number;
  label: string;
}

export const dayHours = () => {
  const dateList: Option[] = [];

  for (let hour = 0; hour < 24; hour++) {
    const ampm = hour >= 12 ? "PM" : "AM";
    const hourLabel = hour > 12 ? hour - 12 : hour;
    let label = `${hourLabel}:00 ${ampm}`;

    if (label === "0:00 AM") {
      label = "12:00 AM";
    }

    dateList.push({
      value: hour,
      label,
    });
  }

  return dateList;
};
