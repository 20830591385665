import React from "react";
import BullseyeArrow from "../../../../public/images/bullseye-arrow.svg";
import styles from "./styles.module.scss";
import { useSelector, useStoreActions } from "app/utils/hooks";
import { fetchOnboarding } from "app/actions/onboarding";
import ProgressBar from "app/components/Layouts/ProgressBar";
import { cls } from "app/utils";
import { RootState } from "typedefs";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { shallowEqual } from "react-redux";

const GettingStartedProgress = () => {
  const { t } = useTranslation();
  const { self: user } = useSelector(
    (state: RootState) => state.user,
    shallowEqual
  );
  const { onboarding } = useStoreActions({ onboarding: fetchOnboarding });

  return (
    user &&
    user.needsOnboarding &&
    user.kind === "coach" && (
      <div className={cls(styles.container)}>
        <div className={styles.progress_bar_container}>
          <div className={styles.title_wrapper}>
            <img src={BullseyeArrow} alt="bullseye" />
            <h1 className={styles.title}>{t("Getting started")}</h1>
          </div>
          <div className={styles.progress_bar_wrapper}>
            {onboarding.data && (
              <ProgressBar
                percentage={
                  onboarding.data && onboarding.data.percentsCompleted
                }
              />
            )}
          </div>
        </div>
        <Link to="/getting-started">
          <p className={styles.continue_btn}>{t("Continue")}</p>
        </Link>
      </div>
    )
  );
};

export default GettingStartedProgress;
