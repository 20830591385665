import React, { useState } from "react";
import styles from "./styles.module.scss";
import UserAvatar from "../UserAvatar";
import DropdownButton from "../DropdownButton";
import UserModel from "app/models/UserModel";
import { cls } from "app/utils";
import EditMemberModal from "app/routes/Groups/_components/EditMemberModal";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "typedefs";

interface Props {
  member: UserModel;
  onRemoveMember?: (id: string, userName: string) => void;
  memberEditModal?: boolean;
  onMemberUpdate?: (member: UserModel) => void;
}

const TeamGroupMember = ({ member, onRemoveMember, onMemberUpdate }: Props) => {
  const { selectedTeam } = useSelector(
    (state: RootState) => state.teamGroup,
    shallowEqual,
  );
  const { self } = useSelector((state: RootState) => state.user, shallowEqual);
  const [memberEditModal, setMemberEditModal] = useState(false);

  const memberModalHandler = () => {
    setMemberEditModal((prev) => !prev);
  };

  const canDelete = () => {
    return (
      member?.permissions?.includes("delete") &&
      member?.planPermissions?.includes("delete")
    );
  };

  const memberMenu = [
    {
      title: "Edit Member",
      onClick: () => {
        memberModalHandler();
      },
    },
    {
      title: "Remove From Channel",
      onClick: (id: string) => {
        onRemoveMember(id, member.name);
      },
      className: styles.delete_btn,
      permission: canDelete(),
    },
  ];

  const canManageMembers = () => {
    const hasManagePermissions =
      selectedTeam.permissions.includes("manage_members");
    const hasPlanManagePermissions =
      selectedTeam.planPermissions.includes("manage_members");

    // check if user has permission to manage members and make sure its not the current user
    return Boolean(
      member.userId !== self.id &&
        hasManagePermissions &&
        hasPlanManagePermissions,
    );
  };

  const onMemberUpdateHandler = (user: UserModel) => {
    onMemberUpdate(user);
    memberModalHandler();
  };

  return (
    <div className={styles.member_container}>
      <div className={styles.member_wrapper}>
        <UserAvatar
          altName="member"
          srcName={member.avatar as string}
          userName={member.name}
          className={styles.avatar}
          sizeHeight={44}
          sizeWidth={44}
        />
        <div className={styles.member_info}>
          <div className={styles.username}>
            {member.name}{" "}
            {member.nickname && (
              <span className={styles.nickname_container}>
                {member.nickname}
              </span>
            )}
          </div>
        </div>
      </div>
      {canManageMembers() && (
        <DropdownButton closeOnClick btnContent={<i className="bullets" />}>
          {memberMenu.map(
            (item) =>
              item.permission && (
                <div key={item.title} className={styles.container}>
                  <button onClick={() => item.onClick(member.id)}>
                    <p className={cls(item.className)}>{item.title}</p>
                  </button>
                </div>
              ),
          )}
        </DropdownButton>
      )}
      {memberEditModal && (
        <EditMemberModal
          member={member}
          isOpen={memberEditModal}
          onClose={memberModalHandler}
          onMemberUpdate={onMemberUpdateHandler}
        />
      )}
    </div>
  );
};

export default TeamGroupMember;
