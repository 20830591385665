/** @module services */
import { Promise, resolve, reject } from "rsvp";
import { Service, createService } from "app/utils/service";
import { AnalyticsService, FacebookService } from "app/services";
import { isNone } from "app/utils";
import { assert } from "app/utils/debug";
import uploader from "app/utils/uploader";
import { fetchPost, fetchPut } from "app/utils/request";
import {
  isAuthenticated,
  setAuth,
  invalidateSession,
  loadAuthUser,
  removeAuth,
} from "app/utils/auth";
import { bugsnagAddUser } from "app/library/bugsnag";
import {
  createdAccount,
  signedIn,
  accountCreatedSurvey,
} from "app/utils/analytics-helpers";
import { s } from "@fullcalendar/core/internal-common";

export const GRANT_TYPES = {
  VERIFICATION_TOKEN: { grant_type: "verification_token" },
  PASSWORD: { grant_type: "password" },
  CODE: { grant_type: "authorization_code" },
  OAUT_GOLFGENIUS: { grant_type: "oauth_token", provider: "golfgenius" },
  OAUTH_FACEBOOK: { grant_type: "oauth_token", provider: "facebook" },
};

export const AUTHENTICATION_KINDS = {
  PASSWORD: "Email",
  FACEBOOK: "Facebook",
};

// invitaion page will use invitation and team_short_link
const ACCOUNT_SOURCE = ["invitation", "team_short_link", "self_sign_up"];

const AUTHENTICATE_FAILURE_REASONS = {
  invalid_grant: "standard_invalid_grant",
  unknown: "standard_unknown",
};

const AUTHENTICATE_VERIFICATION_TOKEN_FAILURE_REASONS = {
  invalid_grant: "verification_token_invalid_grant",
  invalid_verification: "verification_token_invalid_verification",
  unprocessable_entity: "verification_token_unprocessable_entity",
  unknown: "verification_token_unknown",
};

const AUTHENTICATE_CODE_FAILURE_REASONS = {
  invalid_grant: "code_invalid_grant",
  "token expired": "code_expired",
  unknown: "code_unknown",
};

const PASSWORD_RESET_FAILURE_REASONS = {
  422: "password-reset-failure/NOT_FOUND",
  UNKNOWN: "password-reset-failure/UNKNOWN",
};

const FACEBOOK_AUTH_ACCOUNT = {
  invalid_grant: "facebook_grant_rejected",
  sdk_unavailable: "facebook_sdk_unavailable",
  not_authorized: "facebook_not_authorized",
  email_already_taken: "facebook_email_taken",
  "account_creation_failed.duplicate_email": "facebook_email_exists",
  unknown: "facebook_unknown",
};

/**
 * @class AuthService
 *
 */
class AuthService extends Service {
  static _name = "AuthService";

  ready() {
    this.user = null;
    this.isLoading = false;
  }

  getCookie(key) {
    var b = document.cookie.match("(^|;)\\s*" + key + "\\s*=\\s*([^;]+)");
    return b ? b.pop() : null;
  }

  get isAuthenticated() {
    return isAuthenticated();
  }

  getAuthUser() {
    if (this.isLoading) {
      return this.userPromise;
    } else if (this.user != null) {
      return resolve(this.user);
    } else {
      return this.loadAuthUser();
    }
  }

  setAuthUser(user, callback) {
    if (user != null) {
      this.user = user;
      AnalyticsService.identify(this.user.id, this.user.traits, {}, callback);
      bugsnagAddUser(this.user);
    }
    if (callback != null) {
      callback();
    }

    return user;
  }

  loadAuthUser(isNewAccount = false) {
    if (!this.isAuthenticated) {
      return resolve(null);
    }

    if (!this.isLoading || isNewAccount) {
      this.userPromise = new Promise((resolve) => {
        this.isLoading = true;
        loadAuthUser().then((user) => {
          if (isNewAccount) {
            // set AnalyticsService alias for logged in user id
            AnalyticsService.alias(user.id);
          }

          this.setAuthUser(user, () => {
            this.isLoading = false;
            resolve(user);
          });
        });
      });
    }
    return this.userPromise;
  }

  resetAuth() {
    removeAuth();
    this.isLoading = false;
    this.userPromise = null;
  }

  /**
   * Stores the auth user token
   *
   * @method handleAuthSuccess
   * @param {object} response
   * @return {object} response
   */
  handleAuthSuccess(resp, isNewAccount = false) {
    // reset current loaded user
    this.resetAuth();

    // set auth for new user
    setAuth(resp.accessToken);

    // load new user
    return this.loadAuthUser(isNewAccount);
  }

  authenticate(username, password) {
    return this._authenticate(
      { ...GRANT_TYPES.PASSWORD, username, password },
      AUTHENTICATE_FAILURE_REASONS
    );
  }

  authenticateWithCode(code) {
    return this._authenticate(
      { ...GRANT_TYPES.CODE, code },
      AUTHENTICATE_CODE_FAILURE_REASONS
    );
  }

  authenticateWithVerificationToken(verification_token) {
    return this._authenticate(
      { ...GRANT_TYPES.VERIFICATION_TOKEN, verification_token },
      AUTHENTICATE_VERIFICATION_TOKEN_FAILURE_REASONS
    );
  }

  /**
   * Authenticate a user using facebook oauth.
   *
   * @returns {Promise}
   */
  authenticateWithFacebook() {
    return FacebookService.authenticate().then((status) => {
      if (status.status === "connected") {
        const provider_token = status.authResponse.accessToken;
        return this._authenticate(
          { ...GRANT_TYPES.OAUTH_FACEBOOK, provider_token },
          FACEBOOK_AUTH_ACCOUNT
        );
      } else {
        const error = status.status || "unknown";
        return reject(FACEBOOK_AUTH_ACCOUNT[error]);
      }
    });
  }

  authenticateWithGolfGenius(provider_token) {
    return this._authenticate(
      { ...GRANT_TYPES.OAUT_GOLFGENIUS, provider_token },
      AUTHENTICATE_VERIFICATION_TOKEN_FAILURE_REASONS
    );
  }

  _authenticate(params, errorTypes) {
    const source =
      AUTHENTICATION_KINDS[
        (params.provider || params.grant_type).toUpperCase()
      ];

    return fetchPost("token", params, { version: 1, noAuth: true })
      .catch((e) => handleErrors(errorTypes, e))
      .then((data) => this.handleAuthSuccess(data, true))
      .then(() => {
        return new Promise((resolve) => {
          signedIn(source, () => resolve());
        });
      });
  }

  invalidate(noRedirect) {
    AnalyticsService.reset();
    invalidateSession(noRedirect);
  }

  /**
   * Request a password reset for user.
   *
   * @param {string} email
   * @returns {Promise}
   */
  reset(email) {
    return fetchPost("account/password", { email }, { version: 1 }).catch((e) =>
      handleErrors(PASSWORD_RESET_FAILURE_REASONS, e)
    );
  }

  /**
   * Updates account values provided during the post sign up survey.
   *
   * @param {*} survey
   * @returns {Promise}
   */
  savePostSignUpSurvey(kind, sport, kindTitle, start_trial) {
    const profile = { kind, sport, kindTitle, start_trial };
    return this.update(profile).then(
      () =>
        new Promise((resolve) => {
          accountCreatedSurvey(() => resolve());
        })
    );
  }

  /**
   * Sign up user.
   *
   * @param {object} profile
   * @param {string} source
   * @return {Promise}
   */
  signUp(model, tosAccepted = false, utmParams, verificationToken = null) {
    // reminder to developers setup force users to view tos info
    assert(
      tosAccepted === true,
      "signUp requires the caller to force users to accept tos"
    );
    assert(
      ACCOUNT_SOURCE.indexOf(model.source) !== -1,
      `model.source is required to be one of [${ACCOUNT_SOURCE.join(", ")}]`
    );

    const authSource = AUTHENTICATION_KINDS.PASSWORD;
    const user = getUserObject(model);
    user.anonymous_id = AnalyticsService.getAnonymousId();

    // integration with firstpromoter.com
    user.affiliate_visitor_id = this.getCookie("_fprom_track");
    user.affiliate_promoter_code = this.getCookie("_fprom_code");

    return fetchPost(
      "account",
      {
        user: Object.assign({}, utmParams, user),
        verification_token: verificationToken,
      },
      { version: 1 }
    )
      .then((data) => {
        return this.handleAuthSuccess(data, true);
      })
      .then((data) => {
        if (!isNone(model.avatar)) {
          return this._uploadAvatar(data.id, model.avatar).then(() => data);
        }
        return data;
      })
      .then(() => {
        return new Promise((resolve) => {
          createdAccount(authSource, () => {
            resolve(user);
          });
        });
      });
  }

  signUpWithGolfGenius(auth_code) {
    const authSource = AUTHENTICATION_KINDS.PASSWORD;

    const params = {
      provider: {
        name: "golfgenius",
        auth_code: auth_code,
      },
    };

    return fetchPost("account", params, { version: 1 })
      .then((data) => {
        return this.handleAuthSuccess(data, true);
      })
      .then((fetchedUser) => {
        return new Promise((resolve) => {
          createdAccount(authSource, () => {
            resolve(fetchedUser);
          });
        });
      });
  }

  /**
   * Signs up a user using the current facebook context.
   *
   * @param {string} source
   * @returns {Promise}
   */
  signUpWithFacebook(source, tosAccepted, utmParams) {
    // reminder to developers setup force users to view tos info
    assert(
      tosAccepted === true,
      "signUp requires the caller to force users to accept tos"
    );
    assert(
      ACCOUNT_SOURCE.indexOf(source) !== -1,
      `source is required to be one of [${ACCOUNT_SOURCE.join(", ")}]`
    );

    const authSource = AUTHENTICATION_KINDS.FACEBOOK;
    return FacebookService.getAccountInfo()
      .then((data) => fbPromise(data))
      .then((response) =>
        this._createAccountFromFacebookProfile(response, source, utmParams)
      )
      .then(
        () =>
          new Promise((resolve) => {
            createdAccount(authSource, () => resolve());
          })
      );
  }

  /**
   * Creates a consent token from a stripe card token.
   *
   * @param {string} cardToken
   * @returns {Promise}
   */
  createConsentToken(token) {
    return fetchPost("account/consent_token", { token }, { version: 1 })
      .then((data) => data.token)
      .catch((e) => {
        const status = responseStatus(e);
        if (status === 422) {
          const error = responseErrors(e);
          if (error.token != null) {
            return reject("card_invalid");
          }
        }
        return reject("billing_unknown");
      });
  }

  /**
   * Updates the account with the user object.
   *
   * @param {object} user
   * @returns {Promise}
   */
  update(user) {
    return fetchPut("account", { user }, { version: 1 }).catch((e) =>
      reject(e.errors)
    );
  }

  /**
   * Creates an account using a facebook profile.
   *
   * @param {*} profile
   * @param {*} source
   */
  _createAccountFromFacebookProfile(data, source, utmParams) {
    const { authResponse, profile } = data;

    // make sure the authResponse is provided
    if (isNone(authResponse) || isNone(authResponse.accessToken)) {
      return reject("not_authorized");
    }

    // make sure the profile is provided
    if (isNone(profile) || isNone(profile.email)) {
      return reject("email_not_allowed");
    }

    const anonymous_id = AnalyticsService.getAnonymousId();
    const params = {
      provider: {
        name: "facebook",
        access_token: authResponse.accessToken,
      },
      user: Object.assign(
        {
          source,
          anonymous_id,
          avatar_url: profile.picture.data.url,
          email: profile.email,
          first_name: profile.first_name,
          last_name: profile.last_name,
          pp_tos_accepted: true,
          timezone_offset: new Date().getTimezoneOffset(),
        },
        utmParams
      ),
    };

    return fetchPost("account", params, { version: 1 })
      .then((data) => this.handleAuthSuccess(data, true))
      .then((data) => ({ data, provider: params.provider }))
      .catch((e) => handleErrors(FACEBOOK_AUTH_ACCOUNT, e));
  }

  /**
   * Uploads avatar to S3 for a given user Id.
   *
   * @param {string} userId
   * @param {blob} avatar
   * @returns {Promise}
   */
  _uploadAvatar(userId, avatar) {
    const blob = avatar.blob || avatar;
    return uploader(blob, "avatar.jpeg", "image/jpeg", "avatar").then(
      (result) => this.update({ id: userId, avatar_id: result.id })
    );
  }
}

const handleErrors = (messageTypes, e) => {
  if (e.errors && e.errors.length) {
    return reject(messageTypes[e.errors[0]] || messageTypes["unknown"]);
  }
  return reject("unknown");
};

const fbPromise = (response) => {
  if (response.status === "connected") {
    return resolve(response);
  } else {
    return reject(response.status);
  }
};

const responseStatus = (resp) => {
  if (resp.response && resp.response.status) {
    return resp.response.status;
  }
  return resp.status;
};

const responseErrors = (resp) => {
  if (resp.response && resp.response.errors) {
    return resp.response.errors || {};
  }
  return resp.errors || {};
};

const getUserObject = ({
  source,
  firstName,
  lastName,
  email,
  password,
  consentToken,
  coachId,
}) => ({
  source,
  email,
  password,
  firstName,
  lastName,
  consentToken,
  ppTosAccepted: true,
  timezoneOffset: new Date().getTimezoneOffset(),
  coachId,
});

export default createService(AuthService);
