import React from "react";
import LikesViewsModal from "../LikesViewsModal";
import { cls } from "app/utils";
import { useTranslation } from "react-i18next";

interface Props {
  likes: number;
  postId: string;
  canSeeWhoLiked?: boolean;
}

const LikesText = ({ likes, postId, canSeeWhoLiked = true }: Props) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = React.useState(false);
  const modalHandler = () => {
    if (canSeeWhoLiked) {
      setIsOpen(!isOpen);
    }
  };

  return (
    <>
      <div
        onClick={modalHandler}
        className={cls(`post-count`, {
          ["active"]: likes >= 1,
        })}
      >
        {canSeeWhoLiked && t("count_like", { count: likes })}
      </div>
      {isOpen && (
        <LikesViewsModal
          isOpen={isOpen}
          onCloseFunc={modalHandler}
          likesCounter={likes}
          postId={postId}
        />
      )}
    </>
  );
};

export default LikesText;
