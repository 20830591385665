import { useMemo } from "react";

const useShuffledArray = <T>(array: T[]): T[] => {
  return useMemo(() => {
    const shuffledArray = [...array];
    for (let i = shuffledArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledArray[i], shuffledArray[j]] = [
        shuffledArray[j],
        shuffledArray[i],
      ];
    }
    return shuffledArray;
  }, [array]);
};

export default useShuffledArray;
