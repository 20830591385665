import React, { LegacyRef, useEffect } from "react";

import ReactDatePicker, { registerLocale } from "react-datepicker";
import { DateTime } from "luxon";

import styles from "./styles.module.scss";
import i18n from "app/i18n";
import en from "date-fns/locale/en-US";
import ja from "date-fns/locale/ja";

const locales = {
  en,
  ja,
};

type PropsDef = {
  date: DateTime;
  setDate: (DateTime) => void;
  formatDate?: (DateTime) => string;
  disabled?: boolean;
  placeholderText: string;
  maxDate?: Date;
  minDate?: Date;
};

const DatePicker = (props: PropsDef) => {
  const { date, setDate, maxDate, minDate = new Date() } = props;

  const CustomHeader = ({ date, decreaseMonth, increaseMonth }) => {
    return (
      <div className={styles.header}>
        <div
          className={styles.date_arrow}
          style={{ transform: "rotate(90deg)" }}
          onClick={decreaseMonth}
        >
          <span>
            {" "}
            <i className="ico ico-chevron" />{" "}
          </span>
        </div>
        <p className={styles.current_month}>
          {date.toLocaleString({ month: "long", year: "numeric" })}
        </p>
        <div
          className={styles.date_arrow}
          style={{ transform: "rotate(-90deg)" }}
          onClick={increaseMonth}
        >
          <span>
            {" "}
            <i className="ico ico-chevron" />{" "}
          </span>
        </div>
      </div>
    );
  };

  const CustomInput = React.forwardRef((props, ref) => {
    return (
      <input
        {...props}
        type="text"
        value={date?.toLocaleString({
          month: "long",
          day: "numeric",
          year: "numeric",
        })}
        ref={ref as LegacyRef<HTMLInputElement>}
      />
    );
  });
  CustomInput.displayName = "HeaderInput";

  useEffect((): void => {
    const currentLocale = locales[i18n.language] || locales.en;
    registerLocale(i18n.language, currentLocale);
  }, [i18n.language]);

  return (
    <div className={styles.date_picker}>
      <ReactDatePicker
        locale={i18n.language}
        selected={date?.toJSDate()}
        onChange={(value) => setDate(DateTime.fromJSDate(value))}
        customInput={<CustomInput />}
        useWeekdaysShort={true}
        renderCustomHeader={({
          date: selDate,
          decreaseMonth,
          increaseMonth,
        }) => (
          <CustomHeader
            increaseMonth={increaseMonth}
            decreaseMonth={decreaseMonth}
            date={DateTime.fromJSDate(selDate)}
          />
        )}
        maxDate={maxDate}
        minDate={minDate}
        calendarClassName={styles.day_picker}
        disabled={props.disabled}
        placeholderText={props.placeholderText}
        disabledKeyboardNavigation
        onKeyDown={(e) => {
          e.preventDefault();
        }}
      />
    </div>
  );
};

export default DatePicker;
