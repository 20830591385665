import React from "react";
import GroupPageLayout from "../../../components/Layouts/GroupPageLayout";
import { GroupTimelineModel } from "./indexModel";
import GroupPostingForm from "../../../components/GroupPostingForm";
import styles from "./styles.module.scss";
import GroupTabsContainer from "../_components/GroupTabsContainer";
import Filter from "app/components/Filter";
import GroupsPostsContainer from "app/components/GroupsPostsContainer";
import PostReportModal from "app/components/Post/InnerPost/PostReportModal";
import GroupInviteModal from "../_components/GroupInviteModal";
import CreatedScheduledPostModal from "app/components/CreatedScheduledPostModal";

const GroupTimeline = () => {
  const {
    selectedGroup,
    data,
    scheduledPostsData,
    onPost,
    pending,
    onPostDelete,
    reportModal,
    reportModalClose,
    onReportHandler,
    onPostReportClick,
    onPostUpdate,
    onPostPin,
    sentryRef,
    filterHandler,
    onClearFilters,
    modalInviteIsOpen,
    canSeePostviews,
    canSeeWhoLiked,
    canPost,
    newScehduleModalOpen,
    handleNewSchedulePost,
    scheduledAt,
    canViewMembers,
  } = GroupTimelineModel();

  return (
    <GroupPageLayout>
      <div className={styles.group_tabs_container}>
        <GroupTabsContainer groupData={selectedGroup} />
      </div>
      {canPost && <GroupPostingForm onPost={onPost} />}
      <div className={styles.dropdown_container}>
        <hr />
        <Filter
          filterClickHandler={filterHandler}
          onFilterClear={onClearFilters}
          hideAuthors={!canViewMembers()}
        />
      </div>
      <GroupsPostsContainer
        scheduledPostsData={scheduledPostsData}
        displayPostLocations={false}
        hidePinBadge={false}
        onPostDelete={onPostDelete}
        onPostUpdate={onPostUpdate}
        onReport={onPostReportClick}
        onPostPin={onPostPin}
        containerRef={sentryRef}
        posts={data}
        pending={pending}
        canSeePostViews={canSeePostviews}
        canSeeWhoLiked={canSeeWhoLiked}
      />
      {reportModal.isOpen && (
        <PostReportModal
          isModalOpen={reportModal.isOpen}
          modalHandler={reportModalClose}
          reportHandler={onReportHandler}
        />
      )}
      {modalInviteIsOpen && <GroupInviteModal />}
      {newScehduleModalOpen && (
        <CreatedScheduledPostModal
          isOpen={newScehduleModalOpen}
          onClose={handleNewSchedulePost}
          redirectUrl={"/schedule"}
          scheduledAt={scheduledAt}
          location={"Team"}
        />
      )}
    </GroupPageLayout>
  );
};

export default GroupTimeline;
