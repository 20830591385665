import React from 'react';
import { useController } from 'react-hook-form';
import { DateTime } from 'luxon';

import styles from './styles.module.scss';
import SelectBox from 'app/components/OptionButtonGroup/SelectBox';
import _ from 'lodash';

type PropsDef = {
  name: string;
  control: any;
  timeZone: string;
  dateList: any[];
  customStartTime: string;
};

const timeToMinutes = (value, timeZone) => {
  const time = DateTime.fromISO(value).setZone(timeZone);

  const minutes = time.hour * 60 + time.minute;

  return minutes;
};

const minutesToTime = (day, timeZone, minutes) => {
  const time = DateTime.fromISO(day).setZone(timeZone);

  return time.startOf('day').plus({minutes}).toISO();
};

const onSetValue = (field, timeZone, minutes) => {
  const today = field.value === null ? DateTime.now().setZone(timeZone).toISO() : field.value;

  const time = minutesToTime(today, timeZone, minutes);

  field.onChange(time);
};

const disabledValues = (dateList, field, timeZone, customStartTime) => {
  if(field.name === 'start') {
    return dateList;
  }

  let minutes;

  if(customStartTime) {
    minutes = timeToMinutes(customStartTime, timeZone);
  } else {
    minutes = timeToMinutes(field.value, timeZone) - 15;
  }

  return dateList.filter(element => element.value > minutes);
};

const TimeRangeInput = React.forwardRef((props: PropsDef) => {
  const {
    name,
    control,
    timeZone,
    dateList,
    customStartTime,
  } = props;

  const { field } = useController({ name, control });
  const currentMinutes = timeToMinutes(field.value, timeZone);

  const localDateList = disabledValues(dateList, field, timeZone, customStartTime);

  return (
    <div className={styles.container}>
      <SelectBox
        options={localDateList}
        value={localDateList && localDateList.find((el) => el.value === currentMinutes) || null}
        setValue={(dateListElement: {value: number, label: string}) => {
          onSetValue(field, timeZone, dateListElement.value);
        }

        }
        customWidth="152px"
      />
    </div>
  );
});

export default TimeRangeInput;
