import React, { useContext } from "react";
import styles from "./styles.module.scss";
import { useDispatch } from "react-redux";
import { deselectEvent } from "app/actions/events";
import { useTranslation } from "react-i18next";
import { EventType } from "app/models/EventModel";
import { UserContext, RoleType } from 'app/contexts/UserContext';
import { DateTime } from "luxon";
import { useHistory } from "react-router-dom";

const ScheduleBlocksInfo = ({event, onEdit, onDelete}) => {
  const { t } = useTranslation();
  const user = useContext(UserContext);

  const ownEvent =
    user.role === RoleType.COACH && event.coachProfile?.id === user.id;
  const isPublicBlock =
    event.type === EventType.COACH_SCHEDULE_BLOCK && !event.private;
  const isAdmin =
    user.role === RoleType.ADMIN ||
    (user.role === RoleType.COACH && user.isAdmin);

  let allowEdit: boolean;
  if (event.type === EventType.LESSON_SCHEDULE) {
    allowEdit = isAdmin || ownEvent || event.date > new Date();
  } else {
    allowEdit = ownEvent || (isPublicBlock && isAdmin);
  }

  const event_end_time = DateTime.fromISO(event.end, {setZone: true});
  const now = DateTime.now().setZone(event.timeZone);
  const pastEvent: boolean = now > event_end_time;

  const history = useHistory();

  const onFeedIconClick = () => {
    if (event.feedPostId) {
      const targetUrl = `/posts/${event.feedPostId}`;
      dispatch(deselectEvent());
      history.push(targetUrl);
    }
  };

  const dispatch = useDispatch();
  return (
    <>
      <div className={styles.header}>
        <div className={styles.event_title}>
          <p>{event.title}</p>
          {event.scheduleProviderDisplayName &&
            (<div className={styles.repeat}>{`${t('Booked via ')} ${event.scheduleProviderDisplayName}`}</div>)
          }
          <div className={styles.repeat}>{event.repeatLabel}</div>
        </div>
        <div className={styles.header_actions}>
          {(!event.externalCalendarId && allowEdit && !event.scheduleProviderDisplayName && !event.feedPostId && !pastEvent) && (
            <>
              <a onClick={onEdit}>
                <i className="ico ico-pencil-o" />
              </a>
              <a onClick={onDelete}>
                <i className="ico ico-trash" />
              </a>
            </>
          )}
          {(event.feedPostId) && (
            <a onClick={() => onFeedIconClick()}>
              <i className="ico ico-feed-o" />
            </a>
          )}
          <a onClick={() => dispatch(deselectEvent())}>
            <i className="ico ico-x" />
          </a>
        </div>
      </div>
      <div className={styles.event_subtitle}>
        <i className="ico ico-calendar"></i>
        <span>{event.dateString}</span>
      </div>
      <div className={styles.event_subtitle}>
        <i className="ico ico-clock"></i>
        <span>{event.intervalTimeString}</span>
      </div>
      <div className={styles.event_notes}>{event.notes}</div>
    </>
  );
};

export default ScheduleBlocksInfo;
