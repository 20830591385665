import React from "react";
import styles from "./styles.module.scss";
import { SpacesTimelineModel } from "./indexModel";
import SpacePageLayout from "app/components/Layouts/SpacesPageLayout";
import GroupsPostsContainer from "app/components/GroupsPostsContainer";
import Filter from "app/components/Filter";
import SpacePostingForm from "app/components/SpacePostingForm";
import PostReportModal from "app/components/Post/InnerPost/PostReportModal";
import CreatedScheduledPostModal from "app/components/CreatedScheduledPostModal";

const SpacesTimeline = () => {
  const {
    spaceId,
    selectedSpace,
    data,
    scheduledPostsData,
    onPost,
    pending,
    reportModal,
    reportModalClose,
    onReportHandler,
    onPostReportClick,
    onPostDelete,
    onPostUpdate,
    onPostPin,
    sentryRef,
    filterHandler,
    onClearFilters,
    newScehduleModalOpen,
    handleNewSchedulePost,
    scheduledAt,
    scheduledPostLocation,
  } = SpacesTimelineModel();

  return (
    <SpacePageLayout>
      {selectedSpace && (
        <div className={styles.content_container}>
          <SpacePostingForm onPost={onPost} />
          <div className={styles.dropdown_container}>
            <hr />
            <Filter
              filterClickHandler={filterHandler}
              onFilterClear={onClearFilters}
            />
          </div>
          <GroupsPostsContainer
            schedulesGoToLocation={`/spaces/${spaceId}/schedule`}
            scheduledPostsData={scheduledPostsData}
            displayPostLocations={false}
            hidePinBadge={false}
            onPostDelete={onPostDelete}
            onPostUpdate={onPostUpdate}
            onPostPin={onPostPin}
            onReport={onPostReportClick}
            containerRef={sentryRef}
            posts={data}
            pending={pending}
          />
        </div>
      )}
      {reportModal.isOpen && (
        <PostReportModal
          isModalOpen={reportModal.isOpen}
          modalHandler={reportModalClose}
          reportHandler={onReportHandler}
        />
      )}

      {newScehduleModalOpen && (
        <CreatedScheduledPostModal
          isOpen={newScehduleModalOpen}
          onClose={handleNewSchedulePost}
          redirectUrl={scheduledPostLocation}
          scheduledAt={scheduledAt}
          location={"Space"}
        />
      )}
    </SpacePageLayout>
  );
};

export default SpacesTimeline;
