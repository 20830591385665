import { DateTime } from "luxon";
import i18n from "app/i18n";

export const useCalendarUtils = () => {
  const getNthDayOfTheMonth = (date: DateTime) => {
    const firstDay = date.startOf("month");

    const dateDiff = date.diff(firstDay, "days").toObject().days;

    if (dateDiff < 6) {
      return i18n.t("first");
    }
    if (dateDiff < 13) {
      return i18n.t("second");
    }
    if (dateDiff < 19) {
      return i18n.t("third");
    }
    if (dateDiff < 25) {
      return i18n.t("fourth");
    }

    return i18n.t("last");
  };

  const getRecurrenceLabel = (time: DateTime, { label, value }) => {
    if (!time) {
      return { value, label };
    }

    if (!time.isValid) {
      return { value, label };
    }

    if (value === "daily" || !value) {
      return { value, label };
    }

    const weekday = time.toLocaleString({ weekday: "long" });

    if (value === "weekly" || value === "biweekly") {
      return {
        value,
        // label: formatString(t(`%{label} on %{weekday}`), { label, weekday }),
        label: i18n.t("label_on_weekday", { label, weekday }),
      };
    }

    return {
      value,
      // label: formatString(t(`%{label} on the %{day} %{weekday}`), {
      //   label,
      //   day: getNthDayOfTheMonth(time),
      //   weekday,
      // }),
      label: i18n.t("label_on_the_day_weekday", {
        label,
        day: getNthDayOfTheMonth(time),
        weekday,
      }),
    };
  };

  return {
    getRecurrenceLabel,
  };
};
