/**
 * @module Actions.User
 *
 */
import { Promise } from "rsvp";
import { Dispatch } from "react";
import { ActionType, action } from "typesafe-actions";
import { request, fetchGet, fetchPut } from "app/utils/request";
import { ErrorResponse } from "app/utils/request/errorHandler";
import { UserModel } from "app/models";
import config from "config/environment";
import { schedulingFetchGet } from "app/utils/request/scheduling/fetch";
import {
  FacilityModel,
  FacilityResponse,
} from "app/models/scheduling/FacilityModel";
import { RootState } from "typedefs";

export const asyncUserGet = (id?: string): Promise<UserModel> => {
  const query = id != null ? `/${id}` : "";
  return fetchGet<UserModel>(`account${query}`, {}, { version: 1 }).then(
    (user: UserModel) => user,
  );
};

export const asyncSchedulingProfileGet = (): Promise<FacilityModel> => {
  return schedulingFetchGet<FacilityResponse>(`facility`).then(
    (facility: FacilityResponse) => {
      return new FacilityModel(facility);
    },
  );
};

const pending = () => action("@user.fetch.pending");
const error = (err: string) => action("@user.fetch.error", err);
const success = (user: UserModel) => action("@user.fetch.success", user);
const fetchDone = () => action("@user.fetch.done");
const resetPasswordSuccess = () => action("@user.put.reset-password.success");
export const setSchedulingData = (payload: FacilityModel | {}) =>
  action("@user.set_scheduling_data", payload);

const thisActions = {
  pending,
  error,
  success,
  resetPasswordSuccess,
  setSchedulingData,
  fetchDone,
};

export type UserAction = ActionType<typeof thisActions>;

export function fetchSelf() {
  return (dispatch: Dispatch<UserAction>) => {
    dispatch(pending());

    const userPromise = asyncUserGet()
      .then((user) => {
        dispatch(success(user));
        return user;
      })
      .then((user) => {
        if (!user.features.includes("scheduling") || user.kind !== "coach") {
          return Promise.resolve({});
        }
        return asyncSchedulingProfileGet().catch((err) => {
          console.error("scheduleProfileFetchGetErr", err);
          return { facility: null };
        });
      })
      .then((facility: FacilityModel) => {
        dispatch(setSchedulingData(facility));
      })
      .then(() => dispatch(fetchDone()))
      .catch((err) => dispatch(error(err)));

    return userPromise;
  };
}

export function resetPasswordWithToken(
  resetPasswordToken: string,
  password: string,
  passwordConfirmation: string,
) {
  return (dispatch: Dispatch<UserAction>) => {
    dispatch(pending());

    const url = config.API_SERVER + "/password";
    const user = { resetPasswordToken, password, passwordConfirmation };
    const promise = request(url, "PUT", { user }, { version: 1 });
    promise.then(() => dispatch(resetPasswordSuccess()));
    promise.catch((e: ErrorResponse) => dispatch(error(e.errors[0])));

    return promise;
  };
}

export function editProfileSettings(user: { [key: string]: any }) {
  return (dispatch: Dispatch<UserAction>) => {
    const url = config.API_SERVER + `/api/v1/account`;
    const promise = request(url, "PUT", { user }, { version: 1 });
    promise.catch((err) => dispatch(error(err)));

    return promise;
  };
}

export function editPhonee({
  verification_token,
  phoneInfo,
}: {
  [key: string]: any;
}) {
  return (dispatch: Dispatch<UserAction>, getState: () => RootState) => {
    const state = getState().user;
    const url = config.API_SERVER + `/api/v1/account/phone`;
    const promise = request(url, "PUT", { verification_token }, { version: 1 });
    dispatch(success({ ...state?.data, phoneVerified: true, ...phoneInfo }));
    promise.catch((err) => dispatch(error(err)));

    return promise;
  };
}

export const editPhone =
  ({ verification_token }: { verification_token: string }) =>
  async () => {
    const url = `/account/phone`;

    try {
      const response = await fetchPut(
        url,
        { verification_token },
        { version: 1 },
      );
      return response;
    } catch (err) {
      throw err;
    }
  };
export function getIntegrations() {
  return (dispatch: Dispatch<UserAction>) => {
    const url = config.API_SERVER + `/api/v1/account/integrations`;
    const promise = request(url, "GET", {}, { version: 1 });
    promise.catch((err) => dispatch(error(err)));

    return promise;
  };
}

export function createIntegration(integration: { [key: string]: any }) {
  return (dispatch: Dispatch<UserAction>) => {
    const url = config.API_SERVER + `/api/v1/account/integrations`;
    const promise = request(url, "POST", { integration }, { version: 1 });
    promise.catch((err) => dispatch(error(err)));

    return promise;
  };
}

export function changePassword(
  currentPassword: string,
  password: string,
  passwordConfirmation: string,
) {
  return (dispatch: Dispatch<UserAction>) => {
    const user = { currentPassword, password, passwordConfirmation };
    const url = config.API_SERVER + `/api/v1/account/password`;
    const promise = request(url, "PUT", { user }, { version: 1 });
    promise.catch((err) => dispatch(error(err)));

    return promise;
  };
}

export const actions = {
  fetchSelf,
  resetPasswordWithToken,
  changePassword,
  editProfileSettings,
};
