import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import UserModel from "app/models/UserModel";
import { useActions } from "app/utils/hooks";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { RootState } from "typedefs";
import { tabType } from ".";
import {
  clearGroupMembers,
  fetchGroupMembers,
  deleteGroupMember,
  updateGroupMember,
} from "app/actions/groups";
import useDebounce from "app/utils/hooks/useDebounce";
import { inviteModalIsOpen } from "app/actions/team-group-member-import";
import { useHistory, useParams } from "react-router-dom";
import { usePagination } from "app/utils/hooks/usePagination";

type FilterName = "coach" | "player" | "viewer";

const INIT_MEMBERS_PAGE: number = 1;
const MEMBERS_PER_PAGE: number = 20;

export const useGroupMembersModel = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const groupId = useParams<{ id: string }>();
  const memberActions = useActions({
    fetchGroupMembers,
    clearGroupMembers,
    deleteGroupMember,
    updateGroupMember,
  });
  const { selectedGroup } = useSelector(
    (state: RootState) => state.groups,
    shallowEqual,
  );
  const { self } = useSelector((state: RootState) => state.user, shallowEqual);
  const { modalInviteIsOpen } = useSelector(
    (state: RootState) => state.teamGroupMemberImport,
    shallowEqual,
  );

  const [selectedTab, setSelectedTab] = useState<tabType>({
    label: t("Active"),
    value: "accepted",
  });
  const [memberFilterParams, setMemberFilter] = useState({
    coach: true,
    player: true,
    viewer: true,
  });
  const [searchTerm, setSearchTerm] = useState<string | null>(null);
  const history = useHistory();

  const route = (path: string) => {
    history.push(path);
  };

  const membersFilterHandler = (e: { [key in FilterName]: boolean }) => {
    setMemberFilter(e);
  };

  const canManageMembers =
    selectedGroup?.permissions?.includes?.["manage_members"] &&
    selectedGroup?.planPermissions?.includes?.["manage_members"];

  const onTabSelect = useCallback((tab: tabType) => {
    setSelectedTab(tab);
  }, []);

  const inviteMembersModalOpen = () => {
    dispatch(inviteModalIsOpen(true));
  };

  const tabs = [
    { label: t("Active"), value: "accepted" },
    { label: t("Invited"), value: "pending" },
    { label: t("Incomplete"), value: "incomplete" },
  ];

  const updatedTypeParams = Object.entries(memberFilterParams)
    .filter(([_, value]) => value)
    .map(([key]) => key);

  const debouncedSearch = useDebounce(searchTerm, 300);

  const memberSearchParams = {
    type: updatedTypeParams,
    status: selectedTab.value,
    userName: debouncedSearch,
  };

  const {
    data: members,
    pending,
    sentryRef,
    updateData,
    clearData,
  } = usePagination({
    initialParams: {
      page: INIT_MEMBERS_PAGE,
      perPage: MEMBERS_PER_PAGE,
      ...memberSearchParams,
    },
    fetchData: (params, signal) =>
      memberActions.fetchGroupMembers(groupId?.id, params, signal),
    clearDataAction: memberActions.clearGroupMembers,
    dependencies: [debouncedSearch, memberFilterParams],
  });

  useEffect(() => {
    return () => {
      clearData();
    };
  }, []);

  const onDeleteMember = (memberId: string, userName: string) => {
    const confirmation = window.confirm(
      t("Are you sure you want to remove {{userName}} from this Group?", {
        userName,
      }),
    );
    if (confirmation) {
      memberActions.deleteGroupMember(groupId?.id, memberId).then(() => {
        if (memberId === self.id) {
          route("/groups");
        } else {
          updateData((prevData) =>
            prevData?.filter((member: UserModel) => member?.id !== memberId),
          );
        }
      });
    }
  };

  const onMemberUpdate = (updatedMember: UserModel) => {
    memberActions.updateGroupMember(groupId?.id, updatedMember).then(() => {
      updateData((prevMembers: UserModel[]) =>
        prevMembers.map((member) =>
          member?.id === updatedMember.id ? updatedMember : member,
        ),
      );
    });
  };

  return {
    selectedGroup,
    canManageMembers,
    tabs,
    selectedTab,
    onTabSelect,
    membersFilterHandler,
    members,
    onDeleteMember,
    onMemberUpdate,
    memberFilterParams,
    memberSearchHandler: setSearchTerm,
    inviteMembersModalOpen,
    modalInviteIsOpen,
    sentryRef,
    pending,
  };
};
