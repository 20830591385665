import {
  clearGroup,
  clearSelectedGroupData,
  fetchGroupById,
} from "app/actions/groups";
import {
  fetchTeamGroups,
  fetchTeamGroupData,
  clearTeamGroup,
  selectTeam as setSelectedTeam,
} from "app/actions/team-group";
import { fetchJoinableTeam } from "app/actions/team-group-member-import";
import { useActions, useSelector } from "app/utils/hooks";
import { useEffect, useMemo, useState } from "react";
import { shallowEqual, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { RootState } from "typedefs";

export const GroupPageLayoutModel = () => {
  const dispatch = useDispatch();
  const param = useParams<{ id: string; teamId: string }>();
  const groupActions = useActions({
    fetchGroupById,
    fetchTeamGroupData,
    fetchJoinableTeam,
    fetchTeamGroups,
    clearGroup,
    clearTeamGroup,
    clearSelectedGroupData,
  });
  const { selectedGroup } = useSelector(
    (state: RootState) => state.groups,
    shallowEqual,
  );

  const { data: channels } = useSelector(
    (state: RootState) => state.teamGroup,
    shallowEqual,
  );

  const [joinableList, setJoinableList] = useState([]);

  useEffect(() => {
    if (param.id) {
      groupActions.fetchTeamGroups(param?.id);
    }
  }, []);

  useMemo(() => {
    Promise.all([
      groupActions.fetchGroupById(param?.id),
      groupActions.fetchJoinableTeam(param?.id).then((res) => {
        setJoinableList(res);
      }),
    ]);

    if (param?.teamId) {
      groupActions.fetchTeamGroupData(param?.teamId);
    }

    return () => {
      groupActions.clearGroup();
      groupActions.clearTeamGroup();
      groupActions.clearSelectedGroupData();
      dispatch(setSelectedTeam(null));
      // dispatch(selectedGroup)
    };
  }, [param.id, param.teamId]);

  return { selectedGroup, channels, joinableList };
};
