import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import styles from "./styles.module.scss";
import ProgramOption from "../ProgramOption";
import ClinicSvg from "../../../../public/images/clinic-icon.svg";
import CampSvg from "../../../../public/images/camp-icon.svg";
import OperaionSvg from "../../../../public/images/operation36-icon.svg";
import SaveBar from "../../Sessions/SaveBar";
import { useDispatch } from "react-redux";
import {
  resetProgramSettings,
  setDefaultImages,
  startBuild,
} from "../../../actions/scheduling/program";
import { isNull } from "lodash";
import { useTranslation } from "react-i18next";
import { schedulingFetchGet } from "app/utils/request/scheduling/fetch";
import { fetchGet } from "app/utils/request";
import useCurrentFacility from "app/utils/hooks/scheduling/useCurrentFacility";

interface Option {
  title: string;
  label: string;
  subtitle: string;
  image: string;
}

export default function ProgramOptions() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selected, setSelected] = useState<Option | null>(null);
  const history = useHistory();

  const currentFacility = useCurrentFacility();
  const onSubmit = () => {
    if (selected?.label === "Operation") {
      window.open("https://operation36golf.com/programs/new", "_blank");
      history.push("/programs");
    } else {
      schedulingFetchGet("/default_images").then((response) => {
        dispatch(setDefaultImages(response));
      });
      fetchGet('/account').then((response) => {
        dispatch(
          startBuild({
            page: 1,
            type: selected?.label,
            facilityId: currentFacility.data.id,
            email: response?.email,
            phone: response?.phone,
            timeZone: currentFacility.data.timeZone,
            location: currentFacility.data.address,
            currency: currentFacility.data.currency,
          })
        );
      });
    }
  };
  const handleSelection = (item) => {
    setSelected(item);
  };
  const programOptions: Option[] = [
    {
      title: t("DROP-IN CLINIC"),
      label: t("Clinic"),
      subtitle: t(
        "Add new sessions one by one, customize your schedule as you go!"
      ),
      image: ClinicSvg,
    },
    {
      title: t("SERIES"),
      label: t("Series"),
      subtitle: t(
        "Create multiple sessions at once. It's easy - we'll automatically create them for you!"
      ),
      image: CampSvg,
    },
    {
      title: t("OPERATION 36 PROGRAM"),
      label: t("Operation"),
      subtitle: t(
        "Build a developmental program using the Operation 36 Curriculum and 9-Hole events features!"
      ),
      image: OperaionSvg,
    },
  ];

  return (
    <div>
      <div className={`${styles.empty_container} calendar-page-container`}>
        <div>
          <div className={styles.container}>
            <p className={styles.gray_text}>
              {t(
                "Build a program from scratch based on how you manage registrations"
              )}
              .
            </p>
            <p className={`${styles.gray_text} ${styles.mt_20}`}>
              {t(
                "If athletes can register for individual sessions, build a Drop-In Clinic; if athletes must register for all sessions at once, build a Camp"
              )}
              .
              <br />
              {t(
                "To Clone a previously created Camp or Clinic, go back to the Manage Programs page and use the down arrow to Clone"
              )}
              .
              <br />
              {t(
                "Launch the Operation 36 Program Builder if you will be using the Operation 36 Curriculum and 9-Hole Events"
              )}
              .
            </p>
            <div
              className={`${styles.program_options_line_container} ${styles.mt_20}`}
            >
              {programOptions.map((item, index) => {
                return (
                  <ProgramOption
                    key={index}
                    title={item.title}
                    subtitle={item.subtitle}
                    DisplayImage={item.image}
                    selected={selected?.title === item.title}
                    onClick={() => handleSelection(item)}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <SaveBar
        onSubmit={onSubmit}
        showCancel
        onCancel={() => {
          dispatch(resetProgramSettings());
          history.push("/programs");
        }}
        submitDisabled={isNull(selected)}
      />
    </div>
  );
}
