import PagePanel from "app/components/PagePanel";
import { useSelector } from "react-redux";
import React, { useState, useEffect } from "react";
import Navbar from "./Navbar";
import ReturnBar from "./Navbar/ReturnBar";

import AddPricing from "./Pricing/Modals/AddPricing";
import EditPricingGroup from "./Pricing/Modals/EditPricingGroup";
import PricingView from "./Pricing/PricingView";
import styles from "./styles.module.scss";
import { schedulingFetchGet, schedulingFetchPatch } from "app/utils/request/scheduling/fetch";
import InfoModal from "../Calendar/InfoModal";
import { RootState } from 'app/reducers';
import { useTranslation } from 'react-i18next';
import ConflictModal from "./Pricing/Modals/ConflictModal";
import {toast} from "react-toastify";
import {useHistory} from "react-router-dom";

interface LessonRate {
  id: string | number;
  rate: number | string;
  defaultRate: boolean;
  location: string;
  duration: string;
  title: string;
  _destroy?: boolean;
  active: boolean;
}

interface PricingGroups {
  title: string;
  lessonRates: LessonRate[];
  id: string | number;
}

interface InfoPopupContent {
  title: string;
  status: "error" | "success";
  content: string;
  subcontent?: string;
}

export default function Pricing() {
  const { t } = useTranslation();

  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [isAddModalOpen, setAddModalOpen] = useState(false);
  const [pricingGroups, setPricingGroups] = useState<PricingGroups>({
    title: "",
    lessonRates: [],
    id: "",
  });
  const [isInfoModalOpen, setInfoModalOpen] = useState(false);
  const [infoPopupContent, setInfoPopupContent] = useState<InfoPopupContent>({
    title: "",
    status: "success",
    content: "",
  });
  const userId = useSelector((state: RootState) => state.user.self.id);
  const [coachProfile, setCoachProfile] = useState(null);

  const [savePricings, setSavePricings] = useState(false);
  const closeEditModal = () => setEditModalOpen(false);
  const openAddModal = () => setAddModalOpen(true);
  const closeAddModal = () => setAddModalOpen(false);
  const [isDefaultOptionDeactivatedModalOopen, setDefaultOptionDeactivatedModalOopen] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const history = useHistory();
  const [conflictModalText, setConflictModalText] = useState(
    (
      <div>
        {t('You are deactivating the default booking option.')}
        <br/>
        {t('Please select an active booking option as your default before saving changes.')}
      </div>
    )
  );

  useEffect(() => {
    const fetchCoachProfile = async () => {
      try {
        const profileData = await schedulingFetchGet(`coach_profiles/${userId}`);
        setCoachProfile(profileData);
      } catch (error) {
        console.error("Error fetching coach profile:", error);
        toast.error("You don’t have permission to access this page");
        history.push('/');
      }
    };
    fetchCoachProfile();
  }, [userId]);


  useEffect(() => {
    setPricingGroups({ title: "", lessonRates: [], id: "" });
    schedulingFetchGet(`coach_profiles/${userId}/pricing_groups`)
      .then((data) => {
        setPricingGroups(data[0] || { title: "", lessonRates: [] });
      })
      .catch((error) => {
        console.error(error);
      });
  }, [savePricings, userId]);

  const handleAddPricing = (newPricing: LessonRate) => {
    newPricing.active = true;
    if (!checkDefaultRate(pricingGroups.lessonRates)) {
      newPricing.defaultRate = true;
    }
    if(newPricing.defaultRate === true && pricingGroups.lessonRates && pricingGroups.lessonRates.length > 0) {
      pricingGroups.lessonRates.forEach((item) => {
        if(item.defaultRate) {
          item.defaultRate = false;
        }
      });
    }
    const newPricingGroupList = pricingGroups.lessonRates ? [...pricingGroups.lessonRates, newPricing] : [newPricing];
    schedulingFetchPatch(`coach_profiles/${userId}/pricing_groups/${pricingGroups.id}`, {
      ...pricingGroups,
      lessonRates: newPricingGroupList
    }).then(() => {
      setSavePricings(!savePricings);
      setInfoModalOpen(true);
      setInfoPopupContent({
        title: t("Changes saved"),
        status: "success",
        content: t("Booking Option added successfully."),
      });
    }).catch((error) => {
      console.error("Error updating pricing groups:", error);
    });
  };

  const checkDefaultRate = (lessonRates: LessonRate[]) => {
    if (lessonRates === undefined || lessonRates === null) {
      return false;
    }
    if (lessonRates.length === 0) {
      return false;
    }
    const count = lessonRates.filter(rate => rate.defaultRate === true).length;
    if (count === 0) {
      return false;
    }
    return true;
  };

  const handleEditPricing = (updatedItem: LessonRate) => {
    if (!checkDefaultRate(pricingGroups.lessonRates)) {
      updatedItem.defaultRate = true;
    }
    const hasNoDefaultRate = pricingGroups.lessonRates
      .filter(rate => rate.id !== updatedItem.id)
      .filter(rate => rate.defaultRate === true).length === 0;

    if(updatedItem.defaultRate === false && hasNoDefaultRate) {
      updatedItem.defaultRate = true;
    }
    schedulingFetchPatch(`coach_profiles/${userId}/pricing_groups/${pricingGroups.id}`, {
      ...pricingGroups,
      lessonRates: pricingGroups.lessonRates.map((item) =>
        item.id === updatedItem.id
          ? updatedItem
          : { ...item, defaultRate: updatedItem.defaultRate ? false : item.defaultRate }
      )
    }).then(() => {
      setSavePricings(!savePricings);
      setInfoModalOpen(true);
      setInfoPopupContent({
        title: t("Changes saved"),
        status: "success",
        content: t("Booking Option edited successfully."),
      });
    }).catch((error) => {
      console.error("Error updating pricing groups:", error);
    });
  };

  const handleRemovePricing = (removeItem: LessonRate) => {
    removeItem._destroy = true;
    if (removeItem.defaultRate) {
      const index = pricingGroups.lessonRates.findIndex(obj => obj._destroy !== true);
      if (index >= 0) {
        pricingGroups.lessonRates[index].defaultRate = true;
      }
    }
    schedulingFetchPatch(`coach_profiles/${userId}/pricing_groups/${pricingGroups.id}`, {
      ...pricingGroups,
      lessonRates: pricingGroups.lessonRates.map(item =>
        item.id === removeItem.id ? { ...item, defaultRate: false, _destroy: true } : item
      )
    }).then(() => {
      setSavePricings(!savePricings);
      setInfoModalOpen(true);
      setInfoPopupContent({
        title: t("Changes saved"),
        status: "success",
        content: t("Booking Option removed successfully."),
      });
    }).catch((error) => {
      console.error("Error updating pricing groups:", error);
    });
  };

  const handleEditPricingGroup = (newTitle: string) => {
    setPricingGroups((prevPricingGroups) => ({
      ...prevPricingGroups,
      title: newTitle,
    }));
  };

  const onDismiss = () => {
    setDefaultOptionDeactivatedModalOopen(false);
  };

  const onConfirm = () => {
    if (currentItem !== null) {
      currentItem.active = !currentItem.active;
      saveNewStatus(currentItem);
      setCurrentItem(null);
    }
    setDefaultOptionDeactivatedModalOopen(false);
  };

  const handleChangeStatus = (updatedItem: LessonRate) => {
    if (updatedItem !== null) {
      setCurrentItem(updatedItem);
      setConflictModalText((
        <div>
          {t('You deactivated all booking options.')}
          <br/>
          {t('To enable athlete bookings, you must have at least one active booking option.')}
        </div>
      ));
    } else {
      setConflictModalText((
        <div>
          {t('You are deactivating the default booking option.')}
          <br/>
          {t('Please select an active booking option as your default before saving changes.')}
        </div>
      ));
    }
    setDefaultOptionDeactivatedModalOopen(true);
  };

  const saveNewStatus = (updatedItem: LessonRate) => {
    schedulingFetchPatch(`coach_profiles/${userId}/pricing_groups/${pricingGroups.id}`, {
      ...pricingGroups,
      lessonRates: pricingGroups.lessonRates.map((item) =>
        item.id === updatedItem.id ? updatedItem : item
      )
    })
      .then(() => {
        if (updatedItem.active) {
          toast.success("Booking Option activate successfully.");
        } else {
          toast.success("Booking Option deactivate successfully.");
        }
      })
      .catch((error) => {
        console.error("Error updating pricing groups:", error);
      });
  };

  return (
    <>
      <ReturnBar />
      <div>
        <Navbar location={"pricing"} />
        <PagePanel
          title={t("Booking Options")}
          headerChildren={(
            <div className={styles.page_panel}>
              <button
                type="button"
                className={styles.button2}
                onClick={openAddModal}
              >
                <span className={styles.fullText}>+ {t("Add Booking")}</span>
                <span className={styles.shortText}>+ {t("Add")}</span>
              </button>
            </div>
          )}
        >
          <p className={styles.subtitle}>{t('Booking Options represent your coach offerings that can be booked by your athletes.')}</p>
          <p className={styles.subtitle2}>{t('One default booking option is required.')}*</p>
          {Array.isArray(pricingGroups.lessonRates) && pricingGroups.lessonRates.length > 0 ? (
            <PricingView
              list={pricingGroups.lessonRates}
              onEdit={handleEditPricing}
              onRemove={handleRemovePricing}
              titles={pricingGroups.lessonRates.map(pricingGroup => pricingGroup.title)}
              coachCurrencySymbol={coachProfile?.currencySymbol}
              onChangeStatus={handleChangeStatus}
              saveNewStatus={saveNewStatus}
            />
          ) : (
            <div className={styles.no_bookings}>
              <p>{t("You have not created any booking options yet.")}</p>
              <p>{t("You must create booking options to enable athlete booking.")}</p>
            </div>
          )}
        </PagePanel>
        <EditPricingGroup
          isOpen={isEditModalOpen}
          title={pricingGroups.title}
          onClose={closeEditModal}
          onSave={handleEditPricingGroup}
        />
        <AddPricing
          isOpen={isAddModalOpen}
          onClose={closeAddModal}
          onAddPricing={handleAddPricing}
          pricingGroups={Array.isArray(pricingGroups.lessonRates) && pricingGroups.lessonRates.map(pricingGroup => pricingGroup.title)}
          coachCurrencySymbol={coachProfile?.currencySymbol}
        />
        <InfoModal isOpen={isInfoModalOpen} title={infoPopupContent.title} status={infoPopupContent.status} onDismiss={() => setInfoModalOpen(false)}>
          <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
            <div>
              {infoPopupContent.content}
            </div>
            <div>
              <b> {infoPopupContent.subcontent}</b>
            </div>
          </div>
        </InfoModal>
        <ConflictModal isOpen={isDefaultOptionDeactivatedModalOopen} title={'Deactivate Booking'} onConfirm={onConfirm} onDismiss={onDismiss}>
          <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
            {conflictModalText}
          </div>
        </ConflictModal>
      </div>
    </>
  );
}
