import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import classNames from "app/utils/classNames";
import styles from "./styles.module.scss";
import { SearchAllModel } from "app/models/SearchAllModel";
import pdfIcon from "../../../../../public/images/ico_pdf.png";
import docIcon from "../../../../../public/images/ico_doc.png";
import xlsIcon from "../../../../../public/images/ico_xls.png";
import pptIcon from "../../../../../public/images/ico_ppt.png";
import audioIcon from "../../../../../public/images/ico_audio.png";
import TagModel from "app/models/TagModel";

export interface SearchCardPropsDef {
  searchValue: SearchAllModel;
  searchField: string;
}

const fileIcons = {
  docx: docIcon,
  pdf: pdfIcon,
  xlsx: xlsIcon,
  xls: xlsIcon,
  xlxx: xlsIcon,
  pptx: pptIcon,
};

const SearchHighlight = ({
  text,
  result,
}: {
  text: string;
  result: string;
}) => {
  if (!text || !result || text.trim() === "") {
    return <span>{result}</span>;
  }

  const regex = new RegExp(`(${text})`, "gi");
  const parts = result.split(regex);
  return (
    <>
      {parts.map((part: string, index: number) => {
        return regex.test(part) ? (
          <span style={{ backgroundColor: "yellow" }} key={index}>
            {part}
          </span>
        ) : (
          <span>{part}</span>
        );
      })}
    </>
  );
};
const SearchCard = ({ searchValue, searchField }: SearchCardPropsDef) => {
  const renderFileIcon = (extension: any) => {
    const icon = fileIcons[extension];
    return icon ? (
      <img src={icon} alt={`${extension} icon`} className={styles.image} />
    ) : null;
  };

  const renderTags = (tags: TagModel[]) =>
    tags ? (
      <div className={styles.team_name}>
        {/* {tags?.map((tag) => `#${tag}`).join(" ")} */}
        {Array.isArray(searchValue.tags) &&
          searchValue.tags.map((tag) => `#${tag}`).join(" ")}
      </div>
    ) : null;

  const renderContent = () => {
    switch (searchValue?.object) {
      case "team_group":
        return (
          <Link
            to={`/groups/${searchValue?.team?.id}/channels/${searchValue?.id}`}
            className={styles.searchCard}
          >
            <img
              src={searchValue?.team?.logo}
              alt="Team Logo"
              className={classNames(styles.image, styles.circle)}
            />
            <div className={styles.searchCardInfo}>
              <h2>
                <div className={styles.name}>
                  {
                    (
                      <SearchHighlight
                        result={searchValue?.name}
                        text={searchField}
                      />
                    )
                  }
                </div>
                {searchValue?.team && (
                  <div className={styles.team_name}>
                    <SearchHighlight
                      result={searchValue?.team?.name}
                      text={searchField}
                    />
                    {renderTags(searchValue?.tags)}
                  </div>
                )}
              </h2>
            </div>
          </Link>
        );

      case "note":
        return (
          <Link to={`/posts/${searchValue?.id}`} className={styles.searchCard}>
            <div className={styles.note}>Aa</div>
            <div className={styles.searchCardInfo}>
              <h2>
                <div className={styles.name}>
                  <SearchHighlight
                    result={searchValue?.details}
                    text={searchField}
                  />
                </div>
                <div className={styles.team_name}>
                  <SearchHighlight
                    result={searchValue?.details}
                    text={searchField}
                  />
                </div>
                {renderTags(searchValue?.tags)}
              </h2>
            </div>
          </Link>
        );

      case "datafile":
        return (
          <Link to={`/posts/${searchValue.id}`} className={styles.searchCard}>
            {renderFileIcon(searchValue.datafile.extension)}
            <div className={styles.searchCardInfo}>
              <h2>
                <div className={styles.name}>
                  <SearchHighlight
                    result={searchValue?.details}
                    text={searchField}
                  />
                </div>
                <div className={styles.team_name}>
                  <SearchHighlight
                    result={searchValue?.details}
                    text={searchField}
                  />
                </div>
                {renderTags(searchValue.tags)}earchValue.tags
              </h2>
            </div>
          </Link>
        );

      case "audio":
        return (
          <div className={styles.searchCard}>
            <img src={audioIcon} alt="Audio icon" className={styles.image} />
            <div className={styles.searchCardInfo}>
              <h2>
                <div className={styles.name}>
                  <SearchHighlight
                    result={searchValue?.details}
                    text={searchField}
                  />
                </div>
                <div className={styles.team_name}>
                  <SearchHighlight
                    result={searchValue?.details}
                    text={searchField}
                  />
                </div>
                {renderTags(searchValue.tags)}
              </h2>
            </div>
          </div>
        );

      case "video":
        return (
          <div className={styles.searchCard}>
            <img
              src={searchValue.video.smallThumbnailUrl}
              alt="Video thumbnail"
              className={classNames(styles.image, styles.radius)}
              onClick={() =>
                (window.location.href = `/posts/${searchValue.id}`)
              }
            />
            <div className={styles.searchCardInfo}>
              <h2>
                <Link to={`/posts/${searchValue.id}`} className={styles.name}>
                  {searchValue.details}
                  {renderTags(searchValue.tags)}
                </Link>
                <div className={styles.team_name}>
                  <SearchHighlight
                    result={searchValue?.details}
                    text={searchField}
                  />
                </div>
              </h2>
            </div>
          </div>
        );

      case "image":
        return (
          <div className={styles.searchCard}>
            <img
              src={searchValue.image.smallUrl}
              alt="Image thumbnail"
              className={classNames(styles.image, styles.radius)}
            />
            <div className={styles.searchCardInfo}>
              <h2>
                <Link to={`/posts/${searchValue.id}`} className={styles.name}>
                  {searchValue.details}
                  {searchValue?.tags && renderTags(searchValue.tags)}
                </Link>
                <div className={styles.team_name}>
                  <SearchHighlight
                    result={searchValue?.details}
                    text={searchField}
                  />
                </div>
              </h2>
            </div>
          </div>
        );

      case "athlete_space":
        return (
          <Link to={`/spaces/${searchValue?.id}`} className={styles.searchCard}>
            <img
              src={searchValue?.athleteAvatar}
              alt="Space Logo"
              className={classNames(styles.image, styles.circle)}
            />
            <div className={styles.searchCardInfo}>
              <h2>
                <div className={styles.name}>
                  <SearchHighlight
                    result={searchValue?.athleteName}
                    text={searchField}
                  />
                </div>
                <div className={styles.team_name}>
                  <SearchHighlight
                    result={searchValue?.name}
                    text={searchField}
                  />
                </div>
              </h2>
            </div>
          </Link>
        );

      case "team_space":
        return (
          <Link to={`/groups/${searchValue?.id}`} className={styles.searchCard}>
            <img
              src={searchValue?.logo}
              alt="Team Space Logo"
              className={classNames(styles.image, styles.circle)}
            />
            <div className={styles.searchCardInfo}>
              <h2>
                <div className={styles.name}>
                  <SearchHighlight
                    result={searchValue?.name}
                    text={searchField}
                  />
                </div>
                <div className={styles.team_name}>
                  <SearchHighlight
                    result={searchValue?.sport}
                    text={searchField}
                  />
                </div>
              </h2>
            </div>
          </Link>
        );

       case "team":
        return (
          <Link to={`/groups/${searchValue?.id}`} className={styles.searchCard}>
            <img
              src={searchValue?.logo}
              alt="Team Logo"
              className={classNames(styles.image, styles.circle)}
            />
            <div className={styles.searchCardInfo}>
              <h2>
                <div className={styles.name}>
                  <SearchHighlight
                    result={searchValue?.name}
                    text={searchField}
                  />
                </div>
                <div className={styles.team_name}>
                  <SearchHighlight
                    result={searchValue?.sport}
                    text={searchField}
                  />
                </div>
              </h2>
            </div>
          </Link>
        );

      default:
        return null;
    }
  };

  return useMemo(() => renderContent(), [searchValue]);
};

export { SearchCard };
