/**
 * @module Components.StripeForm
 *
 */
import React from "react";
import StartFreeTrialFormElementContainer, {
  PropsDef,
} from "./StartFreeTrialFormElementContainer";
import { CardCVCElement } from "react-stripe-elements";
import { useTranslation } from "react-i18next";

const CardCVC = (props: PropsDef) => {
  const { t } = useTranslation();

  return (
    <StartFreeTrialFormElementContainer
      elementType={CardCVCElement}
      placeholder={{
        id: "placeholder.form.security-code",
        defaultMessage: t("Security Code"),
      }}
      {...props}
    />
  );
};

export default CardCVC;
