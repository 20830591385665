import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import UserAvatar from "app/components/UserAvatar";
import { useTranslation } from "react-i18next";
import ContactInformation from "./ContactInformation";
import ButtonVariant from "app/components/Button/ButtonVariant";
import { cls } from "app/utils";
import {
  fetchCoachProfile,
  commonLocations,
} from "app/actions/coachNowCoachProfile";
import { useHistory, useParams } from "react-router-dom";
import { useActions } from "app/utils/hooks";
import { Loading } from "app/components/Wrappers";
import { CoachProfileModel } from "app/models";
import SpaceCard from "app/components/SpaceCard";

const CoachProfileInfo = () => {
  const { t } = useTranslation();
  const { coachId } = useParams<{ coachId: string }>();
  const history = useHistory();

  const coachActions = useActions({ fetchCoachProfile, commonLocations });

  const [coachData, setCoachData] = useState<CoachProfileModel>({});
  const [commonData, setCommonData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchProfileData = async () => {
      setLoading(true);
      try {
        const resp = await coachActions.fetchCoachProfile(coachId);
        setCoachData(resp);
      } catch (err) {
        history.push(`/404`);
      }
      setLoading(false);
    };

    const fetchConnection = async () => {
      try {
        const resp = await coachActions.commonLocations();
        setCommonData(resp);
      } catch (err) {
        throw err;
      }
    };

    Promise.all([fetchProfileData(), fetchConnection()]);
  }, [coachId, coachActions]);

  const onClickBookSession = () => {
    window.open(coachData?.bookingServiceLink, "_blank");
  };

  const onClickPayment = () => {
    window.open(coachData?.paymentServiceLink, "_blank");
  };

  if (loading) {
    return (
      <Loading
        isLoading={loading}
        loadType="spinner"
        className={styles.loading_container}
      />
    );
  }

  return (
    <>
      <section className={styles.profile_container}>
        {coachData?.coverUrl && (
          <div className={styles.cover_container}>
            <img
              src={coachData?.coverUrl}
              alt="cover"
              className={styles.cover_image}
            />
          </div>
        )}
        <div
          className={cls(styles.avatar_container, {
            [styles.avatar_without_cover]: !coachData?.coverUrl,
          })}
        >
          <UserAvatar
            userName={`${coachData?.firstName} ${coachData?.lastName}`}
            srcName={null as string}
            altName="cover"
            className={styles.avatar}
          />
          <span
            className={cls(styles.user_info_wrapper, {
              [styles.user_info_without_cover]: !coachData?.coverUrl,
            })}
          >
            {coachData?.firstName && (
              <p
                className={styles.user_name}
              >{`${coachData.firstName} ${coachData.lastName}`}</p>
            )}
            {coachData?.sport && (
              <p className={styles.sport}>{coachData.sport}</p>
            )}
          </span>
        </div>
      </section>
      <section
        className={cls(styles.about_container, {
          [styles.null_section]: !coachData?.about,
        })}
      >
        <div className={styles.about_container_content}>
          {coachData?.about && (
            <div
              className={cls(styles.about_wrapper, {
                [styles.about_container_without_cover]: !coachData?.coverUrl,
              })}
            >
              <label className={styles.about_title}>{t("About")}</label>
              <p className={styles.about_info}>{coachData?.about}</p>
            </div>
          )}
          {commonData.length > 0 && (
            <div className={styles.same_space_connections}>
              <p>{t("Spaces and Groups you’re both members of")}</p>
              <div className={styles.same_spaces_list}>
                {commonData.map((item) => {
                  return (
                    <SpaceCard
                      key={item.id}
                      className={styles.space_card}
                      spaceData={item}
                      imageWidth={80}
                      imageHeight={80}
                    />
                  );
                })}
              </div>
            </div>
          )}
        </div>

        <div
          className={cls(styles.book_session_container, {
            [styles.book_sessions_without_cover]: !coachData?.coverUrl,
          })}
        >
          <span className={styles.buttons_container}>
            {coachData?.bookingServiceLink && (
              <ButtonVariant
                title={t("Book a session")}
                buttonType="primary"
                buttonStyles={styles.book_content_btn}
                onClickFunc={onClickBookSession}
              />
            )}

            {coachData?.paymentServiceLink && (
              <ButtonVariant
                title={t("Make a payment")}
                buttonType="secondary-outline"
                buttonStyles={cls(
                  styles.book_content_btn,
                  styles.make_a_payment_btn,
                )}
                onClickFunc={onClickPayment}
              />
            )}
          </span>
          {(coachData?.bookingServiceLink || coachData?.paymentServiceLink) && (
            <hr className={styles.divider} />
          )}
          <div className={styles.contact_info_container}>
            <ContactInformation coachData={coachData} />
          </div>
        </div>
      </section>
    </>
  );
};

export default CoachProfileInfo;
