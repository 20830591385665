import React from "react";
import styles from "./styles.module.scss";
import coachnow_logo from "app/images/Logo_dark.svg";
import pro_badge from "app/images/pro_dark_bg.svg";
import orange_check from "app/images/orange_check.svg";
import orange_bg_check from "app/images/orange_bg_checkmark.svg";
import { useTranslation } from "react-i18next";

interface IProps {
  price: string;
}

type checkedListType = {
  label: string;
};

type TrialIncludedItemTypes = {
  description: string;
};

const PriceCard = ({ price }: IProps) => {
  const { t } = useTranslation();
  const checkedList: checkedListType[] = [
    {
      label: t("Full Video/ Image Analysis Suite with 300GB Cloud Library"),
    },
    {
      label: t("Unlimited Athletes, Spaces, Groups"),
    },
    {
      label: t("View Tracking, Read Receipts, Smart Lists"),
    },
    {
      label: t("Automated Templates, Post Scheduler"),
    },
  ];

  return (
    <div className={styles.pricing_card}>
      <img
        src={orange_bg_check}
        alt="checked plan"
        className={styles.plan_checkmark}
      />
      <div className={styles.header}>
        <div className={styles.header_wrapper}>
          <div className={styles.header_logo_wrapper}>
            <img
              src={coachnow_logo}
              alt="CoachNow Logo"
              className={styles.site_logo}
            />
            <img src={pro_badge} alt="Pro Logo" className={styles.pro_badge} />
          </div>
          <div className={styles.header_pricing}>
            <div className={styles.price_wrapper}>
              <p className={styles.price}>{price}</p>
              <p className={styles.price_date}>&nbsp;/&nbsp;{t("year")}</p>
            </div>
            <p className={styles.billed_by}>{t("Billed annually")}</p>
          </div>
        </div>
      </div>
      <hr className={styles.divider} />
      <div className={styles.body}>
        <div className={styles.checked_items_list}>
          {checkedList.map((item: checkedListType) => {
            return (
              <div key={item.label} className={styles.checkd_item}>
                <TrialIncludedItem description={item.label} />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const TrialIncludedItem = ({ description }: TrialIncludedItemTypes) => {
  return (
    <div className={styles.included_item}>
      <img src={orange_check} alt="Checkmark" className={styles.checkmark} />
      <p className={styles.checked_description}>{description}</p>
    </div>
  );
};

export default PriceCard;
