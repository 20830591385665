/**
 * main react app loader
 *
 */
import React, { useEffect } from "react";
import { hot } from "react-hot-loader/root";
import { useLocation } from "react-router-dom";
import styles from "./styles/app.module.scss";
import Router from "./Router";
import { AuthService } from "app/services";
import { useSelector, useStoreActions } from "./utils/hooks";
import { fetchSelf } from "./actions/user";
import { usePostViewsTracking } from "./utils/hooks/usePostViewsTracking";
import { GoogleOAuthProvider } from "@react-oauth/google";
import settings from "config/environment";
import { RootState } from "typedefs";
import { shallowEqual } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  UserContextType,
  UserContext,
  RoleType,
} from "app/contexts/UserContext";
/***/
const Auth: any = AuthService;
const POST_VIEWS_POLL_INTERVAL = 5000; // 5 seconds

/**
 * Application Class
 */
function App() {
  const location = useLocation();
  const { sendAndClearPostViews } = usePostViewsTracking();
  const user = useSelector((state: RootState) => state.user, shallowEqual);
  const golfGeniusId = user.data?.golfgeniusId;

  // This identifies user for analytics.
  useEffect(() => {
    if (location.pathname !== "/signin") {
      Auth.loadAuthUser();
    }
  }, [Auth.isAuthenticated, golfGeniusId]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      sendAndClearPostViews();
    }, POST_VIEWS_POLL_INTERVAL);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useStoreActions({ user: fetchSelf });

  const { self } = useSelector((state: RootState) => state.user);
  const currentUserRole: UserContextType = {};

  if (self) {
    currentUserRole.id = self.id;
    currentUserRole.kind = self.kind;
    currentUserRole.features = self.features;

    if (self.facilityMemberships && self.facilityMemberships.length > 0) {
      const currentFacilityMembership = self.facilityMemberships[0];
      const isAdminRole = currentFacilityMembership.role === RoleType.ADMIN;
      const isCoachAdminRole =
        currentFacilityMembership.role === RoleType.COACH &&
        currentFacilityMembership.isAdmin;
      currentUserRole.role = currentFacilityMembership.role;
      currentUserRole.facilityId = currentFacilityMembership.facilityId;
      currentUserRole.facility = self.scheduling?.facility;
      currentUserRole.facilityMembershipId = currentFacilityMembership.id;
      currentUserRole.isAdmin = currentFacilityMembership.isAdmin;

      currentUserRole.behavesLikeAdmin = isAdminRole || isCoachAdminRole;
    } else {
      currentUserRole.role = self.kind as RoleType;
      currentUserRole.isAdmin = false;
      currentUserRole.behavesLikeAdmin = false;
    }
  }

  return (
    <div className={styles.app}>
      <GoogleOAuthProvider clientId={settings.GOOGLE_CLIENT_ID}>
        <UserContext.Provider value={currentUserRole}>
          <Router />
        </UserContext.Provider>
      </GoogleOAuthProvider>
      <ToastContainer />
    </div>
  );
}

export default hot(App);
