/**
 * @module Components.StripeForm
 *
 */
import React from "react";
import { MessageDescriptor } from "react-intl";
import { ReactStripeElements } from "react-stripe-elements";
import FormItem from "app/components/inputs/FormItem";
import styles from "../styles.module.scss";
import { useTranslation } from "react-i18next";

type ElResponse = ReactStripeElements.ElementChangeResponse & {
  target?: EventTarget;
};

type PropsDef = {
  value?: string;
  placeholder?: MessageDescriptor;
  onChange?: (e: ElResponse) => void;
  onFocus?: (e: ElResponse) => void;
  onBlur?: (e: ElResponse) => void;
  error?: MessageDescriptor | string | null;
  disabled?: boolean;
  readOnly?: boolean;
};

export default function AddressLinetwo(props: PropsDef) {
  const { t } = useTranslation();
  const modifyEvent = (
    e: React.FormEvent<HTMLInputElement>,
    callback: (e: ElResponse) => void,
  ) => {
    if (callback) {
      callback({
        elementType: "addressLineTwo",
        complete: true,
        empty: false,
        brand: "Unknown",
        value: e.currentTarget.value,
        target: e.currentTarget,
      });
    }
  };

  return (
    <FormItem className={styles.formControl} error={props.error}>
      <input
        onChange={(e) => modifyEvent(e, props.onChange)}
        onFocus={(e) => modifyEvent(e, props.onFocus)}
        onBlur={(e) => modifyEvent(e, props.onBlur)}
        placeholder={t("Address Line 2")}
        value={props.value}
        disabled={props.disabled}
        readOnly={props.readOnly}
      />
    </FormItem>
  );
}
