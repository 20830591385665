import Select, { components } from "react-select";
import React from "react";
import { SelectStyles } from "app/routes/Calendar/EventForm/constants";
import { useTranslation } from "react-i18next";

interface OptionProps {
  value: string | number;
  label: string;
}

interface SelectBoxProps {
  options: OptionProps[];
  value: OptionProps;
  setValue: any;
  noOptionsText?: string;
  ariaLabel?: string | null;
  containerStyle?: string | null;
  customControl?: any;
  customColor?: string;
  customWidth?: string;
  isDisabled?: boolean;
}

const SelectBox = ({
  options,
  value,
  setValue,
  noOptionsText,
  ariaLabel = null,
  containerStyle = null,
  customColor = null,
  customWidth = "",
  isDisabled = false,
}: SelectBoxProps) => {
  const { t } = useTranslation();

  const customOptions = {
    ...SelectStyles,
    menuPortal: (base) => ({
      ...base,
      zIndex: 9999,
    }),
    control: (base) => ({
      ...base,
      borderColor: customColor || "#ccc",
      width: customWidth,
    }),
    valueContainer: (baseStyles) => ({
      ...baseStyles,
      height: 42,
      color: '#000',
      overflow: 'visible',
    }),
  };
  const NoOptionsMessage = (props) => {
    return (
      <components.NoOptionsMessage {...props}>
        <span>{noOptionsText || t("No options")}</span>
      </components.NoOptionsMessage>
    );
  };

  const CustomMenu = (props) => {
    return (
      <div className="time-range-menu-dropdown">
        <components.Menu {...props}>
          {props.children}
        </components.Menu>
      </div>
    );
  };

  return (
    <div className={`${containerStyle}`}>
      <Select
        isDisabled={isDisabled}
        menuPortalTarget={document.body}
        placeholder={t("Select")}
        styles={customOptions}
        value={value}
        onChange={setValue}
        aria-label={ariaLabel}
        components={{
          IndicatorSeparator: () => null,
          NoOptionsMessage,
          Menu: CustomMenu,
        }}
        options={options}
        menuPlacement="auto"
      />
    </div>
  );
};

export default SelectBox;
