import reactDom from "react-dom";
import styles from "./styles.module.scss";
import React from "react";
import xIco from "../../../../public/images/close.svg";
import { useTranslation } from "react-i18next";

const InformationModal = ({
  children = null,
  title,
  subtitle = null,
  handleAction,
  confirmText = null,
  backgroundColor = "white",
  width = "385px",
  showCloseIcon = false,
  topImage = null,
}) => {
  const { t } = useTranslation();

  const confirmButton = (
    <button
      data-testid="save-edit"
      form="form-details"
      className={styles.confirm_long_button}
      type="submit"
      onClick={handleAction}
    >
      {confirmText || t("Ok")}
    </button>
  );

  const modal = (
    <div className={`${styles.wrapper} modal`}>
      <div className={styles.modal} style={{ width }}>
        <div className={topImage ? styles.image_header : styles.header} style={{ backgroundColor }}>
          <div className={styles.close_container}>
            {showCloseIcon && (
              <img
                className={styles.clickable_image}
                src={xIco}
                alt="envelope"
                id="repeat-indicator"
                onClick={handleAction}
              />
            )}
          </div>
        </div>
        <div className={styles.body}>
          {topImage && <img src={topImage} width={40} height={40} />}

          {title && <h3>{title}</h3>}
          {subtitle && <p>{subtitle}</p>}
        </div>
        {children}
        <div className={styles.footer}>
          <div className={styles.buttons_container}>{confirmButton}</div>
        </div>
      </div>
    </div>
  );
  return reactDom.createPortal(modal, document.body);
};

export default InformationModal;
