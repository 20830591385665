import React, { useState, useRef } from "react";
import UserAvatar from "../UserAvatar";
import { useDropzone } from "react-dropzone";
import styles from "./styles.module.scss";
import upload from "app/utils/uploader";
import { cls } from "app/utils";
import EditPenIcon from "../../../public/images/edit-pen.svg";
import { Loading } from "../Wrappers";

interface Props {
  userName?: string;
  avatar?: string;
  onUpload?: (avatar: any) => void;
  canEdit?: boolean;
}

const AvatarWithEdit = ({
  userName,
  avatar,
  onUpload,
  canEdit = true,
}: Props) => {
  const [isLoading, setIsLoading] = useState(false);

  const uploadRef = useRef(null);

  const onDrop = async (acceptedFiles: File[]) => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];

      try {
        setIsLoading(true);
        const abortableUpload = upload(file, file.name, file.type, undefined);
        uploadRef.current = abortableUpload;
        const response = await abortableUpload.promise;
        setIsLoading(false);
        return onUpload(response);
      } catch (error) {
        console.error("Error during file upload:", error);
        setIsLoading(false);
      }
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    // noClick: true,
    accept: {
      "image/*": [],
    },
  });
  return (
    <div className={cls(styles.container)}>
      <div className={styles.dropzone_inner}>
        {canEdit && (
          <div {...getRootProps({ className: "dropzone" })}>
            <span
              className={cls(styles.edit_pen_wrapper, {
                [styles.dropzone_inner_active]: isDragActive,
              })}
            >
              {<img className={styles.edit_pen} src={EditPenIcon} />}
              <input {...getInputProps()} id="media__uploader" />
            </span>
          </div>
        )}
        {isLoading && (
          <Loading
            isLoading={isLoading}
            loadType="spinner"
            className={styles.loader}
          />
        )}

        {!isLoading && (
          <UserAvatar
            srcName={avatar}
            userName={userName}
            sizeWidth={100}
            sizeHeight={100}
            className={cls(styles.avatar, {
              [styles.dropzone_inner_active]: isDragActive,
            })}
          />
        )}
      </div>
    </div>
  );
};

export default AvatarWithEdit;
