import React from "react";

import styles from "./styles.module.scss";

type PropsDef = {
  event: any;
  timeText: string;
};

export default function LessonSchedule(props: PropsDef) {
  const { startTimeString, durationInMinutes, eventColor } = props.event.extendedProps;

  const leftBarStyle = eventColor ? { backgroundColor: eventColor } : {};

  return (
    <div className={`${styles.event_container} ${durationInMinutes < 30 ? styles.small_event : ''}`}>
      <div
        className={styles.lesson_schedule_bar}
        style={leftBarStyle}
      />
      <div className={styles.lesson_schedule_event}>
        <div className={styles.event_title}>{props.event.title}</div>
        <div className={styles.event_time}>{startTimeString}</div>
      </div>
    </div>
  );
}
