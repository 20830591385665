import SpaceModel from "app/models/SpaceModel";
import { cls } from "app/utils";
import React from "react";
import UserAvatar from "../UserAvatar";
import styles from "./styles.module.scss";

interface Props {
  spaceData: SpaceModel;
  imageWidth: number;
  imageHeight: number;
  className: string;
}

const SpaceCard = ({
  spaceData,
  imageWidth = 100,
  imageHeight = 100,
  className,
}: Props) => {
  return (
    <div className={cls(styles.container, className)}>
      <span className={styles.avatar_wrapper}>
        <UserAvatar
          srcName={spaceData?.avatar}
          userName={spaceData?.athleteName}
          sizeWidth={imageWidth}
          sizeHeight={imageHeight}
          className={styles.avatar}
        />
      </span>
      <div className={styles.space_info}>
        <p className={styles.athlete_name}>
          <b>{spaceData?.athleteName}</b>
        </p>
        <p className={styles.sport}>{spaceData?.sport}</p>
      </div>
    </div>
  );
};

export default SpaceCard;
