import React from "react";
import styles from "../../../routes/ViewProgram/styles.module.scss";
import PhoneIco from "app/images/phone.svg";
import EmailIco from "app/images/mail.svg";
import UserAvatar from "../UserAvatar";
import SquaredColorOption from "../SquaredColorOption";
import { useTranslation } from "react-i18next";

const GroupDetails = ({ program }) => {
  const { t } = useTranslation();

  const tagsColor = {
    background: "#F4F4F4",
    text: "#000000",
    hasBorder: false,
  };

  return (
    <div className={styles.groupDetailsContainer}>
      <div style={{ width: "45%" }}>
        {program?.tags?.length > 0 && (
          <div className={styles.tagContainer}>
            {program?.tags?.map((tag, index) => (
              <div key={index} className={styles.tagItem}>
                <SquaredColorOption
                  color={tagsColor}
                  text={tag?.name}
                />
              </div>
            ))}
          </div>
        )}
        {program?.longDescription.split("\n").map((item, index) => {
          return (
            <p key={index} className={styles.longDescription}>
              {item}
            </p>
          );
        })}
      </div>
      <div className={styles.detailsContainer}>
        <p className={styles.detailsHeaderCoach}>{t("Coaches")}</p>
        <div className={styles.column}>
          {program?.coaches?.map((coach, index) => (
            <div className={styles.customGroupCard} key={index}>
              <div className={styles.customGroupCardContent}>
                <UserAvatar
                  src={coach?.profilePicture}
                  height={30}
                  font_size={12}
                  width={30}
                  first_name={coach?.firstName}
                  last_name={coach?.lastName}
                />
                <p className={styles.customGroupCardDay}>
                  {coach?.firstName} {coach?.lastName}
                </p>
              </div>
            </div>
          ))}
        </div>
        <div>
          <p className={styles.detailsHeader}>{t("Contact Info")}</p>
          <div style={{ marginTop: "16px" }}>
            <div className={styles.cardInfoItem}>
              <span>
                <img src={EmailIco} />
              </span>
              <span className={styles.distanceTextWithIcon}>
                {program?.email}
              </span>
            </div>
          </div>
          <div style={{ marginTop: "16px" }}>
            <div className={styles.cardInfoItem}>
              <span>
                <img src={PhoneIco} />
              </span>
              <span className={styles.distanceTextWithIcon}>
                {program?.phone}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GroupDetails;
