import React from "react";
import styles from "./styles.module.scss";
import ModalLayout from "app/components/Layouts/ModalLayout/ModalLayout";
import closeIcon from "app/images/close-icon.svg";
import { cls } from "app/utils";
import { useEditMemberModal } from "./indexModel";
import UserModel from "app/models/UserModel";
import UserAvatar from "app/components/UserAvatar";
import { useTranslation } from "react-i18next";

interface Props {
  member: UserModel;
  isOpen?: boolean;
  onClose?: () => void;
  onMemberUpdate?: (member: UserModel) => void;
}

const EditMemberModal: React.FC<Props> = ({
  member,
  isOpen,
  onClose,
  onMemberUpdate,
}: Props) => {
  const { t } = useTranslation();
  const {
    memberSettingsData,
    memberData,
    handleNameChange,
    handleNicknameChange,
    handleEmailChange,
    typeHandler,
    onSave,
  } = useEditMemberModal({ member, onMemberUpdate });

  return (
    <ModalLayout isOpen={isOpen}>
      <div className={styles.container}>
        <div className={styles.member_container}>
          <div className={styles.title_container}>
            <h1>{t("Edit Group Member")}</h1>
            <img
              src={closeIcon}
              alt="close"
              className={styles.close_icon}
              onClick={onClose}
            />
          </div>
          <div className={styles.form_wrapper}>
            <div className={styles.content_wrapper}>
              <UserAvatar
                altName="user"
                className={styles.user_avatar}
                srcName={member.avatar as string}
                sizeHeight={180}
                sizeWidth={180}
                userName={member.name}
              />
              <div className={styles.input_list_wrapper}>
                <div className={styles.input_wrapper}>
                  <label htmlFor="name">{t("Name")}</label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    required
                    disabled={member.status !== "incomplete"}
                    onChange={handleNameChange}
                    defaultValue={memberData.name}
                  />
                </div>

                <div className={styles.input_wrapper}>
                  <label htmlFor="email">{t("Email")}</label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    required
                    disabled={member.status !== "incomplete"}
                    onChange={handleEmailChange}
                    defaultValue={memberData.email}
                  />
                </div>

                <div className={styles.input_wrapper}>
                  <label htmlFor="nickname">{t("NickName")}</label>
                  <input
                    type="text"
                    name="nickname"
                    id="nickname"
                    required
                    onChange={handleNicknameChange}
                    defaultValue={memberData.nickname}
                  />
                </div>

                <ul className={cls("stack-list-items")}>
                  {memberSettingsData.map((item) => (
                    <li
                      key={item.type}
                      className={cls("stack-list-item selectable-item", {
                        [styles.disabled]: memberData.type === item.type,
                        ["active"]: memberData.type === item.type,
                      })}
                    >
                      <label
                        className="selectable-item-trigger"
                        htmlFor={item.label}
                        onClick={() => typeHandler(item.type)}
                      ></label>
                      <div className="selectable-item-control">
                        <input
                          id={item.label}
                          name="type"
                          type="radio"
                          value={item.type}
                          checked={memberData.type === item.type}
                        />
                      </div>
                      <div className="selectable-item-details">
                        <div className="selectable-item-label">
                          {item.label}
                        </div>
                        <div className="selectable-item-summary">
                          {item.description}
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>

          <div className={styles.buttons}>
            <button
              className={cls("btn btn-outline-secondary", styles.button)}
              onClick={onClose}
            >
              {t("Cancel")}
            </button>
            <button
              disabled={!memberData.name}
              className={cls("btn btn-primary", styles.button, {
                ["disabled"]:
                  !memberData.name || memberData.name.trim().length === 0,
              })}
              onClick={onSave}
            >
              {t("Save")}
            </button>
          </div>
        </div>
      </div>
    </ModalLayout>
  );
};

export default EditMemberModal;
