import React, { memo } from "react";
import styles from "./styles.module.scss";
import { TeamsTimelineModel } from "./indexModel";
import GroupPageLayout from "app/components/Layouts/GroupPageLayout";
import TeamsPostingForm from "../TeamsPostingForm";
import TeamsTabsContainer from "../TeamsTabsContainer";
import GroupsPostsContainer from "app/components/GroupsPostsContainer";
import Filter from "app/components/Filter";
import PostReportModal from "app/components/Post/InnerPost/PostReportModal";
import GroupInviteModal from "app/routes/Groups/_components/GroupInviteModal";
import CreatedScheduledPostModal from "app/components/CreatedScheduledPostModal";

const TeamsTimeline = () => {
  const {
    selectedTeam,
    data,
    scheduledPostsData,
    onPost,
    pending,
    reportModal,
    reportModalClose,
    onReportHandler,
    onPostReportClick,
    onPostDelete,
    onPostUpdate,
    onPostPin,
    sentryRef,
    filterHandler,
    onClearFilters,
    modalInviteIsOpen,
    canSeePostviews,
    canSeeWhoLiked,
    canPost,
    newScehduleModalOpen,
    handleNewSchedulePost,
    scheduledAt,
    canViewMembers,
  } = TeamsTimelineModel();

  return (
    <GroupPageLayout>
      {selectedTeam && (
        <div>
          <div className={styles.group_tabs_container}>
            <TeamsTabsContainer />
          </div>
          {canPost && <TeamsPostingForm onPost={onPost} />}
          <div className={styles.dropdown_container}>
            <hr />
            <Filter
              filterClickHandler={filterHandler}
              onFilterClear={onClearFilters}
              hideAuthors={!canViewMembers()}
            />
          </div>
          <GroupsPostsContainer
            scheduledPostsData={scheduledPostsData}
            displayPostLocations={false}
            hidePinBadge={false}
            onPostDelete={onPostDelete}
            onPostUpdate={onPostUpdate}
            onPostPin={onPostPin}
            containerRef={sentryRef}
            onReport={onPostReportClick}
            posts={data}
            pending={pending}
            canSeePostViews={canSeePostviews}
            canSeeWhoLiked={canSeeWhoLiked}
          />
        </div>
      )}
      {modalInviteIsOpen && selectedTeam?.name && (
        <GroupInviteModal defaultModalContent="channel-invite-members" />
      )}
      {reportModal.isOpen && (
        <PostReportModal
          isModalOpen={reportModal.isOpen}
          modalHandler={reportModalClose}
          reportHandler={onReportHandler}
        />
      )}
      {newScehduleModalOpen && (
        <CreatedScheduledPostModal
          isOpen={newScehduleModalOpen}
          onClose={handleNewSchedulePost}
          redirectUrl={"/schedule"}
          scheduledAt={scheduledAt}
          location={"Channel"}
        />
      )}
      {newScehduleModalOpen && (
        <CreatedScheduledPostModal
          isOpen={newScehduleModalOpen}
          onClose={handleNewSchedulePost}
          redirectUrl={"/schedule"}
          scheduledAt={scheduledAt}
          location={"Channel"}
        />
      )}
    </GroupPageLayout>
  );
};

export default memo(TeamsTimeline);
