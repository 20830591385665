/**
 * @module Components.Routes
 *
 */
import React, { useState, useEffect, useRef, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DateTime, DateTimeFormatOptions } from "luxon";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import interactionPlugin from "@fullcalendar/interaction"; // for selectable
import luxonPlugin from "@fullcalendar/luxon3";
import { capitalize, some, compact } from "lodash";
import { RootState } from "typedefs";
import { useTranslation } from "react-i18next";
/* import { useActions } from "app/utils/hooks"; */
import { DATE_FULL_WEEKDAY, DATETIME_FULL_WEEKDAY } from "app/utils/luxonFormats";
import { UserContext, RoleType } from 'app/contexts/UserContext';
import config from 'config/environment';

/* import useInterval from "app/utils/hooks/useInterval"; */
import { Loading } from "app/components/Wrappers";
import { useEventsModel } from "./indexModel";

import useAvailablity from "./hooks/useAvailability";
import useConfirmation from "app/routes/Calendar/hooks/useConfirmation";
import useCurrentFacility from 'app/utils/hooks/scheduling/useCurrentFacility';

import {
  setSelectedEvent,
  deleteEvent,
  initializeNewEvent,
  createEvent,
  editEvent,
  startEdit,
  deselectEvent,
  hideInfoPopup,
    /* fetchEvents, */
} from "app/actions/events";
import { EventModel, EventType } from "app/models/EventModel";
import { CoachProfileModel } from "app/models/scheduling/CoachProfileModel";
import { AvailabilityModel } from "app/models/scheduling/AvailabilityModel";

import Event from "app/routes/Calendar/Event";
import EventInfo from "app/routes/Calendar/EventInfo";
import CalendarToolbar from "app/routes/Calendar/CalendarToolbar";
import EventForm from "./EventForm";
import InfoModal from "app/routes/Calendar/InfoModal";
import ConfirmationPrompt from "app/routes/Calendar/ConfirmationPrompt";
import CoachInfo from "app/routes/Sessions/SessionForm/CoachInfo";

import styles from "./styles.module.scss";

const mediaWatcher = window.matchMedia("(max-width: 820px)");

export default function FacilityCalendar() {
  const { t } = useTranslation();
  const calendarRef = useRef(null);
  const eventElRef = useRef(null);
  const dispatch = useDispatch();
  const userId = useSelector((state: RootState) => state.user.self.id);
  const user = useContext(UserContext);

  const calendarView = mediaWatcher.matches ? "timeGridNarrow" : "timeGridWeek";
  const [calendarDate, setCalendarDate] = useState<DateTime>(DateTime.now());
  const [selectedCoach, setSelectedCoach] = useState<CoachProfileModel>(null);
  const [withCoachSelection, setWithCoachSelection] = useState<boolean>(false);
    /* const eventActions = useActions({
  *   fetchEvents,
  * }); */


  // Used as onUnmount
  useEffect(() => {
    return () => {
      dispatch(deselectEvent());
    };
  }, []);

  const currentFacility = useCurrentFacility();
  let coachProfileResources = [];
  if (currentFacility.data?.id) {
    coachProfileResources = currentFacility.data.coachProfiles.map(coach => {
      return {
        id: coach.id,
        title: coach.fullName,
        profilePpicture: coach.profilePicture,
      };
    });
  }

  const { events } = useEventsModel(
    calendarDate.startOf('day'),
    calendarDate.endOf('day'),
  );

  const { availability, loading: loadingAvailability } = useAvailablity(
    calendarDate.startOf('day'),
    calendarDate.endOf('day'),
  );

  if (availability.length > 0 && !loadingAvailability ) {
    coachProfileResources = coachProfileResources.map(coach => {
      const avs = availability.filter(av => av.coachProfileId === coach.id);
      return {
        ...coach,
        businessHours: avs,
      };
    });
  }

  const { PromptComponent, requestConfirmation } = useConfirmation(
    (props: any) => <ConfirmationPrompt {...props} />
  );

  /* const updateEvents = useCallback(() => {
  *   eventActions.fetchEvents({ start: isoStartDate, end: isoEndDate });
  * }, [isoStartDate, isoEndDate]);
  */
  /* useInterval(updateEvents, 60 * 1000); */

  // @ts-ignore: next-line
  const selectedEvent: EventModel = useSelector((state: RootState) => state.events.selected);
  const isEditingEvent: boolean = useSelector((state: RootState) => state.events.editSelected);
  const newEvent = useSelector((state: RootState) => state.events.newEvent);
  const showInfoPopup = useSelector((state: RootState) => state.events.showInfoPopup);
  const infoPopupContent = useSelector((state: RootState) => state.events.infoPopupContent);

  if (currentFacility.pending) {
    return (
      <Loading loadType="spinner" isLoading className={styles.loadingSpinner} />
    );
  }

  const eventForForm = selectedEvent || newEvent;
  const showEventForm = (selectedEvent && isEditingEvent) || newEvent;
  const timeZone = DateTime.now().zoneName;

  const onNextCalendar = () => {
    const calendarApi = calendarRef.current.getApi();

    calendarApi.next();
    setCalendarDate(DateTime.fromJSDate(calendarApi.view.activeStart));
  };

  const onPrevCalendar = () => {
    const calendarApi = calendarRef.current.getApi();

    calendarApi.prev();
    setCalendarDate(DateTime.fromJSDate(calendarApi.view.activeStart));
  };

  const onDateChangeCalendar = (date: DateTime) => {
    const calendarApi = calendarRef.current.getApi();

    setCalendarDate(date);
    calendarApi.gotoDate(date.toJSDate());
  };

  const renderEvent = (info: any) => {
    return <Event {...info} />;
  };

  const renderResourceLabelContent = (info: any) => {
    if(!currentFacility.data?.coachProfiles) {
      return null;
    }
    const coach = currentFacility.data.coachProfiles.find(c => c.id === info.resource.id);
    return <CoachInfo coach={coach} singleCoach={false} />;
  };

  const onDeleteEvent = () => {
    let content =
      selectedEvent.type === "coach_schedule_block"
        ? t("Please confirm that you would like to cancel this event.")
        : t("Please confirm that you would like to delete this session.");

    if (selectedEvent.recurrenceGroupId) {
      content =
        selectedEvent.type === "coach_schedule_block"
          ? t(
              "Your event is set to {{repeat}}. You can choose whether you wish to apply these changes to the repeating dates as well.",
              { repeat: capitalize(selectedEvent.repeat) }
            )
          : t(
              "Your session is set to “Repeating - {{repeat}}”. You can choose whether you wish to apply these changes to the repeating dates as well.",
              { repeat: capitalize(selectedEvent.repeat) }
            );
    }

    const title =
      selectedEvent.type === "coach_schedule_block"
        ? t("Delete Unavailability")
        : t("Cancel Session");

    requestConfirmation({
      event: selectedEvent,
      content,
      title,
      operation: "delete",
      actions: {
        onConfirm: () => {
          dispatch(deleteEvent(selectedEvent));
        },
        onConfirmFollowing: () => {
          dispatch(deleteEvent(selectedEvent, "following"));
        },
      },
    });
  };

  const onSaveEventForm = (data: any) => {
    if (data.id) {
      const payload = EventModel.apiPayload(data, "update");
      let content = t(
        "Please confirm that you would like to edit this session."
      );
      if (data.recurrenceGroupId) {
        content = t(
          "Your session is set to “Repeating - {{repeat}}”. You can choose whether you wish to apply these changes to the repeating dates as well.",
          { repeat: capitalize(selectedEvent.repeat) }
        );
      }
      requestConfirmation({
        event: selectedEvent,
        content,
        title: t("Edit Event"),
        operation: "edit",
        actions: {
          onConfirm: () => {
            dispatch(editEvent(payload));
          },
          onConfirmFollowing: () => {
            dispatch(editEvent({ ...payload, applyToRecurrence: "following" }));
          },
        },
      });
    } else {
      const payload = EventModel.apiPayload(data, "create");
      dispatch(createEvent(payload));
    }
  };

  const onEditEvent = () => {
    dispatch(startEdit());
  };

  const onCancelSelection = () => {
    calendarRef.current.getApi().unselect();
    setSelectedCoach(null);
    setWithCoachSelection(false);
    dispatch(deselectEvent());
  };

  const initNewEvent = () => {
    setWithCoachSelection(true);
    dispatch(
      initializeNewEvent({
        start: null,
        end: null,
        date: calendarDate.toJSDate(),
        type: EventType.LESSON_SCHEDULE,
        timeZone,
        backgroundColor: "#0279b3",
        coachProfiles: [],
      })
    );
  };

  // @ts-ignore: next-line
  const eventSubmitErrors = events.error;

  const selectAllow = (info: any) => {
    const coach = currentFacility.data.coachProfiles.find(c => c.id === info.resource.id);
    const localStart = DateTime.fromJSDate(info.start).setZone(coach.timeZone);
    const localEnd = DateTime.fromJSDate(info.end).setZone(coach.timeZone);

    if (!coach) {
      return false;
    }

    if ((user.role === RoleType.COACH && user.isAdmin) || user.role === RoleType.ADMIN) {
      return true;
    }

    if (user.role === RoleType.COACH && userId === coach.id) {
      return true;
    }

    const now = new Date();
    if(info.start.getTime() - now.getTime() < 0) {
      return false;
    }

    if (DateTime.fromJSDate(info.end).diff(coach.latestBookingDate).toMillis() > 0) {
      return false;
    }

    if (DateTime.fromJSDate(info.start).diff(coach.earliestBookingDate).toMillis() < 0) {
      return false;
    }

    const avs = availability.filter(av => av.coachProfileId === coach.id);
    const businessHourIntervals = AvailabilityModel.getIntervals(avs);

    const withinHours = some(businessHourIntervals, interval => {
        return interval.contains(localStart) && interval.contains(localEnd.minus({ millisecond: 1 }));
    });

    if (!withinHours) {
      return false;
    }

    return true;
  };

  const calendarHeaderDateFormat = (date: DateTime, _view: string) => {
    return date.toLocaleString(DATE_FULL_WEEKDAY);
  };

  const coachForForm = isEditingEvent ? selectedEvent.coachProfile : selectedCoach;

  return (
    <div className={styles.container}>
      {showEventForm && (
        <EventForm
          event={eventForForm}
          onCancel={onCancelSelection}
          onSave={onSaveEventForm}
          submitting={events.pendingCreate || events.pendingEdit}
          errors={eventSubmitErrors}
          coachProfiles={compact([coachForForm])}
          coachProfileOptions={currentFacility.data.coachProfiles}
          allowCoachSelection={withCoachSelection}
          facility={currentFacility.data}
        />
      )}

      <InfoModal
        isOpen={showInfoPopup}
        title={infoPopupContent.title}
        status={infoPopupContent.status}
        onDismiss={() => dispatch(hideInfoPopup())}
        payload={infoPopupContent.payload}
        action={infoPopupContent.action}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <div>{infoPopupContent.content}</div>
          {infoPopupContent.item && (
            <div>
              <b style={{ color: "#000" }}>
                {DateTime.fromISO(infoPopupContent.item?.start).toLocaleString(DATETIME_FULL_WEEKDAY as DateTimeFormatOptions)}
              </b>
            </div>
          )}
        </div>
      </InfoModal>

      <PromptComponent />

      <div
        id="calendar-element"
        className={`${styles.calendar_container} calendar-page-container`}
      >
        <CalendarToolbar
          date={calendarDate}
          dateFormat={calendarHeaderDateFormat}
          onNext={onNextCalendar}
          onPrev={onPrevCalendar}
          onDateChange={onDateChangeCalendar}
          initNewEvent={initNewEvent}
          calendarView={calendarView}
        />

        <EventInfo
          event={selectedEvent}
          referenceElement={eventElRef.current}
          onDelete={onDeleteEvent}
          onEdit={onEditEvent}
        />
        <FullCalendar
          schedulerLicenseKey={config.FULL_CALENDAR_LICENSE}
          headerToolbar={false}
          ref={calendarRef}
          events={events.data as any[]}
          allDaySlot={false}
          selectable
          selectMirror
          unselectAuto
          expandRows
          unselectCancel=".event_form_container, .confirm_cancel_conflict, .info-modal-content, .info-modal-header, .time-range-menu-dropdown"
          plugins={[resourceTimeGridPlugin, luxonPlugin, interactionPlugin]}
          unselect={() => dispatch(deselectEvent())}
          viewDidMount={(viewApi) => {
            setCalendarDate(DateTime.fromJSDate(viewApi.view.activeStart));
          }}
          select={(info) => {
            // @ts-ignore: next-line
            const timeZone = info.view.dateEnv.timeZone;
            const date = DateTime.fromISO(info.startStr)
              .setZone(timeZone)
              .toISO();
            const coach = currentFacility.data.coachProfiles.find(c => c.id === info.resource.id );
            const lessonRate = coach.lessonRates.find(lr => lr.defaultRate);

            setSelectedCoach(coach);

            dispatch(
              initializeNewEvent({
                backgroundColor: "#0279b3",
                date: new Date(date),
                start: info.startStr,
                end: info.endStr,
                type: EventType.LESSON_SCHEDULE,
                coachProfiles: [coach],
                lessonRateId: lessonRate?.id,
                rate: lessonRate?.rate,
                location: lessonRate?.location,
                timeZone,
              })
            );
          }}
          dayHeaderFormat={{ day: "numeric", weekday: "short" }}
          slotLabelFormat={{
            hour: "numeric",
            minute: "2-digit",
            omitZeroMinute: true,
            hour12: true,
          }}
          initialView="resourceTimeGridDay"
          resources={coachProfileResources}
          timeZone={timeZone}
          eventContent={renderEvent}
          resourceLabelContent={renderResourceLabelContent}
          businessHours={availability}
          slotDuration="01:00:00"
          height="100%"
          eventBackgroundColor="#039BE5"
          snapDuration="00:30:00"
          eventClick={(info) => {
            eventElRef.current = info.el;
            dispatch(setSelectedEvent(parseInt(info.event.id, 10)));
          }}
          longPressDelay={600}
          scrollTime="05:45:00"
          nowIndicator={true}
          nowIndicatorClassNames="multi-coach-now-indicator-line"
          selectAllow={selectAllow}
        />
      </div>
    </div>
  );
}
