import React from "react";
import AvatarWithEdit from "app/components/AvatarWithEdit";
import ButtonVariant from "app/components/Button/ButtonVariant";
import CoverImage from "app/components/CoverImage";
import { cls } from "app/utils";
import AccountWrap from "..";
import { useCoachProfileModel } from "./indexModel";
import styles from "./styles.module.scss";
import { Toast } from "app/components/helpers";
import CountrySelect from "react-flags-select";
import { states } from "app/components/StripeForm/states";
import PhoneInput from "react-phone-input-2";
// import useClassDetection from "app/utils/hooks/useClassDetection";

const CoachProfileEdit = () => {
  const {
    t,
    self,
    userState,
    updateUserData,
    uploadedAvatar,
    onCoverChange,
    uploadedCover,
    // onUpload,
    onPhoneChange,
    phoneValue,
    updateUser,
    handleUrlInputUpdate,
    setToastAlert,
    toastAlert,
    toastErrorAlert,
    deleteCoverHandler,
    // deleteAvatarHandler,
    error,
  } = useCoachProfileModel();

  if (!userState && !self) {
    return null;
  }

  // const pageIsTrnslated = useClassDetection("translated-ltr");

  return (
    <div className={styles.container}>
      <AccountWrap>
        <div className={cls(styles.container, "panel")}>
          <h2 className={styles.panel_title}>{t("Coach Profile")}</h2>
          <div className={styles.profile_container}>
            <CoverImage
              onChange={onCoverChange}
              image={uploadedCover}
              onDelete={deleteCoverHandler}
            />
            <div className={styles.avatar_container}>
              <AvatarWithEdit
                canEdit={false}
                // onUpload={onUpload}
                userName={self?.displayName}
                avatar={uploadedAvatar}
              />
            </div>
          </div>
          <div className={styles.inputs_content}>
            <div className={styles.inputs_content_left}>
              <span className={styles.input_wrapper}>
                <label htmlFor="name">{t("First Name")}</label>
                <input
                  type="text"
                  id="name"
                  defaultValue={userState?.firstName}
                  onChange={(e) =>
                    updateUserData({
                      type: "first_name",
                      value: e.target.value,
                    })
                  }
                  placeholder={t("First Name")}
                />
              </span>
              <span className={styles.input_wrapper}>
                <label htmlFor="lastName">{t("Last Name")}</label>
                <input
                  type="text"
                  id="lastName"
                  defaultValue={userState?.lastName}
                  onChange={(e) =>
                    updateUserData({ type: "last_name", value: e.target.value })
                  }
                  placeholder={t("Last Name")}
                />
              </span>

              <span className={styles.input_wrapper}>
                <label htmlFor="handle">{t("Handle")}</label>
                <input
                  type="text"
                  id="Handle"
                  defaultValue={userState?.handle}
                  onChange={(e) =>
                    updateUserData({ type: "handle", value: e.target.value })
                  }
                  placeholder={t("Handle")}
                />
              </span>
              <span className={styles.input_wrapper}>
                <label htmlFor="pga_number">{t("PGA Number")}</label>
                <input
                  type="number"
                  id="pga_number"
                  defaultValue={userState?.pgaNumber}
                  onChange={(e) =>
                    updateUserData({
                      type: "pga_number",
                      value: e.target.value,
                    })
                  }
                  placeholder={t("PGA Number")}
                />
              </span>
              <span className={styles.information}>
                <h2>{t("Contact Information")}</h2>
                <p>
                  {t(
                    "Your athletes can contact you through these contact details",
                  )}
                </p>
              </span>
              <span className={styles.input_wrapper}>
                <label htmlFor="email">{t("Email")}</label>
                <input
                  type="email"
                  id="email"
                  defaultValue={userState?.email}
                  onChange={(e) =>
                    updateUserData({ type: "email", value: e.target.value })
                  }
                  placeholder={t("Email")}
                />
              </span>
              <span
                className={cls(styles.input_wrapper, styles.phone_container)}
              >
                <label htmlFor="phone">{t("Phone")}</label>
                <PhoneInput
                  onChange={onPhoneChange}
                  inputStyle={{ width: "100%", height: "42px" }}
                  // inputProps={{ autoFocus: true }}
                  enableSearch
                  disableSearchIcon
                  searchClass={styles.search_class}
                  inputClass={styles.input_class}
                  buttonClass={styles.button_class}
                  dropdownClass={styles.dropdown_class}
                  country={
                    userState?.phoneCountry
                      ? userState?.phoneCountry.toLowerCase()
                      : "us"
                  }
                  value={phoneValue}
                />
              </span>

              {/* {!pageIsTrnslated && ( */}
              <span className={styles.input_wrapper}>
                <label htmlFor="country">{t("Country")}</label>
                <CountrySelect
                  id="country"
                  onSelect={(code: string) => {
                    updateUserData({ type: "country", value: code });
                  }}
                  selected={userState?.country}
                  placeholder={t("Country")}
                  searchable
                  className={cls(styles.country_select)}
                  optionsSize={14}
                />
              </span>
              {/* )} */}

              {userState?.country === "US" && (
                <span className={styles.input_wrapper}>
                  <label htmlFor="state">{t("State")}</label>
                  <select
                    id="state"
                    name="state"
                    className={styles.select_states}
                    onChange={(e) =>
                      updateUserData({ type: "state", value: e?.target?.value })
                    }
                    value={userState?.state}
                    // disabled={props.disabled}
                    placeholder={t("Select state")}
                  >
                    {!userState?.state && (
                      <option value="">{t("Select state")}</option>
                    )}
                    {states.map((state) => (
                      <option
                        key={state.abbreviation}
                        value={state.abbreviation}
                      >
                        {state.name}
                      </option>
                    ))}
                  </select>
                </span>
              )}
              <span className={styles.input_wrapper}>
                <label htmlFor="city">{t("City")}</label>
                <input
                  type="text"
                  id="city"
                  defaultValue={userState?.city}
                  onChange={(e) =>
                    updateUserData({ type: "city", value: e.target.value })
                  }
                  placeholder={t("City")}
                />
              </span>
              <span className={styles.input_wrapper}>
                <label htmlFor="zip_code">{t("Zip Code")}</label>
                <input
                  type="text"
                  id="zip_code"
                  defaultValue={userState?.zip}
                  onChange={(e) =>
                    updateUserData({ type: "zip", value: e.target.value })
                  }
                  placeholder={t("Zip Code")}
                />
              </span>
              <span className={styles.input_wrapper}>
                <label htmlFor="address">{t("Address")}</label>
                <input
                  type="text"
                  id="address"
                  defaultValue={userState?.address}
                  onChange={(e) =>
                    updateUserData({ type: "address", value: e.target.value })
                  }
                  placeholder={t("Address")}
                />
              </span>
              <span className={styles.input_wrapper}>
                <label htmlFor="facility">{t("Facility")}</label>
                <input
                  type="text"
                  id="facility"
                  defaultValue={userState?.facility}
                  onChange={(e) =>
                    updateUserData({ type: "facility", value: e.target.value })
                  }
                  placeholder={t("Facility")}
                />
              </span>
              <span className={styles.information}>
                <h2>{t("Book & Pay")}</h2>
                <p>
                  {t(
                    `Here, you can add links to external services(such as Calendly), providing your athletes with the ability to book or pay`,
                  )}
                </p>
              </span>
              <span className={styles.input_wrapper}>
                <label htmlFor="booking_service">{t("Booking Service")}</label>
                <input
                  type="text"
                  id="booking_service"
                  value={userState?.bookingServiceLink ?? ""}
                  onChange={(e) =>
                    handleUrlInputUpdate({
                      type: "bookingServiceLink",
                      value: e.target.value,
                    })
                  }
                  placeholder={t("Booking Service")}
                />

                {error.bookingServiceError && (
                  <p className={styles.error}>{error.bookingServiceError}</p>
                )}
              </span>
              <span className={styles.input_wrapper}>
                <label htmlFor="payment_service">{t("Payment Service")}</label>
                <input
                  type="text"
                  id="payment_service"
                  value={userState?.paymentServiceLink || ""}
                  onChange={(e) =>
                    handleUrlInputUpdate({
                      type: "paymentServiceLink",
                      value: e.target.value,
                    })
                  }
                  placeholder={t("Payment Service")}
                />
                {error.paymentServiceError && (
                  <p className={styles.error}>{error.paymentServiceError}</p>
                )}
              </span>
            </div>
            <hr className={styles.divider} />
            <div className={styles.inputs_content_right}>
              <span className={styles.input_wrapper}>
                <label htmlFor="about">{t("About")}</label>
                <textarea
                  className={styles.about_textarea}
                  id="about"
                  defaultValue={userState?.about}
                  rows={25}
                  onChange={(e) =>
                    updateUserData({ type: "about", value: e.target.value })
                  }
                  placeholder={t("About")}
                />
              </span>
              <span className={styles.input_wrapper}>
                <label htmlFor="awards">{t("Awards")}</label>
                <textarea
                  className={styles.awards_textarea}
                  id="awards"
                  defaultValue={userState?.awards}
                  rows={3}
                  onChange={(e) =>
                    updateUserData({ type: "awards", value: e.target.value })
                  }
                  placeholder={t("awards")}
                />
              </span>
            </div>
          </div>
          <ButtonVariant
            buttonType="primary"
            title={t("Save Changes")}
            buttonStyles={styles.save_button}
            onClickFunc={updateUser}
          />
        </div>
        <Toast
          error={toastErrorAlert}
          success={toastAlert}
          onDone={() => setToastAlert(null)}
        />
      </AccountWrap>
    </div>
  );
};

export default CoachProfileEdit;
