
/**
 * @module Components.StripeForm
 *
 */
import React from 'react';
import StartFreeTrialFormElementContainer, { PropsDef } from './StartFreeTrialFormElementContainer';
import { CardExpiryElement } from 'react-stripe-elements';
import { useTranslation } from 'react-i18next';

const CardExpiry = (props: PropsDef) => {
  const { t } = useTranslation();

  return (
    <StartFreeTrialFormElementContainer
      elementType={CardExpiryElement}
      placeholder={{
        id: "placeholder.form.card-expiry",
        defaultMessage: t("Expiration Date (MO/YR)"),
      }}
      {...props}
    />
  );
};

export default CardExpiry;
