import React, { memo } from "react";
import styles from "./styles.module.scss";
import PagePanel from "app/components/PagePanel";
import TeamAvatar from "app/components/TeamAvatar";

interface GroupHeaderProps {
  title: string;
  avatar?: string;
  sport?: string;
}

const GroupHeader = ({ title, sport, avatar }: GroupHeaderProps) => {
  return (
    <PagePanel>
      <div className={styles.header_wrapper}>
        <TeamAvatar
          sizeHeight={64}
          sizeWidth={64}
          altName="group"
          className={styles.header_logo}
          srcName={avatar}
        />
        <div className={styles.header_title_wrapper}>
          <h1>{title}</h1>
          {sport && <p>{sport}</p>}
        </div>
      </div>
    </PagePanel>
  );
};

export default memo(GroupHeader);
