import spacesIcon from "../../../public/images/smart-list/spaces-icon.svg";
import inspirationIcon from "../../../public/images/smart-list/inspiration-icon.svg";
import thirtyDaysIcon from "../../../public/images/smart-list/30-calendar-icon.svg";
import sixtyDaysIcon from "../../../public/images/smart-list/60-plus-calendar-icon.svg";
import feedPostIcon from "../../../public/images/smart-list/feed-post-icon.svg";
import fifteenDaysIcon from "../../../public/images/smart-list/15-calendar-icon.svg";
import listIcon from "../../../public/images/smart-list/list-icon.svg";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { selectedListValue, clearSmartList } from "app/actions/smartList";
import { useCallback, useEffect, useMemo, useState } from "react";
import { RootState } from "typedefs";
import SmartListModel from "app/models/SmartListModel";
import { fetchLists, clearList } from "app/actions/list";
import { useActions } from "app/utils/hooks";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useSmartListsData from "../helpers/useSmartListsData";
import useInfiniteScroll from "react-infinite-scroll-hook";

const PER_PAGE = 8;

export const useSmartLists = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const smartListsData = useSmartListsData();

  const route = (path: string) => {
    history.push(path);
  };

  const smartListActions = useActions({
    fetchLists,
    clearList,
    clearSmartList,
  });

  // Data for predefined spaces
  const AllSpacesData = useMemo(
    () => [
      {
        name: t("All spaces"),
        object: "athlete_space",
        id: "1",
        icon: spacesIcon,
      },
      {
        name: t("Inspiration"),
        object: "demo_space",
        id: "2",
        icon: inspirationIcon,
      },
    ],
    [],
  );

  const user = useSelector((state: RootState) => state.user.self, shallowEqual);

  const selectedList = useSelector(
    (state: RootState) => state.smartList.selectedListValue,
    shallowEqual,
  );

  const [isDropdownOpen, setDropdownOpen] = useState<boolean>(false);
  const [isUpgradeModalOpen, setIsUpgradeModalOpen] = useState<boolean>(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [segmentsData, setSegmentsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasMoreSegments, setHasMoreSegments] = useState(true);

  const fetchSegmentsData = async (queryParams: {}) => {
    setLoading(true);
    const res = await smartListActions.fetchLists(queryParams);
    if (res?.length < PER_PAGE) {
      setHasMoreSegments(false);
    }
    setSegmentsData([...segmentsData, ...res]);
    setCurrentPage(currentPage + 1);
    setLoading(false);
  };

  // Initial fetch and pagination logic
  useEffect(() => {
    if (segmentsData?.length === 0 && !loading) {
      fetchSegmentsData({ page: 1, per_page: PER_PAGE });
    }

    return () => {
      smartListActions.clearList();
      setSegmentsData([]);
      setLoading(false);
      setHasMoreSegments(true);
    };
  }, []);

  const loadMoreSegments = () => {
    if (!loading) {
      fetchSegmentsData({ page: currentPage, per_page: PER_PAGE });
    }
  };

  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage: hasMoreSegments,
    onLoadMore: loadMoreSegments,
    rootMargin: "0px 0px 0px 0px",
  });

  const transformSmartLists = (smartListData: any) => {
    return smartListData?.map((item: SmartListModel) => {
      const { name, object } = item;

      let icon: string | null;

      if (name.includes("15")) {
        icon = fifteenDaysIcon;
      } else if (name.includes("30")) {
        icon = thirtyDaysIcon;
      } else if (name.includes("60")) {
        icon = sixtyDaysIcon;
      } else if (name.includes("Unanswered")) {
        icon = feedPostIcon;
      } else if (object.includes("segment")) {
        icon = listIcon;
      } else {
        icon = feedPostIcon;
      }
      return {
        ...item,
        icon,
      };
    });
  };

  const handleUpgradeModal = () => {
    setIsUpgradeModalOpen((prev) => !prev);
  };

  const hasSmartListAccess = (): boolean => {
    return (
      (user.plan.planCode.startsWith("coachpro+") &&
        user.plan.planCode.startsWith("academy")) ||
      user.plan.status !== "trial_expired"
    );
  };

  const handleDataClick = useCallback(
    (value: { name: string; id: string; object: string }) => {
      if (value.object === "smart_list" && !hasSmartListAccess()) {
        handleUpgradeModal();
      } else {
        dispatch(selectedListValue(value));
        setDropdownOpen((prev: boolean): boolean => !prev);

        const newRoute = `/spaces?object=${value?.object}&name=${value.name
          .toLocaleLowerCase()
          .replace(/\s+/g, "_")}&id=${value.id ? value.id : "_"}`;

        route(newRoute);
      }
    },
    [user, dispatch, handleUpgradeModal, route],
  );

  const handleDropdownBtn = useCallback(() => {
    setDropdownOpen((prev) => !prev);
  }, []);

  // calculate valid data for rendering
  const validData = useMemo(() => {
    const allSpacesData = Array.isArray(AllSpacesData) ? [AllSpacesData] : [];
    const userData = Array.isArray(smartListsData)
      ? [transformSmartLists(smartListsData)]
      : [];
    const segments = segmentsData ? transformSmartLists(segmentsData) : [];

    return [...allSpacesData, ...userData, segments];
  }, [AllSpacesData, smartListsData, segmentsData]);

  return {
    data: validData,
    handleDataClick,
    selectedList,
    isDropdownOpen,
    handleDropdownBtn,
    handleUpgradeModal,
    isUpgradeModalOpen,
    sentryRef,
    segmentsLoading: loading,
    user,
  };
};
