import React from "react";
import UserModel from "app/models/UserModel";
import CoachCard from "../CoachCard";
import styles from "./styles.module.scss";

interface Props {
  coaches: UserModel[];
}

const MyCoachesList = ({ coaches }: Props) => {
  return (
    <div className={styles.container}>
      {coaches?.map((user: UserModel) => {
        return <CoachCard key={user?.id} user={user} />;
      })}
    </div>
  );
};

export default MyCoachesList;
