import React, { useState, useEffect } from "react";
import Select, { StylesConfig } from "react-select";
import styles from "./styles.module.scss";
import SwitchToggle from "../SwitchToggle";
import { UseFormRegister, UseFormSetValue, UseFormWatch } from "react-hook-form";
import { useTranslation } from 'react-i18next';

interface RemindersProps {
  register: UseFormRegister<any>;
  setValue: UseFormSetValue<any>;
  watch: UseFormWatch<any>;
}

const Reminders: React.FC<RemindersProps> = ({ register, setValue, watch }) => {
  const { t } = useTranslation();
  const reminderOptions = [
    { label: t("count_hour", { count: 1 }), value: 1 },
    { label: t("count_hour", { count: 3 }), value: 3 },
    { label: t("count_hour", { count: 6 }), value: 6 },
    { label: t("count_hour", { count: 12 }), value: 12 },
    { label: t("count_day", { count: 1 }), value: 24 },
    { label: t("count_day", { count: 2 }), value: 48 },
    { label: t("count_day", { count: 3 }), value: 72 },
    { label: t("count_day", { count: 4 }), value: 96 },
    { label: t("count_day", { count: 5 }), value: 120 },
    { label: t("count_day", { count: 6 }), value: 144 },
    { label: t("count_day", { count: 7 }), value: 168 },
  ];

  const reminderTime = watch("reminder_time", { label: t("1 Day"), value: 24 });
  const secondReminderTime = watch("second_reminder_time", null);

  const [firstReminderEnabled, setFirstReminderEnabled] = useState(reminderTime !== null);
  const [secondReminderEnabled, setSecondReminderEnabled] = useState(secondReminderTime !== null);

  const handleSecondReminderChange = (checked: boolean) => {
    setSecondReminderEnabled(checked);
    setValue("second_reminder_time", checked ? { label: t("1 Hour"), value: 1 } : null);
  };

  useEffect(() => {
    setFirstReminderEnabled(reminderTime !== null);
    setSecondReminderEnabled(secondReminderTime !== null);
  }, [reminderTime, secondReminderTime]);

  const handleFirstReminderChange = (selectedOption: any) => {
    setValue("reminder_time", selectedOption.value);
  };

  const handleSecondReminderTimeChange = (selectedOption: any) => {
    setValue("second_reminder_time", selectedOption.value);
  };

  const customStyles: StylesConfig = {
    control: (base: any) => ({
      ...base,
      background: 'white',
      border: '1px solid #ccc',
    }),
    valueContainer: (base: any) => ({
      ...base,
      height: '48px',
      display: 'flex',
      alignItems: 'center',
    }),
    multiValue: (styles: any) => ({
      ...styles,
      backgroundColor: '#27a4ff',
      padding: '4px',
      borderRadius: '4px',
    }),
    multiValueLabel: (styles: any) => ({
      ...styles,
      color: 'white',
      fontSize: '16px',
    }),
    multiValueRemove: (styles: any) => ({
      ...styles,
      color: 'white',
      ':hover': {
        color: 'white',
      },
    }),
    singleValue: (provided: any) => ({
      ...provided,
      color: 'black',
    }),
    container: (provided: any) => ({
      ...provided,
      color: '#8C8C8C',
    }),
    placeholder: (defaultStyles: any) => ({
      ...defaultStyles,
      color: '#8c8c8c',
    }),
  };

  const IndicatorSeparator = (): JSX.Element | null => null;

  return (
    <div style={{ marginTop: "20px", marginBottom: "30px" }}>
      <div>
        <label htmlFor="reminders" className={styles.labelStyle}>
          {t("Reminders")}
        </label>
      </div>
      <div className={styles.custom_text}>
        {t("Determine when your students receive text and banner reminders. All students receive email reminders 48 hours before their session.")}
      </div>
      <div className={styles.reminderContainer}>
        <div className={styles.toggleContainer}>
          <SwitchToggle
            defaultChecked={firstReminderEnabled}
            title={t("First Reminder (Required)")}
            register={register}
            name="firstReminder"
            setValue={setValue}
            defaultValue={true}
            forcedState={true}
            disabled={true}
          />
        </div>
        <div className={styles.dropdownContainer}>
          <Select
            id="firstReminderDropdown"
            onChange={handleFirstReminderChange}
            options={reminderOptions}
            className={styles.dropdown}
            styles={customStyles}
            components={{ IndicatorSeparator }}
            value={reminderOptions.find(option => option.value === reminderTime)}
          />
        </div>
      </div>

      <div className={styles.marginDropdowns}>
        <div className={styles.reminderContainer}>
          <div className={styles.toggleContainer}>
            <SwitchToggle
              defaultChecked={secondReminderEnabled}
              title={t("Second Reminder")}
              register={register}
              name="secondReminder"
              setValue={setValue}
              defaultValue={false}
              onChange={handleSecondReminderChange}
            />
          </div>
          {secondReminderEnabled && (
            <div className={styles.dropdownContainer}>
              <Select
                id="secondReminderDropdown"
                onChange={handleSecondReminderTimeChange}
                options={reminderOptions}
                className={styles.dropdown}
                styles={customStyles}
                components={{ IndicatorSeparator }}
                value={reminderOptions.find(option => option.value === secondReminderTime)}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Reminders;
