import React, { ReactElement, useState } from "react";
import { RootState } from "typedefs";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";

import { EventResponse, LessonSchedule } from "app/models/EventModel";
import { schedulingFetchPatch } from "app/utils/request/scheduling/fetch";
import SaveBar from "app/routes/Sessions/SaveBar";
import PagePanel from "app/components/PagePanel";
import InfoModal from "app/routes/Calendar/InfoModal";
import { resetBookSession } from "app/actions/scheduling/bookSession";

import styles from "./styles.module.scss";

import { BookSessionState } from "app/reducers/scheduling/bookSession";

type SummaryItemProps = {
  label: string | ReactElement;
  value: string | ReactElement;
};

const SummaryItem = (props: SummaryItemProps) => {
  const { label, value } = props;
  return (
    <div className={styles.summary_item}>
      <div className={styles.summary_attribute}>{label}</div>
      <div className={styles.summary_value}>{value}</div>
    </div>
  );
};

export default function SessionConfirmation() {
  const history = useHistory();
  const dispatch = useDispatch();
  const bookSession = useSelector(
    (state: RootState): BookSessionState => state.bookSession,
  );
  const selectedSession = useSelector(
    (state: RootState): LessonSchedule => state.bookSession.selectedSession,
  );
  const [loading, setLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [sessionNotes, setSessionNotes] = useState(selectedSession.notes);
  const [errors, setErrors] = useState([]);
  const { t } = useTranslation();

  if (!bookSession.coach) {
    history.push("/sessions/new");
    return null;
  }

  const editSession = () => {
    setLoading(true);
    const payload = {
      start: DateTime.fromMillis(bookSession.time.startHour)
        .setZone(bookSession.coach.timeZone)
        .toISO(),
      end: DateTime.fromMillis(bookSession.time.endHour)
        .setZone(bookSession.coach.timeZone)
        .toISO(),
      lessonRateId: bookSession.pricingValue.id,
      notes: sessionNotes,
    };

    schedulingFetchPatch<EventResponse>(
      `/bookings/${selectedSession.id}`,
      payload,
    )
      .then(() => {
        setShowPopup(true);
        setLoading(false);
      })
      .catch((errs) => {
        setLoading(false);
        setErrors(errs.errors);
      });
  };

  const dateStr = DateTime.fromMillis(bookSession.time.startHour)
    .setZone(bookSession.time.timeZone)
    .toLocaleString({ year: "numeric", day: "numeric", month: "long" });

  const startTimeString = DateTime.fromMillis(bookSession.time.startHour)
    .setZone(bookSession.time.timeZone)
    .toLocaleString(DateTime.TIME_SIMPLE);

  const endTimeString = DateTime.fromMillis(bookSession.time.endHour)
    .setZone(bookSession.time.timeZone)
    .toLocaleString(DateTime.TIME_SIMPLE);

  const timeZoneShortString = DateTime.fromMillis(bookSession.time.endHour)
    .setZone(bookSession.time.timeZone)
    .toFormat("ZZZZ");

  const intervalTimeString = `${startTimeString} - ${endTimeString} ${timeZoneShortString}`;

  let oldSessionInfo = null;
  if (selectedSession) {
    const prevDateStr = DateTime.fromJSDate(
      selectedSession.date,
    ).toLocaleString({ year: "numeric", day: "numeric", month: "long" });

    const lessonRateId = selectedSession.lessonRateId;
    const oldLessonRate = bookSession.coach.lessonRates.find(
      (lr) => lessonRateId === lr.id,
    );

    oldSessionInfo = (
      <div className={`${styles.summary_box} ${styles.old_session_box}`}>
        <h2>Old Session</h2>
        <hr />
        <SummaryItem label={t("Session")} value={oldLessonRate?.title || "-"} />
        <SummaryItem
          label={t("Coach")}
          value={(
            <>
              {selectedSession.coachProfile.firstName +
                " " +
                selectedSession.coachProfile.lastName}
            </>
            )}
        />
        <SummaryItem label={t("Facility")} value={bookSession.facility.name} />
        <hr />
        <SummaryItem
          label={(
            <>
              <span className={styles.summary_icon}>
                <i className="ico ico-calendar" />
              </span>
              {t("Date")}
            </>
          )}
          value={prevDateStr}
        />
        <SummaryItem
          label={(
            <>
              <span className={styles.summary_icon}>
                <i className="ico ico-clock" />
              </span>
              {t("Time")}
            </>
          )}
          value={selectedSession.intervalTimeString}
        />
        {selectedSession.location && (
          <SummaryItem
            label={(
              <>
                <span className={styles.summary_icon}>
                  <i className="ico ico-map-marker" />
                </span>
                {t("Location")}
              </>
            )}
            value={selectedSession.location}
          />
        )}
        <hr />
        <SummaryItem
          label={t("Session Rate")}
          value={`${selectedSession.coachProfile.currencySymbol}${selectedSession.rate}`}
        />
      </div>
    );
  }

  return (
    <div
      className={`${styles.confirmation_page_container} session-confirmation-page-container`}
    >
      <PagePanel
        title={t("Edit Session")}
        buttonType="outline-secondary"
        buttonTitle={<i className="ico ico-back" style={{ color: "#000" }} />}
        url="/sessions/new"
      />

      <div className={styles.summary_container}>
        <div className={`${styles.summary_box} ${styles.new_session_box}`}>
          <h2>Session Summary</h2>
          <hr />
          <SummaryItem
            label={t("Session")}
            value={bookSession.pricingValue.title}
          />
          <SummaryItem
            label={t("Coach")}
            value={(
              <>
                <img
                  className={styles.avatar}
                  src={bookSession.coach.profilePicture}
                />
                {bookSession.coach.fullName}
              </>
            )}
          />
          <SummaryItem
            label={t("Facility")}
            value={bookSession.facility.name}
          />
          <hr />
          <SummaryItem
            label={(
              <>
                <span className={styles.summary_icon}>
                  <i className="ico ico-calendar" />
                </span>
                {t("Date")}
              </>
            )}
            value={dateStr}
          />
          <SummaryItem
            label={(
              <>
                <span className={styles.summary_icon}>
                  <i className="ico ico-clock" />
                </span>
                {t("Time")}
              </>
            )}
            value={intervalTimeString}
          />
          {bookSession.pricingValue.location && (
            <SummaryItem
              label={(
                <>
                  <span className={styles.summary_icon}>
                    <i className="ico ico-map-marker" />
                  </span>
                  {t("Location")}
                </>
              )}
              value={bookSession.pricingValue.location}
            />
          )}
          <hr />
          <SummaryItem
            label={t("Session Rate")}
            value={`${bookSession.coach.currencySymbol}${bookSession.pricingValue.rate}`}
          />
        </div>

        {oldSessionInfo}

        <div className={styles.notes_container}>
          <h2> Notes </h2>
          <textarea
            className={styles.notes_input}
            placeholder={t("Add note here")}
            onChange={(ev) => {
              setSessionNotes(ev.target.value);
            }}
            value={sessionNotes}
          />
        </div>
      </div>
      <SaveBar
        submitLabel={loading ? t("Saving...") : t("Confirm")}
        cancelLabel="Back"
        style={styles.save_bar}
        onCancel={() => history.push("/sessions/edit")}
        onSubmit={editSession}
        submitDisabled={loading}
      />

      <InfoModal
        isOpen={showPopup}
        title={t("Session updated")}
        status="success"
        onDismiss={() => {
          dispatch(resetBookSession());
          history.push("/sessions");
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <div>{t("You have updated your scheduled session")}</div>
        </div>
      </InfoModal>

      <InfoModal
        isOpen={errors.length > 0}
        title={errors[0]?.details[0]?.message}
        status="error"
        onDismiss={() => {
          history.goBack();
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <div>{errors[0]?.details[0]?.detail}</div>
        </div>
      </InfoModal>
    </div>
  );
}
