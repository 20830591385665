import React from "react";
import UserModel from "app/models/UserModel";
import styles from "../styles.module.scss";
import Button from "app/components/Button/ButtonVariant";
import { cls } from "app/utils";
import UserAvatar from "app/components/UserAvatar";
import { useTranslation } from "react-i18next";

const UsersList = ({
  isCreatable,
  results,
  toggleUserSelection,
  noOptionsMessage,
  onNoOptionBtnClick,
  displayAddUser,
  hideEmail,
}: {
  isCreatable?: boolean;
  results: UserModel[];
  toggleUserSelection: (e: UserModel) => void;
  noOptionsMessage?: string | null;
  onNoOptionBtnClick?: () => void;
  displayAddUser?: boolean;
  hideEmail?: boolean;
}) => {
  const { t } = useTranslation();

  if (!results || !results.length) {
    return (
      <div className={styles.no_options_container}>
        <p className={cls("needsclick", styles.no_options_title)}>
          {t("No options")}
        </p>
        {noOptionsMessage && isCreatable && (
          <Button
            title={noOptionsMessage}
            buttonType="primary"
            buttonStyles={styles.no_options_btn}
            onClickFunc={onNoOptionBtnClick ? onNoOptionBtnClick : null}
          />
        )}
      </div>
    );
  }

  return (
    <div className={styles.list_container}>
      {displayAddUser && (
        <div className={styles.no_options_container}>
          {noOptionsMessage && (
            <Button
              title={noOptionsMessage}
              buttonType="primary"
              buttonStyles={styles.no_options_btn}
              onClickFunc={onNoOptionBtnClick ? onNoOptionBtnClick : null}
            />
          )}
        </div>
      )}
      {results &&
        results.map((user: UserModel) => {
          return (
            <div
              className={cls("menu-item needsclick", styles.user_wrapper)}
              key={user.id}
              onClick={() => toggleUserSelection(user)}
            >
              <div className="profile-block user-select-user profile-block-default needsclick">
                <UserAvatar
                  className="avatar needsclick"
                  userName={user.name}
                  srcName={user.avatar as string}
                />
                <div className="profile-block-details needsclick">
                  <div className="profile-block-title needsclick">
                    {user.displayName}
                  </div>

                  {!hideEmail && (
                    <div className="profile-block-summary needsclick">
                      {user.email || user.phoneNormalized}
                    </div>
                  )}
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default UsersList;
