/**
 * @module Components.StripeForm
 *
 */
import React from "react";
import StartFreeTrialFormWrapper, {
  PropsDef,
} from "./StartFreeTrialFormWrapper";

export default function StripeForm(props: PropsDef) {
  return <StartFreeTrialFormWrapper {...props} />;
}
