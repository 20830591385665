import React from "react";
import ModalLayout from "../../../../../components/Layouts/ModalLayout/ModalLayout";
import DeleteIcon from "../../../../../../public/images/delete-icon.svg";
import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";
import DOMPurify from 'dompurify';

interface RemoveAvailabilityProps {
  isOpen: boolean;
  onClose: () => void;
  onDelete: () => void;
  title: string;
}

const RemoveAvailability = ({ isOpen, onClose, onDelete, title }: RemoveAvailabilityProps) => {
  const { t } = useTranslation();
  const trimmedTitle = title?.length > 35 ? title?.substring(0, 35) + '...' : title;
  return (
    <ModalLayout isOpen={isOpen} onClose={onClose} classNames={styles.modal}>
      <div className={styles.close_button} onClick={onClose}>
          <i className="ico ico-x" />
      </div>
      <img style={{width: '40px', height: '40px', display: 'block', margin: '0 auto'}} src={DeleteIcon} alt="info" onClick={onClose}/>
      <div className={styles.modal_header}>
        <h2>{t("Delete Schedule")}</h2>
      </div>
      <div className={styles.modal_content}>
        <p
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(t('Are you sure you want to delete the <strong>{{title}}</strong> schedule?', {
              title: trimmedTitle,
            }),
            )}}
        />
        <div className={styles.buttonGroup}>
          <button type="button" className={styles.confirmButton} onClick={onDelete}>
            {t('Delete')}
          </button>
          <button type="button" className={styles.cancelButton} onClick={onClose}>
            {t('Cancel')}
          </button>
        </div>
      </div>
    </ModalLayout>
  );
};

export default RemoveAvailability;
