import { useCallback, useEffect, useRef, useState } from "react";
import { getMe } from "app/actions/groups";
import { clearPost, fetchPosts, reportPost } from "app/actions/posts";
import { fetchScheduledPosts, scheduledPosts } from "app/actions/schedule";
import { useActions } from "app/utils/hooks";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { RootState } from "typedefs";
import { deletePost, editPost } from "app/actions/postingForm";
import moment from "moment";
import { useParams } from "react-router-dom";
import PostModel from "app/models/PostModel";
import { FilterModel } from "app/models/FilterModel";
import { usePagination } from "app/utils/hooks/usePagination";

interface QueryParams {
  page?: number;
  teamId?: string;
  perPage?: number;
  replyLimit?: number;
}

const INIT_POST_PAGE = 1;
const POSTS_PER_PAGE = 16;
const POSTS_REPLY_LIMIT = 3;

export const GroupTimelineModel = () => {
  const { self } = useSelector((state: RootState) => state.user, shallowEqual);
  const { modalInviteIsOpen } = useSelector(
    (state: RootState) => state.teamGroupMemberImport,
    shallowEqual,
  );

  const dispatch = useDispatch();
  const postsActions = useActions({
    fetchPosts,
    fetchScheduledPosts,
    getMe,
    editPost,
    clearPost,
    reportPost,
    deletePost,
  });

  const { scheduledPostsSuccess: scheduledPostsData } = useSelector(
    (state: RootState) => state.schedulePost,
    shallowEqual,
  );

  const [newScehduleModalOpen, setNewScheduleModalOpen] =
    useState<boolean>(false);
  const [scheduledAt, setScheduledAt] = useState(null);
  const [reportModal, setReportModal] = useState({ isOpen: false, post: null });

  const { selectedGroup } = useSelector(
    (state: RootState) => state.groups,
    shallowEqual,
  );
  const urlParams = useParams<{ id: string }>();

  const handleNewSchedulePost = (): void => {
    setNewScheduleModalOpen((prev: boolean): boolean => !prev);
  };

  const onReport = (post: PostModel, message?: string) => {
    postsActions
      .reportPost(post.id, message)
      .then(() => {
        updateData(() =>
          data.filter((filteredPost: PostModel) => filteredPost.id !== post.id),
        );
      })
      .catch((error) => {
        console.error("Error deleting post:", error);
      });
  };

  const onPostReportClick = (post: PostModel) => {
    setReportModal({ isOpen: true, post });
  };

  const reportModalClose = () => {
    setReportModal({ isOpen: false, post: null });
  };

  const onReportHandler = (reason: string) => {
    onReport(reportModal?.post, reason);
    reportModalClose();
  };

  const postsParams = {
    teamId: urlParams.id,
    "options[include]": ["replies"],
    page: INIT_POST_PAGE,
    perPage: POSTS_PER_PAGE,
    replyLimit: POSTS_REPLY_LIMIT,
  };

  const [scheduledPostsParams] = useState({
    teamId: urlParams.id,
    "options[include]": ["posts"],
    orderBy: "scheduled_at",
    order: "asc",
    timezoneOffset: moment().utcOffset().toString(),
  });

  const [query, setQuery] = useState<QueryParams>(postsParams);
  const abortControllerRef = useRef<AbortController | null>(null);

  const { data, pending, sentryRef, updateData, loadInitialData }: any =
    usePagination({
      initialParams: {
        page: INIT_POST_PAGE,
        perPage: POSTS_PER_PAGE,
        ...query,
      },
      fetchData: (params, signal) => postsActions.fetchPosts(params, signal),
      clearDataAction: () => {},
      dependencies: [],
    });

  useEffect(() => {
    let mounted = true;

    const loadData = async () => {
      if (selectedGroup) {
        postsActions.getMe(urlParams.id);
      }
    };
    if (mounted) {
      loadData();
      postsActions.fetchScheduledPosts(scheduledPostsParams);
    }

    return () => {
      dispatch(scheduledPosts({ posts: null, error: null, pending: false }));
      postsActions.clearPost();
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
      updateData(() => []);
      setQuery(postsParams);
      mounted = false;
    };
  }, [postsActions, dispatch, urlParams.id]);

  const onPostDelete = (post: PostModel) => {
    const confirmation = window.confirm(
      "Are you sure you want to delete this post?",
    );
    if (confirmation) {
      postsActions
        .deletePost(post.id)
        .then(() => {
          updateData((prevData: PostModel[]) =>
            prevData.filter(
              (filteredPost: PostModel) => filteredPost.id !== post.id,
            ),
          );
        })
        .catch((error) => {
          console.error("Error deleting post:", error);
        });
    }
  };

  const onPostUpdate = (post: PostModel) => {
    if (post) {
      postsActions.editPost(post);
    }
  };

  const onPostPin = () => {
    loadInitialData(postsParams);
  };

  const canViewMembers = useCallback(() => {
    return (
      selectedGroup?.membersCanViewOtherMembers ||
      selectedGroup?.ownerId === self?.id
    );
  }, [selectedGroup]);

  function filterHandler(filters: FilterModel) {
    const filteredQuery = { ...postsParams, ...filters };
    postsActions.clearPost();
    loadInitialData(filteredQuery);
  }

  function onClearFilters() {
    postsActions.clearPost();
    loadInitialData(postsParams);
  }

  const onPost = async (post: PostModel) => {
    if (!post) {
      return;
    }

    if (!post?.scheduledAt) {
      if (!data?.[0]?.pinnedByName) {
        updateData((prevData: PostModel[]) => [
          { ...post, userAvatar: self.avatar },
          ...prevData,
        ]);
      } else if (data?.[0]?.pinnedByName) {
        updateData((prevData: PostModel[]) => {
          const newData = prevData.length > 0 ? [...prevData] : [];
          newData.splice(1, 0, {
            ...post,
            userAvatar: self.avatar,
          });
          return newData;
        });
      }
    } else {
      postsActions.fetchScheduledPosts(scheduledPostsParams);
      setScheduledAt(post.scheduledAt);
      handleNewSchedulePost();
    }
  };

  const canSeePostviews =
    (self?.id === selectedGroup?.ownerId ||
      selectedGroup?.membersCanSeePostData) ??
    false;

  const canSeeWhoLiked =
    (self?.id === selectedGroup?.ownerId ||
      selectedGroup?.membersCanSeePostData) ??
    false;

  const canPost =
    (self?.id === selectedGroup?.ownerId || selectedGroup?.membersCanPost) ??
    false;

  return {
    selectedGroup,
    data,
    scheduledPostsData,
    onPost,
    pending,
    reportModal,
    reportModalClose,
    onReportHandler,
    onPostReportClick,
    onPostDelete,
    onPostUpdate,
    onPostPin,
    sentryRef,
    filterHandler,
    onClearFilters,
    modalInviteIsOpen,
    canSeePostviews,
    canSeeWhoLiked,
    canPost,
    newScehduleModalOpen,
    handleNewSchedulePost,
    scheduledAt,
    canViewMembers,
  };
};
