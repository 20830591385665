/*{ pending } @module Actions.Event
 *
 */
import { Dispatch } from "react";
import { ActionType, action } from "typesafe-actions";
import { DateTime } from "luxon";
import { cloneDeep } from "lodash";
import { RootState } from "typedefs";

import {
  schedulingFetchGet,
  schedulingFetchDelete,
  schedulingFetchPost,
  schedulingFetchPatch,
} from "app/utils/request/scheduling/fetch";

import { DATETIME_FULL_WEEKDAY } from "app/utils/luxonFormats";
import {
  EventModel,
  EventResponse,
  EventType,
  LessonSchedule,
  CoachScheduleBlock,
} from "app/models/EventModel";

import { serializeObject } from "app/utils/request/model";

import i18n from "app/i18n";
import { CoachProfileModel } from "app/models";
import { ApiValidationError, SchedulingErrorResponse, SchedulingValidationError } from "app/models/scheduling/SchedulingErrorResponse";

const fetchPending = () => action("@events.fetch.pending");

const fetchError = (err: string) => action("@events.fetch.error", err);

const fetchSuccess = (events: EventModel[]) =>
  action("@events.fetch.success", events);

export const removeEvents = (ids: number[]) => action("@events.remove", ids);
const selectEvent = (event: EventModel) => action("@events.select", event);
export const deselectEvent = () => action("@events.deselect");
export const clearNewEvent = (event: EventModel) =>
  action("@events.new.clear", event);
const clearEvents = () => action("@events.clear");

const createNewEvent = (event: EventModel) => action("@events.new.init", event);
const createPending = () => action("@events.create.pending");
const createPendingDone = () => action("@events.create.pendingDone");
const createSuccess = (events: EventModel[]) =>
  action("@events.create.success", events);
const createError = (errors?: any) => action("@events.create.error", errors);
const createValidationError = (errors?: SchedulingValidationError) =>
  action("@events.create.validationError", errors);

export const startEdit = () => action("@events.edit.start");
export const endEdit = () => action("@events.edit.end");
const editSuccess = (
  events: EventModel[],
  type: EventType,
  recurrenceGroupId: number = null,
) => {
  return action("@events.edit.success", { events, type, recurrenceGroupId });
};

const editPending = () => action("@events.edit.pending");
const editPendingDone = () => action("@events.edit.pendingDone");
const editError = (errors?: any) => action("@events.edit.error", errors);
const editValidationError = (errors?: any) =>
  action("@events.edit.validationError", errors);

export const requireConfirmation = (content: any) =>
  action("@events.requestConfirmation", content);
export const dismissConfirmation = () => action("@events.dismissConfirmation");

const showInfoPopup = (content: any) =>
  action("@events.showInfoPopup", content);
export const hideInfoPopup = () => action("@events.hideInfoPopup");

const thisActions = {
  fetchPending,
  fetchError,
  fetchSuccess,
  selectEvent,
  deselectEvent,
  removeEvents,
  requireConfirmation,
  dismissConfirmation,
  createNewEvent,
  createSuccess,
  createError,
  createValidationError,
  clearNewEvent,
  createPending,
  createPendingDone,
  startEdit,
  endEdit,
  editPending,
  editPendingDone,
  showInfoPopup,
  hideInfoPopup,
  editSuccess,
  editError,
  editValidationError,
  clearEvents,
};

export type EventAction = ActionType<typeof thisActions>;

function parseError(errors: ApiValidationError[]): SchedulingValidationError {
  return errors.reduce<SchedulingValidationError>(
    (accum: SchedulingValidationError, err: ApiValidationError): SchedulingValidationError => {
      if (!accum[err.source.parameter]) {
        accum[err.source.parameter] = [];
      }
      accum[err.source.parameter] = err.details.map(d => d.message);

      return accum;
    },
    {}
  );
}

const parseEvents = (
  events: EventResponse[],
): (LessonSchedule | CoachScheduleBlock)[] => {
  return events
    .filter((ev) => Object.values(EventType).includes(ev.type))
    .map((event) => {
      if (event.type === "coach_schedule_block" || event.type === "program") {
        return EventModel.buildInstance(event);
      }

      return EventModel.buildInstance({
        ...event,
      });
    });
};

export function createEvent(event: EventModel) {
  if (event.type === "lesson_schedule") {
    return createSession(event);
  } else {
    return createScheduleBlock(event);
  }
}

export function editEvent(event: EventModel) {
  if (event.type === "lesson_schedule") {
    return editSession(event);
  } else {
    return editScheduleBlock(event);
  }
}

function createSession(event: EventModel) {
  return (dispatch: Dispatch<EventAction>) => {
    dispatch(createPending());
    schedulingFetchPost<EventResponse[]>("lesson_schedules", {
      ...event,
      source: "web",
    })
      .then((events: EventResponse[]) => {
        const sessions = parseEvents(events);

        const firstSession = sessions[0];
        dispatch(createSuccess(sessions));
        dispatch(
          showInfoPopup({
            title: i18n.t("New Session Created"),
            content: i18n.t("You created a new session on"),
            subcontent: `${DateTime.fromISO(firstSession.start)
              .setZone(firstSession.timeZone)
              .toLocaleString(DATETIME_FULL_WEEKDAY)}.`,
            item: firstSession,
            status: "success",
          }),
        );
      })
      .catch((err: SchedulingErrorResponse) => {
        if (err.status === 500) {
          dispatch(
            showInfoPopup({
              title: i18n.t("Something went wrong"),
              content: i18n.t(
                "A new session wasn't created, please try again.",
              ),
              status: "failed",
            }),
          );
          dispatch(createError());
        } else if (err.status === 409) {
          dispatch(
            showInfoPopup({
              title: i18n.t("Please confirm that you would like to proceed"),
              content: err.errors[0].details[0].message,
              status: "conflict",
              payload: event,
              action: "create",
            }),
          );

          dispatch(createPendingDone());
        } else {
          const validationErrors = serializeObject<SchedulingValidationError>(parseError(err.errors));
          dispatch(createValidationError(validationErrors));
        }
      });
  };
}

function createScheduleBlock(event: EventModel) {
  return (dispatch: Dispatch<EventAction>) => {
    dispatch(createPending());
    return schedulingFetchPost<EventResponse[]>("coach_schedule_blocks", {
      ...event,
      source: "web",
    })
      .then((events: EventResponse[]) => {
        const blocks = parseEvents(events);
        const firstBlock = blocks[0];

        dispatch(createSuccess(blocks));
        dispatch(
          showInfoPopup({
            title: i18n.t("New Unavailability Block Created"),
            content: i18n.t("You created a new unavailability block on"),
            subcontent: `${DateTime.fromISO(firstBlock.start)
              .setZone(firstBlock.timeZone)
              .toLocaleString(DATETIME_FULL_WEEKDAY)}.`,
            status: "success",
            item: firstBlock,
          }),
        );
      })
      .catch((err) => {
        if (err.status === 500) {
          dispatch(
            showInfoPopup({
              title: i18n.t("Something went wrong"),
              content: i18n.t(
                "The unavailability block wasn't created, please try again.",
              ),
              status: "failed",
            }),
          );
          dispatch(createError());
        } else if (err.status === 409) {
          dispatch(
            showInfoPopup({
              title: i18n.t("Please confirm that you would like to proceed"),
              content: err.errors[0].details[0].message,
              status: "conflict",
              payload: event,
              action: "create",
            }),
          );
          dispatch(createPendingDone());
        } else {
          const validationErrors = serializeObject<SchedulingValidationError>(parseError(err.errors));
          dispatch(createValidationError(validationErrors));
        }
      });
  };
}

function editSession(payload: EventModel) {
  return (dispatch: Dispatch<EventAction>) => {
    dispatch(editPending());

    return schedulingFetchPatch<EventResponse[]>(
      `lesson_schedules/${payload.id}`,
      payload,
    )
      .then((events: EventResponse[]) => {
        const sessions = parseEvents(events);
        const firstSession = sessions[0];
        dispatch(
          editSuccess(sessions, payload.type, payload.recurrenceGroupId),
        );
        dispatch(
          showInfoPopup({
            title: i18n.t("Session Updated"),
            content: i18n.t("This session is scheduled on"),
            subcontent: `${DateTime.fromISO(firstSession.start)
              .setZone(firstSession.timeZone)
              .toLocaleString(DateTime.DATETIME_FULL)}.`,
            status: "success",
            item: firstSession,
          }),
        );
      })
      .catch((err) => {
        if (err.status === 500) {
          dispatch(
            showInfoPopup({
              title: i18n.t("Something went wrong"),
              content: i18n.t(
                "The unavailability block wasn't created, please try again.",
              ),
              status: "failed",
            }),
          );
          dispatch(editError());
        } else if (err.status === 409) {
          dispatch(
            showInfoPopup({
              title: i18n.t("Please confirm that you would like to proceed"),
              content: err.errors[0].details[0].message,
              status: "conflict",
              action: "edit",
              payload,
            }),
          );

          dispatch(editPendingDone());
        } else if (err.status === 422) {
          dispatch(
            showInfoPopup({
              title: i18n.t("Session could not be changed."),
              content: err.errors[0].details[0].message,
              status: "error",
              action: "edit",
              payload,
            }),
          );

          dispatch(editPendingDone());
        } else {
          const validationErrors = serializeObject<SchedulingValidationError>(parseError(err.errors));
          dispatch(editValidationError(validationErrors));
        }
      });
  };
}

function editScheduleBlock(payload: EventModel) {
  return (dispatch: Dispatch<EventAction>) => {
    dispatch(editPending());
    return schedulingFetchPatch<EventResponse[]>(
      `coach_schedule_blocks/${payload.id}`,
      payload,
    )
      .then((events: EventResponse[]) => {
        const sessions = parseEvents(events);
        const firstSession = sessions[0];
        dispatch(
          editSuccess(sessions, payload.type, payload.recurrenceGroupId),
        );
        dispatch(
          showInfoPopup({
            title: i18n.t("Unavailability Block Updated"),
            content: i18n.t("This block is scheduled on"),
            subcontent: `${DateTime.fromISO(firstSession.start)
              .setZone(firstSession.timeZone)
              .toLocaleString(DateTime.DATETIME_FULL)}.`,
            status: "success",
            item: firstSession,
          }),
        );
      })
      .catch((err) => {
        if (err.status === 500) {
          dispatch(
            showInfoPopup({
              title: i18n.t("Something went wrong"),
              content: i18n.t(
                "The unavailability block wasn't updated, please try again.",
              ),
              status: "failed",
            }),
          );
          dispatch(editError());
        } else if (err.status === 409) {
          dispatch(
            showInfoPopup({
              title: i18n.t("Please confirm that you would like to proceed"),
              content: err.errors[0].details[0].message,
              status: "conflict",
              action: "edit",
              payload,
            }),
          );
          dispatch(editPendingDone());
        } else {
          const validationErrors = serializeObject<SchedulingValidationError>(parseError(err.errors));
          dispatch(editValidationError(validationErrors));
        }
      });
  };
}

type NewEventInit = {
  start: string;
  end: string;
  date: Date;
  type: EventType;
  timeZone: string;
  backgroundColor: string;
  repeat?: null;
  lessonRateId?: number | null;
  coachProfiles?: CoachProfileModel[];
  rate?: number;
  location?: string;
};

export function initializeNewEvent({
  start,
  end,
  type,
  timeZone,
  date,
  lessonRateId,
  rate,
  coachProfiles = [],
  location = "",
}: NewEventInit) {
  return (dispatch: Dispatch<EventAction>) => {
    const newEvent = {
      start,
      end,
      type,
      date,
      timeZone,
      repeat: null,
      studentProfiles: [],
      lessonRateId,
      rate,
      location,
      coachProfiles,
    };
    // @ts-ignore
    dispatch(createNewEvent(newEvent as EventModel));
  };
}

export function withConfirmation({ content }: { content: string }) {
  return (dispatch: Dispatch<EventAction>) => {
    dispatch(
      requireConfirmation({
        content,
      }),
    );
  };
}

export function deleteEvent(event: EventModel, applyToRecurrence?: string) {
  if (event.type === "lesson_schedule") {
    return deleteLessonSchedule(event, applyToRecurrence);
  } else {
    return deleteScheduleBlock(event, applyToRecurrence);
  }
}

function deleteLessonSchedule(event: EventModel, applyToRecurrence?: string) {
  return (dispatch: Dispatch<EventAction>, getState: () => RootState) => {
    return schedulingFetchDelete(`lesson_schedules/${event.id}`, {
      applyToRecurrence,
    })
      .then(() => {
        if (applyToRecurrence === "following") {
          const followingEventIds = getFollowingEvents(
            getState().events.data,
            event,
          ).map((ev) => ev.id);
          dispatch(removeEvents([event.id, ...followingEventIds]));
        } else {
          dispatch(removeEvents([event.id]));
        }
        dispatch(
          showInfoPopup({
            title: i18n.t("Session Canceled"),
            content: i18n.t("This session has been successfully canceled."),
            status: "success",
          }),
        );
      })
      .catch((err) => {
        console.error("fetchErr", err);

        if (err.status === 500) {
          dispatch(
            showInfoPopup({
              title: i18n.t("Something went wrong"),
              content: i18n.t(
                "This session has not been canceled. Please try again.",
              ),
              status: "error",
            }),
          );
        } else if (err.status === 409) {
          dispatch(
            showInfoPopup({
              title: i18n.t("Session could not be canceled."),
              content: err.errors[0].details[0].message,
              status: "error",
              payload: event,
              action: "create",
            }),
          );
        }
      });
  };
}

function deleteScheduleBlock(event: EventModel, applyToRecurrence?: string) {
  return (dispatch: Dispatch<EventAction>, getState: () => RootState) => {
    return schedulingFetchDelete(`coach_schedule_blocks/${event.id}`, {
      applyToRecurrence,
    })
      .then(() => {
        if (applyToRecurrence === "following") {
          const followingEventIds = getFollowingEvents(
            getState().events.data,
            event,
          ).map((ev) => ev.id);
          dispatch(removeEvents([event.id, ...followingEventIds]));
        } else {
          dispatch(removeEvents([event.id]));
        }

        dispatch(
          showInfoPopup({
            title: i18n.t("Unavailability Block Canceled"),
            content: i18n.t(
              "This unavailability block has been successfully canceled.",
            ),
            status: "success",
          }),
        );
      })
      .catch((err) => {
        console.error("fetchErr", err);

        dispatch(
          showInfoPopup({
            title: i18n.t("Something went wrong"),
            content: i18n.t(
              "This unavailability block has not been canceled. Please try again.",
            ),
            status: "error",
          }),
        );
      });
  };
}

export function setSelectedEvent(id: number) {
  return (dispatch: Dispatch<EventAction>, getState: () => RootState) => {
    const events: EventModel[] = getState().events.data;
    const selected = cloneDeep(events.find((event) => event.id === id));
    dispatch(selectEvent(selected));
  };
}

export function fetchEvents(params?: { [key: string]: any }) {
  return (dispatch: Dispatch<EventAction>, getState: () => RootState) => {
    if (getState().events.pending) {
      // @ts-ignore:next-line
      return;
    }
    dispatch(fetchPending());
    return schedulingFetchGet<EventResponse[]>("events", params || {})
      .then((events: EventResponse[]) => {
        const models = parseEvents(events);
        dispatch(fetchSuccess(models));
      })
      .catch((err) => {
        console.error("fetchErr", err);
        dispatch(fetchError(err));
      });
  };
}

export function fetchFacilityEvents(params?: { [key: string]: any }) {
  return (dispatch: Dispatch<EventAction>, getState: () => RootState) => {
    if (getState().events.pending) {
      // @ts-ignore:next-line
      return;
    }
    dispatch(fetchPending());
    return schedulingFetchGet<EventResponse[]>(
      "facilities/events",
      params || {},
    )
      .then((events: EventResponse[]) => {
        const models = parseEvents(events);
        dispatch(fetchSuccess(models));
      })
      .catch((err) => {
        console.error("fetchErr", err);
        dispatch(fetchError(err));
      });
  };
}

function getFollowingEvents(events: EventModel[], event: EventModel) {
  return events.filter((ev) => {
    return (
      ev.type === event.type &&
      ev.recurrenceGroupId === event.recurrenceGroupId &&
      ev.interval.isAfter(event.interval.start)
    );
  });
}
