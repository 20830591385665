import React from 'react';

import styles from './styles.module.scss';
import ModalLayout from "app/components/Layouts/ModalLayout/ModalLayout";

type PropsDef = {
  isOpen: boolean;
  title: string;
  buttonText: string;
  children: React.ReactNode;
  onConfirm: () => void;
  onCancel?: () => void;
};


export default function SimpleModal(props: PropsDef) {
  const { isOpen, title, children, buttonText, onConfirm } = props;

  return (
    <ModalLayout isOpen={isOpen} classNames={`${styles.modal}`}>
      <div className={styles.confirmation_modal_content}>
        <div className={styles.title_and_content}>
          <div className={styles.title}>
            <h2>{title}</h2>
          </div>
          <div className={styles.content}>
            {children}
          </div>
          <div className={styles.button}>
            <button onClick={onConfirm} className={`${styles.confirm_button} btn btn-primary`}>{buttonText}</button>
          </div>
        </div>
      </div>
    </ModalLayout>
  );
}
