import React, { useCallback } from "react";
import styles from "./styles.module.scss";
import SegmentsLayout from "app/components/Layouts/SegmentsLayout";
import SearchBar from "app/components/inputs/SearchBar";
import PagePanel from "app/components/PagePanel";
import GroupsModel from "app/models/GroupsModel";
import ContentCard from "app/components/ContentCard";
import { useGroupsModel } from "./indexModel";
import { CreateNewGroupModal } from "./_components/CreateNewGroupModal";
import TransferSuccessModal from "./_components/TransferSuccessModal";
import NoGroupsResult from "./_components/NoGroups";
import { useTranslation } from "react-i18next";

const Groups = () => {
  const { t } = useTranslation();
  const {
    groups,
    canCreateGroup,
    results,
    searchTerm,
    onSearchGroup,
    onGroupClickHandler,
    createGroupModalHandler,
    isOpenCreateGroupModal,
  } = useGroupsModel();

  const RenderResults = useCallback(() => {
    if (results?.length === 0) {
      return (
        <NoGroupsResult searchResult={results} searchedText={searchTerm} />
      );
    } else {
      return (
        <div className={styles.list_container}>
          {results?.length > 0 &&
            results?.map((result: GroupsModel) => {
              return (
                <ContentCard
                  key={result.id}
                  img={result.logo}
                  title={result.name}
                  subtitle={result.sport}
                  onContentCardClick={() => onGroupClickHandler(result.id)}
                />
              );
            })}
        </div>
      );
    }
  }, [results]);

  return (
    <SegmentsLayout isLoading={groups.pending}>
      <div className={styles.container}>
        <PagePanel
          title={t("Groups")}
          buttonTitle={t("+ New Group")}
          buttonStyles={canCreateGroup ? "btn-primary" : "btn-upgrade"}
          onButtonClick={createGroupModalHandler}
          // url={canAddMedia && "/resources/new"}
        />
        {results && results.length === 0 && searchTerm === "" ? null : (
          <SearchBar
            className={styles.search}
            placeholder={t("Search groups...")}
            onChange={onSearchGroup}
          />
        )}
        <RenderResults />
        {isOpenCreateGroupModal && (
          <CreateNewGroupModal
            isOpen={isOpenCreateGroupModal}
            onClose={createGroupModalHandler}
          />
        )}
      </div>
      <TransferSuccessModal />
    </SegmentsLayout>
  );
};

export default Groups;
