import { createPost, fetchPost } from "app/actions/postingForm";
import PostModel, { PostingModel } from "app/models/PostModel";
import { useActions } from "app/utils/hooks";
import usePlanFeature from "app/utils/services/plan_feature/user";
import { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "typedefs";

interface Props {
  onPost?: (post: PostModel) => void;
}

const defaultDestinations = {
  smart_list_ids: [],
  space_id: [],
  team_ids: [],
  segment_ids: [],
  team_group_ids: [],
};

export const FeedPostingFormModel = ({ onPost }: Props) => {
  const feedPostActions = useActions({ createPost, fetchPost });
  const { self: user } = useSelector(
    (state: RootState) => state.user,
    shallowEqual,
  );
  const [filteredDestinations, setFilteredDestinations] =
    useState(defaultDestinations);

  const { canSchedulePosts, canAddMedia, canPostMedia } = usePlanFeature(user);

  // check for user's permissions for uploading media file to posting form
  const canUploadMedia = (): boolean => {
    if (!user) {
      return null;
    }
    return canAddMedia && canPostMedia;
  };

  const { selectedDestinations } = useSelector(
    (state: RootState) => state.destinations,
    shallowEqual,
  );

  useEffect((): void => {
    if (selectedDestinations && selectedDestinations.length > 0) {
      selectedDestinations.forEach(
        (obj: { id: string; object: string }): void => {
          const idx =
            obj.object === "smart_list"
              ? "smart_list_ids"
              : obj.object === "athlete_space"
                ? "space_id"
                : obj.object === "team"
                  ? "team_ids"
                  : obj.object === "segment"
                    ? "segment_ids"
                    : "team_group_ids";

          setFilteredDestinations((prevState: any) => ({
            ...prevState,
            [idx]: [...prevState[idx], obj.id],
          }));
        },
      );
    }
  }, [selectedDestinations]);

  // posting form submit handler
  const onSubmit = (data: PostingModel): void => {
    // check if location(s) are selected in redux to use id from it
    if (selectedDestinations) {
      feedPostActions
        .createPost({ ...data, ...filteredDestinations })
        .then((res): void => {
          if (onPost) {
            onPost({ ...res, userAvatar: user.avatar });
          }
        })
        .finally((): void => {
          setFilteredDestinations(defaultDestinations);
        });
    }
  };

  const onCancel = (): void => {
    setFilteredDestinations(defaultDestinations);
  };

  return {
    canSchedulePosts,
    canAddMedia,
    canPostMedia,
    canUploadMedia,
    onSubmit,
    onCancel,
  };
};
