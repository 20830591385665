import { CoachProfileModel } from "app/models";
import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";
import assignment from "app/images/assignment.svg";

interface Props {
  coachData: CoachProfileModel;
}

const ContactInformation = ({ coachData }: Props) => {
  const { t } = useTranslation();

  const noContactInformation = () => {
    return (
      <div>
        <div className={styles.contact_info_title_container}>
          <img src={assignment} alt="info" />
          <p className={styles.contact_info_title}>
            {t("Contact Information Not Provided")}
          </p>
        </div>
        <div className={styles.contact_info_wrapper}>
          <p>
            {t(
              "The coach’s contact information, along with options to book a session and make a payment, will be available here once the coach completes their profile."
            )}
          </p>
        </div>
      </div>
    );
  };

  const contactInformation = () => {
    return (
      <div>
        <p className={styles.contact_info_title}>{t("Contact Information")}</p>
        <div className={styles.contact_info_wrapper}>
          {coachData?.address && (
            <p
              className={styles.address}
            >{`${coachData.address} ${coachData?.city}`}</p>
          )}
          {coachData?.phone && (
            <a href={`tel:+${coachData.phone}`}>
              <p className={styles.phone}>{coachData.phone}</p>
            </a>
          )}
          {coachData?.email && (
            <a href={`mailto:${coachData.email}`}>
              <p className={styles.email}>{coachData.email}</p>
            </a>
          )}
        </div>
      </div>
    );
  };

  return !coachData?.address || !coachData?.phone || !coachData?.email
    ? noContactInformation()
    : contactInformation();
};

export default memo(ContactInformation);
