import { useCallback, useEffect, useState } from "react";
import UserModel from "app/models/UserModel";
import { useActions } from "app/utils/hooks";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { RootState } from "typedefs";
import {
  updateTeamGroupMember,
  clearTeamGroupMembers,
  deleteTeamGroupMember,
  fetchTeamGroupMembers,
} from "app/actions/team-group";
import useDebounce from "app/utils/hooks/useDebounce";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { inviteModalIsOpen } from "app/actions/team-group-member-import";
import { usePagination } from "app/utils/hooks/usePagination";

type FilterName = "coach" | "player" | "viewer";

const INIT_MEMBERS_PAGE: number = 1;
const MEMBERS_PER_PAGE: number = 20;

export const useTeamsGroupMembersModel = () => {
  const { teamId } = useParams<{ teamId: string }>();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const memberActions = useActions({
    fetchTeamGroupMembers,
    clearTeamGroupMembers,
    deleteTeamGroupMember,
    updateTeamGroupMember,
  });
  const history = useHistory();

  const route = (path: string) => {
    history.push(path);
  };

  const { self } = useSelector((state: RootState) => state.user, shallowEqual);

  const { modalInviteIsOpen } = useSelector(
    (state: RootState) => state.teamGroupMemberImport,
    shallowEqual,
  );

  const { selectedTeam } = useSelector(
    (state: RootState) => state.teamGroup,
    shallowEqual,
  );
  const [canManageMembers, setCanManageMembers] = useState(false);
  const [memberFilterParams, setMemberFilter] = useState({
    coach: true,
    player: true,
    viewer: true,
  });
  const [searchTerm, setSearchTerm] = useState(null);

  useEffect(() => {
    if (selectedTeam) {
      if (
        selectedTeam?.permissions?.includes("manage_members") &&
        selectedTeam?.planPermissions?.includes("manage_members")
      ) {
        setCanManageMembers(true);
      } else {
        setCanManageMembers(false);
      }
    }

    return () => {
      setCanManageMembers(false);
      memberActions.clearTeamGroupMembers();
      clearData();
    };
  }, [selectedTeam]);

  const membersFilterHandler = (e: { [key in FilterName]: boolean }) => {
    setMemberFilter(e);
  };

  const memberSearchHandler = (val: string) => {
    setSearchTerm(val);
  };

  const inviteMembersModalHandler = () => {
    // dispatch(inviteModalType("invite-members"));
    dispatch(inviteModalIsOpen(true));
  };

  const debouncedSearch = useDebounce(searchTerm, 300);

  const memberSearchParams = {
    userName: debouncedSearch,
  };

  const onDeleteMember = (memberId: string, userName: string) => {
    const confirmation = window.confirm(
      t(`Are you sure you want to remove {{userName}} from this channel?`, {
        userName,
      }),
    );
    if (confirmation) {
      memberActions
        .deleteTeamGroupMember(selectedTeam.id, memberId)
        .then(() => {
          if (memberId === self?.id) {
            route("/groups");
          } else {
            updateData((prevData) =>
              prevData?.filter((member: UserModel) => member?.id !== memberId),
            );
          }
        })
        .catch((error) => {
          console.error("Error deleting post:", error);
        });
    }
  };

  const {
    data: members,
    pending,
    sentryRef,
    updateData,
    clearData,
  } = usePagination({
    initialParams: {
      page: INIT_MEMBERS_PAGE,
      perPage: MEMBERS_PER_PAGE,
      ...memberFilterParams,
      ...memberSearchParams,
    },
    fetchData: (params, signal) =>
      memberActions.fetchTeamGroupMembers(teamId, params, signal),
    clearDataAction: memberActions.clearTeamGroupMembers,
    dependencies: [debouncedSearch, memberFilterParams],
  });

  const onMemberInvite = useCallback((members) => {
    updateData((prevMembers) => {
      return [...prevMembers, ...members];
    });
  }, []);

  const onMemberUpdate = (updatedMember: UserModel) => {
    memberActions
      .updateTeamGroupMember(selectedTeam.id, updatedMember)
      .then(() => {
        updateData((prevMembers: UserModel[]) => {
          // Find the index of the updated member in the array
          const updatedIndex = prevMembers.findIndex(
            (member) => member.id === updatedMember.id,
          );

          // If the member is found, update it
          if (updatedIndex !== -1) {
            const updatedMembers = [...prevMembers];
            updatedMembers[updatedIndex] = updatedMember;
            return updatedMembers;
          }

          // If the member is not found, return the previous array as is
          return prevMembers;
        });
      });
  };

  return {
    selectedTeam,
    canManageMembers,
    membersFilterHandler,
    members,
    sentryRef,
    pending,
    onDeleteMember,
    onMemberUpdate,
    memberFilterParams,
    memberSearchHandler,
    inviteMembersModalHandler,
    onMemberInvite,
    modalInviteIsOpen,
  };
};
