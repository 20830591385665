import ScheduleBtn from "app/components/Button/ScheduleBtn";
import styles from "./styles.module.scss";
import TagsSelect from "app/components/inputs/SelectBox/TagsSelect";
import LocationsDropdown from "app/components/LocationsDropdown";
import ScheduledAtPicker from "app/components/ScheduledPosts/ScheduledAtPicker";
import Button from "app/components/Button/ButtonVariant";
import { MediaTypes } from "app/routes/Resources/ResourceCard/index.types";
import React, { memo } from "react";
import TagModel from "app/models/TagModel";
import { useTranslation } from "react-i18next";
import AttachmentPreview from "../AttachmentPreview";
import CreateMedia from "../CreateMedia";

interface Props {
  attachment?: MediaTypes;
  locationsIsRequired?: boolean;
  postButtonLabel?: string;
  disablePostButton?: boolean;
  canSelectMedia?: boolean;
  canSchedule?: boolean;
  canUploadMedia?: boolean;
  hasAddMedia?: boolean;
  hasLocationSelect?: boolean;
  hasSchedule?: boolean;
  hasTagSelect?: boolean;
  onTagsChange?: (e: TagModel[]) => void;
  onScheduleChange?: (e: number) => void;
  errorMessage?: string;
  onClearMedia?: () => void;
  onSelectMedia?: (media: MediaTypes) => void;
  scheduledInActive?: boolean;
  pickedSchedule?: number;
  paywallModalPro?: () => void;
  onCancelHandler?: () => void;
  onSubmitFunction?: () => void;
  postLocationsError?: boolean;
  scheduleIsRequired?: boolean;
  scheduleError?: boolean;
  newTags?: TagModel[];
  isMove?: boolean;
}

const PostingformExpanded = ({
  canSelectMedia,
  canUploadMedia,
  hasAddMedia = true,
  canSchedule = true,
  hasSchedule = true,
  hasLocationSelect = true,
  disablePostButton,
  hasTagSelect = true,
  postButtonLabel = "Create",
  errorMessage,
  onTagsChange,
  onScheduleChange,
  attachment,
  onClearMedia,
  onSelectMedia,
  scheduledInActive,
  pickedSchedule,
  paywallModalPro,
  onCancelHandler,
  onSubmitFunction,
  locationsIsRequired,
  postLocationsError,
  scheduleIsRequired,
  scheduleError,
  newTags,
  isMove,
}: Props) => {
  const { t } = useTranslation();

  const SchedulePost = (): React.ReactElement => {
    if (!hasSchedule) {
      return null;
    }

    if (!canSchedule) {
      return (
        <Button
          buttonType="secondary-outline"
          title={t("Schedule")}
          icon={<i className="ico ico-time" />}
          onClickFunc={paywallModalPro}
        />
      );
    }

    if (!scheduledInActive) {
      return (
        <ScheduledAtPicker
          value={pickedSchedule as any}
          onChange={(e): void => onScheduleChange(e)}
        />
      );
    } else {
      return (
        <ScheduleBtn
          scheduledTime={null}
          onScheduleChange={onScheduleChange}
          scheduledAt={pickedSchedule}
        />
      );
    }
  };

  return (
    <>
      {hasTagSelect && (
        <>
          <TagsSelect
            isCreatable
            isClearable
            value={newTags?.length > 0 ? newTags : null}
            onChange={onTagsChange}
            isMulti
          />
        </>
      )}

      {attachment && (
        <AttachmentPreview
          uploadedMedia={attachment}
          onClearMedia={onClearMedia}
        />
      )}

      <span className={styles.footer_buttons}>
        <div className={styles.buttons_wrapper}>
          {hasLocationSelect && (
            <LocationsDropdown
              classNames={styles.location_btn}
              hideLists={isMove}
              hideSmartLists={isMove}
            />
          )}

          {hasAddMedia && (
            <CreateMedia
              hasPermission={canUploadMedia}
              titleContent={t("Media")}
              icon={<i className="ico ico-media" />}
              canSelectMedia={canSelectMedia}
              onSelectMedia={onSelectMedia}
            />
          )}
          <SchedulePost />
        </div>
        <div className={styles.buttons_wrapper}>
          <Button
            title={t("Cancel")}
            buttonType="secondary-outline"
            buttonStyles={styles.control_btn}
            onClickFunc={onCancelHandler}
          />
          <Button
            title={postButtonLabel}
            buttonType="primary"
            disabled={disablePostButton}
            onClickFunc={onSubmitFunction}
          />
        </div>
      </span>
      {locationsIsRequired && postLocationsError && (
        <p className={styles.error_msg}>{t("Please select a location")}</p>
      )}
      {errorMessage && <p className={styles.error_msg}>{errorMessage}</p>}
      {scheduleIsRequired && scheduleError && (
        <p className={styles.error_msg}>{t("Please select a schedule date")}</p>
      )}
    </>
  );
};

export default memo(PostingformExpanded);
