/**
 * @module Components
 *
 */
import React, { PropsWithChildren } from "react";
import { Dots } from "app/components/Loading";
import Footer from "./Footer";
import styles from "./styles.module.scss";
import logo_png from "app/images/edufii-logo2.png";

type PropsDef = PropsWithChildren<{
  loading?: boolean;
}>;

export default function Wrapper(props: PropsDef) {
  const body = props.loading ? (
    <Dots className={styles.dotsWrapper} active={true} />
  ) : (
    <div className={styles.wrapperContent}>{props.children}</div>
  );

  const footer = props.loading ? <div /> : <Footer />;

  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapperContainer}>
        <div className={styles.wrapperBody}>
          <div className={styles.wrapperHeaderLogo}>
            <a className={styles.wrapperLink} href={window.location.origin}>
              <img className={styles.wrapperImg} src={logo_png} />
            </a>
          </div>

          {body}

          <div className="clear-float"></div>
        </div>
      </div>

      {footer}
    </div>
  );
}
