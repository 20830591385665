import React from 'react';

import styles from './styles.module.scss';

export default function SelectAllOption(props: any) {
  return (
    <div
      className={`${styles.option} ${props.isSelected ? styles.selected : ''}`}
      onClick={() => props.selectOption(props.data)}
    >
      <div className={styles.option_info}>
        <div className={styles.user_avatar} />
        <div className="option__label">{props.label}</div>
      </div>
    </div>
  );
}
