import DatePicker, { registerLocale } from "react-datepicker";
import React, { useEffect, useState } from "react";
import styles from '../../DatePicker/styles.module.scss';
import { DateTime } from "luxon";
import i18n from "app/i18n";
import en from 'date-fns/locale/en-US';
import ja from 'date-fns/locale/ja';

const locales = {
  en,
  ja,
};


const DayPicker = ({
  minDate,
  maxDate,
  date,
  CustomInput,
  onChange,
  setIsOpen,
  disabled = false,
  openToDate = null,
}) => {
  const [highlight, setHighlight] = useState(false);
  const handleChange = (date) => {
    onChange(date.toISOString());
  };

  const CustomHeader = ({ date, decreaseMonth, increaseMonth }) => {
    return (
      <div className={styles.header}>
        <div className={styles.date_arrow} style={{ transform: 'rotate(90deg)' }} onClick={decreaseMonth}>
          <span> <i className="ico ico-chevron" /> </span>
        </div>
        <p className={styles.current_month}>
          {date.toLocaleString({ month: 'long', year: 'numeric' })}
        </p>
        <div className={styles.date_arrow} style={{ transform: 'rotate(-90deg)' }} onClick={increaseMonth}>
          <span> <i className="ico ico-chevron" /> </span>
        </div>
      </div>
    );
  };

  const onCalendarOpen = () => {
    setIsOpen(true);
    setHighlight(true);
  };

  const onCalendarClose = () => {
    setIsOpen(false);
    setHighlight(false);
  };

  useEffect(() => {
    const currentLocale = locales[i18n.language] || locales.en;
    registerLocale(i18n.language, currentLocale);
  }, [i18n.language]);

  return (
    <DatePicker
      locale={i18n.language}
      disabled={disabled}
      minDate={minDate}
      openToDate={openToDate}
      maxDate={maxDate}
      selected={date}
      portalId="root"
      onChange={handleChange}
      dateFormat="MMMM d, yyyy"
      useWeekdaysShort={true}
      renderCustomHeader={({ date: selDate, decreaseMonth, increaseMonth }) => (
        <CustomHeader
          increaseMonth={increaseMonth}
          decreaseMonth={decreaseMonth}
          date={DateTime.fromJSDate(selDate)}
        />
      )}
      calendarClassName={styles.day_picker}
      customInput={<CustomInput highlight={highlight} />}
      onCalendarOpen={onCalendarOpen}
      onCalendarClose={onCalendarClose}
      showPopperArrow={false}
    />
  );
};

export default DayPicker;
